@charset "UTF-8";
/** Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the BSD-3 License as modified (the “License”); you may obtain a copy
// of the license at https://github.com/palantir/blueprint/blob/master/LICENSE
// and https://github.com/palantir/blueprint/blob/master/PATENTS */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
@font-face {
  font-family: "Icons16";
  font-style: normal;
  font-weight: normal;
  src: url("../resources/icons/icons-16.eot?#iefix") format("embedded-opentype"), url("../resources/icons/icons-16.woff") format("woff"), url("../resources/icons/icons-16.ttf") format("truetype"); }

@font-face {
  font-family: "Icons20";
  font-style: normal;
  font-weight: normal;
  src: url("../resources/icons/icons-20.eot?#iefix") format("embedded-opentype"), url("../resources/icons/icons-20.woff") format("woff"), url("../resources/icons/icons-20.ttf") format("truetype"); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Typography

Weight: 2

Styleguide typography
*/
hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15); }
  .pt-dark hr {
    border-color: rgba(255, 255, 255, 0.15); }

::selection {
  background: rgba(125, 188, 255, 0.6); }

/*
Usage

Keep in mind these general web typography guidelines when building your applications.

- The default text color in all components is compliant with the recommended
  [WCAG 2.0](https://www.w3.org/TR/WCAG20/) minimum contrast ratio.
- If you choose to go with a custom text color, make sure the background behind it provides
  proper contrast.
- Try not to explicitly write pixel values for your font-size or line-height CSS rules.
  Instead, reference the classes and variables we provide in Blueprint (`.pt-ui-text`,
  `$pt-font-size-large`, etc.).

Weight: 2

Styleguide typography.usage
*/
body {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif, "Icons16";
  font-size: 14px;
  font-weight: 400;
  color: #182026; }

small {
  font-size: 12px; }

/*
Fonts

Blueprint does not include any fonts of its own; it will use the default sans-serif operating system
font. We provide a class to use the default monospace font instead.

Markup:
<div class="{{.modifier}}">Blueprint components react overlay date picker.</div>

.pt-monospace-text - Use a monospace font (ideal for code)

Weight: 1

Styleguide typography.fonts
*/
.pt-monospace-text {
  text-transform: none;
  font-family: monospace;
  font-size: smaller; }

/*
Headings

Markup:
<h1>H1 heading</h1>
<h2>H2 heading</h2>
<h3>H3 heading</h3>
<h4>H4 heading</h4>
<h5>H5 heading</h5>
<h6>H6 heading</h6>

Weight: 3

Styleguide typography.headings
*/
h1 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 40px; }

h2 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 27px; }

h3 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 24px; }

h4 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 20px; }

h5 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 17px; }

h6 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 15px; }

/*
UI text

The base font size for Blueprint web applications is 14px. This should be the default type size
for most short strings of text which are not headings or titles. If you wish to reset some
element's font size and line height to the default base styles, use the `.pt-ui-text` class.
For longer running text, see [running text styles](#typography.running-text).

Markup:
<div class="{{.modifier}}">Blueprint components react overlay date picker.</div>

.pt-ui-text - Default UI text. This is applied to the document body as part of core styles.
.pt-ui-text-large - Larger UI text.

Weight: 4

Styleguide typography.ui-text
*/
.pt-ui-text {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif, "Icons16";
  font-size: 14px;
  font-weight: 400; }

.pt-ui-text-large {
  line-height: 1.25;
  font-size: 16px; }

/*
Running text

Large blocks of _running text_ should use `.pt-running-text` styles.

Note that `<p>` elements by default don't add any styles; they just inherit the base typography.
This is a conservative design; `<p>` elements are so ubiquitous that they're more often used for UI
text than long form text. It's up to the user to decide which blocks of text in an application
should get running text styles.

Markup:
<p>
  Default paragraphs have base typography styles.
</p>
<p class="pt-running-text">
  Running text is larger and more spaced out.
  <br />
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur.
  <br />
  <a href="#">Excepteur sint occaecat cupidatat non proident.</a>
</p>
<div class="pt-running-text">
  Includes support for <strong>strong</strong>, <em>emphasized</em>, and <u>underlined</u> text.
  <a href="#">Also links!</a>
</div>

Weight: 5

Styleguide typography.running-text
*/
.pt-running-text {
  line-height: 1.5;
  font-size: 16px; }
  .pt-running-text h1 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h2 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h3 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h4 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h5 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h6 {
    margin-top: 40px;
    margin-bottom: 20px; }

.pt-running-text-small {
  line-height: 1.5;
  font-size: 14px; }

p {
  margin: 0 0 10px;
  padding: 0; }

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Weight: 8

Styleguide typography.links
*/
a {
  text-decoration: none;
  color: #106ba3; }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #106ba3; }
  a .pt-icon, a .pt-icon-standard, a .pt-icon-large {
    color: inherit; }
  a code,
  .pt-dark a code {
    color: inherit; }
  .pt-dark a,
  .pt-dark a:hover {
    color: #2b95d6; }
    .pt-dark a .pt-icon, .pt-dark a .pt-icon-standard, .pt-dark a .pt-icon-large,
    .pt-dark a:hover .pt-icon,
    .pt-dark a:hover .pt-icon-standard,
    .pt-dark a:hover .pt-icon-large {
      color: inherit; }

/*
Preformatted text

Use `<pre>` for code blocks, and `<code>` for inline code. Note that `<pre>` blocks will
retain _all_ whitespace so you'll have to format the content accordingly.

Markup:
<code>$ npm install</code>
<pre>
%pt-select {
  @include pt-button();
  @include prefixer(appearance, none, webkit moz);
  border-radius: $pt-border-radius;
  height: $pt-button-height;
  padding: 0 ($input-padding-horizontal * 3) 0 $input-padding-horizontal;
}
</pre>
<pre><code>export function hasModifier(modifiers: ts.ModifiersArray, ...modifierKinds: ts.SyntaxKind[]) {
    if (modifiers == null || modifierKinds == null) {
        return false;
    }
    return modifiers.some((m) => {
        return modifierKinds.some((k) => m.kind === k);
    });
}</code></pre>

Weight: 6

Styleguide typography.preformatted
*/
pre,
code {
  text-transform: none;
  font-family: monospace;
  font-size: smaller; }

code {
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  color: #5c7080; }
  .pt-dark code {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #bfccd6; }

pre {
  display: block;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  background: rgba(255, 255, 255, 0.7);
  padding: 13px 15px 12px;
  line-height: 1.4;
  color: #182026;
  font-size: 13px;
  word-break: break-all;
  word-wrap: break-word; }
  pre > code {
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    white-space: pre-wrap;
    color: inherit;
    font-size: inherit; }
  .pt-dark pre {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark pre > code {
      box-shadow: none;
      background: transparent; }

/*
Block quotes

Block quotes are treated as running text.

Markup:
<blockquote>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </p>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </p>
</blockquote>

Weight: 7

Styleguide typography.blockquotes
*/
blockquote {
  line-height: 1.5;
  font-size: 16px;
  margin: 0 0 10px;
  border-left: solid 4px rgba(167, 182, 194, 0.5);
  padding: 0 20px; }
  blockquote p:last-child {
    margin-bottom: 0; }
  .pt-dark blockquote {
    border-color: rgba(115, 134, 148, 0.5); }

/*
Lists

Blueprint provides a small amount of global styling and a few modifier classes for list elements.

`<ul>` and `<ol>` elements in blocks with the `.pt-running-text` modifier class will
automatically assume the `.pt-list` styles to promote readability.

Markup:
<ul class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>Item the third</li>
</ul>
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>Item the third</li>
</ol>

.pt-list - Add a little spacing between items for readability.
.pt-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Weight: 8

Styleguide typography.lists
*/
ol,
ul {
  margin: 10px 0;
  padding-left: 40px; }

.pt-list li:not(:last-child),
.pt-running-text ul li:not(:last-child),
.pt-running-text ol li:not(:last-child) {
  padding-bottom: 5px; }

.pt-list li :last-child,
.pt-running-text ul li :last-child,
.pt-running-text ol li :last-child {
  margin-bottom: 0; }

.pt-list ol,
.pt-list ul,
.pt-running-text ul ol,
.pt-running-text ul ul,
.pt-running-text ol ol,
.pt-running-text ol ul {
  margin-top: 5px; }

.pt-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none; }
  .pt-list-unstyled li {
    padding: 0; }

/*
Text utilities

Blueprint provides a small handful of class-based text utilities which can applied to any element
that contains text.

Markup:
<div class="{{.modifier}}" style="width: 320px;">
  Blueprint components react overlay date picker. Breadcrumbs dialog progress non-ideal state.
</div>

.pt-text-muted - Changes text color to a gentler gray.
.pt-text-overflow-ellipsis - Truncates a single line of text with an ellipsis if it overflows its
  container.

Weight: 9

Styleguide typography.utilities
*/
.pt-text-muted {
  color: #5c7080; }
  .pt-dark .pt-text-muted {
    color: #bfccd6; }

.pt-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

/*
Internationalization

Weight: 10

Styleguide typography.intl
*/
/*
Right-to-left text

Use the utility class `.pt-rtl`.

Markup:
<h4>Arabic:</h4>
<p class="pt-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h4>Hebrew:</h4>
<p class="pt-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide typography.intl.rtl
*/
.pt-rtl {
  text-align: right; }

/*
Dark theme

<!-- TODO: move me to components.dark -->
Use the `.pt-dark` class to apply dark text colors *and* to cascade the dark theme to descendants.
This only applies colors to text; you'll have to set a background color yourself. Blueprint's
dark background color is aliased as `$pt-dark-app-background-color`.

Weight: 10

Styleguide typography.dark
*/
.pt-dark {
  color: #f5f8fa; }
  .pt-dark h1 {
    color: #f5f8fa; }
  .pt-dark h2 {
    color: #f5f8fa; }
  .pt-dark h3 {
    color: #f5f8fa; }
  .pt-dark h4 {
    color: #f5f8fa; }
  .pt-dark h5 {
    color: #f5f8fa; }
  .pt-dark h6 {
    color: #f5f8fa; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Icons

Weight: 3

Styleguide icons
*/
/*
UI icons

Blueprint UI icons are implemented via an icon font. They can be used anywhere text is
used. It's easy to change their color or apply effects like text shadows via standard CSS
properties.

To use Blueprint UI icons, you need to apply two classes to a `<span>` element:
- a __sizing class__, either `pt-icon-standard` (16px) or `pt-icon-large` (20px)
- an icon __identifier class__, such as `pt-icon-projects`

```html
<span class="pt-icon-standard pt-icon-projects"></span>
```

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Non-standard sizes</h5>
  Generally, icons should only be used at either 16px or 20px. However if a non-standard size is
  necessary, set a `font-size` that is whole multiple of 16 or 20 with the relevant size class.
  You can instead use the class `pt-icon` to make the icon inherit its size from surrounding text.
</div>

@react-docs Icons

Weight: -1

Styleguide icons.ui
*/
.pt-icon, .pt-icon-standard, .pt-icon-large {
  display: inline-block;
  color: #5c7080; }
  .pt-dark .pt-icon, .pt-dark .pt-icon-standard, .pt-dark .pt-icon-large {
    color: #bfccd6; }

span.pt-icon-standard {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

span.pt-icon-large {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

span.pt-icon {
  line-height: 1;
  font-family: "Icons20";
  font-size: inherit;
  font-weight: 400;
  font-style: normal; }
  span.pt-icon::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.pt-icon-blank::before {
  content: ""; }

.pt-icon-style::before {
  content: ""; }

.pt-icon-align-left::before {
  content: ""; }

.pt-icon-align-center::before {
  content: ""; }

.pt-icon-align-right::before {
  content: ""; }

.pt-icon-align-justify::before {
  content: ""; }

.pt-icon-bold::before {
  content: ""; }

.pt-icon-italic::before {
  content: ""; }

.pt-icon-underline::before {
  content: "⎁"; }

.pt-icon-search-around::before {
  content: ""; }

.pt-icon-graph-remove::before {
  content: ""; }

.pt-icon-group-objects::before {
  content: ""; }

.pt-icon-merge-links::before {
  content: ""; }

.pt-icon-layout::before {
  content: ""; }

.pt-icon-layout-auto::before {
  content: ""; }

.pt-icon-layout-circle::before {
  content: ""; }

.pt-icon-layout-hierarchy::before {
  content: ""; }

.pt-icon-layout-grid::before {
  content: ""; }

.pt-icon-layout-group-by::before {
  content: ""; }

.pt-icon-layout-skew-grid::before {
  content: ""; }

.pt-icon-geosearch::before {
  content: ""; }

.pt-icon-heatmap::before {
  content: ""; }

.pt-icon-drive-time::before {
  content: ""; }

.pt-icon-select::before {
  content: ""; }

.pt-icon-predictive-analysis::before {
  content: ""; }

.pt-icon-layers::before {
  content: ""; }

.pt-icon-locate::before {
  content: ""; }

.pt-icon-bookmark::before {
  content: ""; }

.pt-icon-citation::before {
  content: ""; }

.pt-icon-tag::before {
  content: ""; }

.pt-icon-clipboard::before {
  content: ""; }

.pt-icon-selection::before {
  content: "⦿"; }

.pt-icon-timeline-events::before {
  content: ""; }

.pt-icon-timeline-line-chart::before {
  content: ""; }

.pt-icon-timeline-bar-chart::before {
  content: ""; }

.pt-icon-applications::before {
  content: ""; }

.pt-icon-projects::before {
  content: ""; }

.pt-icon-changes::before {
  content: ""; }

.pt-icon-notifications::before {
  content: ""; }

.pt-icon-lock::before {
  content: ""; }

.pt-icon-unlock::before {
  content: ""; }

.pt-icon-user::before {
  content: ""; }

.pt-icon-search-template::before {
  content: ""; }

.pt-icon-inbox::before {
  content: ""; }

.pt-icon-more::before {
  content: ""; }

.pt-icon-help::before {
  content: "?"; }

.pt-icon-calendar::before {
  content: ""; }

.pt-icon-media::before {
  content: ""; }

.pt-icon-link::before {
  content: ""; }

.pt-icon-share::before {
  content: ""; }

.pt-icon-download::before {
  content: ""; }

.pt-icon-document::before {
  content: ""; }

.pt-icon-properties::before {
  content: ""; }

.pt-icon-import::before {
  content: ""; }

.pt-icon-export::before {
  content: ""; }

.pt-icon-minimize::before {
  content: ""; }

.pt-icon-maximize::before {
  content: ""; }

.pt-icon-tick::before {
  content: "✓"; }

.pt-icon-cross::before {
  content: "✗"; }

.pt-icon-plus::before {
  content: "+"; }

.pt-icon-minus::before {
  content: "−"; }

.pt-icon-arrow-left::before {
  content: "←"; }

.pt-icon-arrow-right::before {
  content: "→"; }

.pt-icon-exchange::before {
  content: ""; }

.pt-icon-comparison::before {
  content: ""; }

.pt-icon-list::before {
  content: "☰"; }

.pt-icon-filter::before {
  content: ""; }

.pt-icon-confirm::before {
  content: ""; }

.pt-icon-fork::before {
  content: ""; }

.pt-icon-trash::before {
  content: ""; }

.pt-icon-person::before {
  content: ""; }

.pt-icon-people::before {
  content: ""; }

.pt-icon-add::before {
  content: ""; }

.pt-icon-remove::before {
  content: ""; }

.pt-icon-geolocation::before {
  content: ""; }

.pt-icon-zoom-in::before {
  content: ""; }

.pt-icon-zoom-out::before {
  content: ""; }

.pt-icon-refresh::before {
  content: ""; }

.pt-icon-delete::before {
  content: ""; }

.pt-icon-cog::before {
  content: ""; }

.pt-icon-flag::before {
  content: "⚑"; }

.pt-icon-pin::before {
  content: ""; }

.pt-icon-warning-sign::before {
  content: ""; }

.pt-icon-error::before {
  content: ""; }

.pt-icon-info-sign::before {
  content: "ℹ"; }

.pt-icon-credit-card::before {
  content: ""; }

.pt-icon-edit::before {
  content: "✎"; }

.pt-icon-history::before {
  content: ""; }

.pt-icon-search::before {
  content: ""; }

.pt-icon-log-out::before {
  content: ""; }

.pt-icon-star::before {
  content: "★"; }

.pt-icon-star-empty::before {
  content: "☆"; }

.pt-icon-sort-alphabetical::before {
  content: ""; }

.pt-icon-sort-numerical::before {
  content: ""; }

.pt-icon-sort::before {
  content: ""; }

.pt-icon-folder-open::before {
  content: ""; }

.pt-icon-folder-close::before {
  content: ""; }

.pt-icon-folder-shared::before {
  content: ""; }

.pt-icon-caret-up::before {
  content: "⌃"; }

.pt-icon-caret-right::before {
  content: "〉"; }

.pt-icon-caret-down::before {
  content: "⌄"; }

.pt-icon-caret-left::before {
  content: "〈"; }

.pt-icon-menu-open::before {
  content: ""; }

.pt-icon-menu-closed::before {
  content: ""; }

.pt-icon-feed::before {
  content: ""; }

.pt-icon-two-columns::before {
  content: ""; }

.pt-icon-one-column::before {
  content: ""; }

.pt-icon-dot::before {
  content: "•"; }

.pt-icon-property::before {
  content: ""; }

.pt-icon-time::before {
  content: "⏲"; }

.pt-icon-disable::before {
  content: ""; }

.pt-icon-unpin::before {
  content: ""; }

.pt-icon-flows::before {
  content: ""; }

.pt-icon-new-text-box::before {
  content: ""; }

.pt-icon-new-link::before {
  content: ""; }

.pt-icon-new-object::before {
  content: ""; }

.pt-icon-path-search::before {
  content: ""; }

.pt-icon-automatic-updates::before {
  content: ""; }

.pt-icon-page-layout::before {
  content: ""; }

.pt-icon-code::before {
  content: ""; }

.pt-icon-map::before {
  content: ""; }

.pt-icon-search-text::before {
  content: ""; }

.pt-icon-envelope::before {
  content: "✉"; }

.pt-icon-paperclip::before {
  content: ""; }

.pt-icon-label::before {
  content: ""; }

.pt-icon-globe::before {
  content: ""; }

.pt-icon-home::before {
  content: "⌂"; }

.pt-icon-th::before {
  content: ""; }

.pt-icon-th-list::before {
  content: ""; }

.pt-icon-th-derived::before {
  content: ""; }

.pt-icon-circle::before {
  content: ""; }

.pt-icon-draw::before {
  content: ""; }

.pt-icon-insert::before {
  content: ""; }

.pt-icon-helper-management::before {
  content: ""; }

.pt-icon-send-to::before {
  content: ""; }

.pt-icon-eye-open::before {
  content: ""; }

.pt-icon-folder-shared-open::before {
  content: ""; }

.pt-icon-social-media::before {
  content: ""; }

.pt-icon-arrow-up::before {
  content: "↑"; }

.pt-icon-arrow-down::before {
  content: "↓"; }

.pt-icon-arrows-horizontal::before {
  content: "↔"; }

.pt-icon-arrows-vertical::before {
  content: "↕"; }

.pt-icon-resolve::before {
  content: ""; }

.pt-icon-graph::before {
  content: ""; }

.pt-icon-briefcase::before {
  content: ""; }

.pt-icon-dollar::before {
  content: "$"; }

.pt-icon-ninja::before {
  content: ""; }

.pt-icon-delta::before {
  content: "Δ"; }

.pt-icon-barcode::before {
  content: ""; }

.pt-icon-torch::before {
  content: ""; }

.pt-icon-widget::before {
  content: ""; }

.pt-icon-unresolve::before {
  content: ""; }

.pt-icon-offline::before {
  content: ""; }

.pt-icon-zoom-to-fit::before {
  content: ""; }

.pt-icon-add-to-artifact::before {
  content: ""; }

.pt-icon-map-marker::before {
  content: ""; }

.pt-icon-chart::before {
  content: ""; }

.pt-icon-control::before {
  content: ""; }

.pt-icon-multi-select::before {
  content: ""; }

.pt-icon-direction-left::before {
  content: ""; }

.pt-icon-direction-right::before {
  content: ""; }

.pt-icon-database::before {
  content: ""; }

.pt-icon-pie-chart::before {
  content: ""; }

.pt-icon-full-circle::before {
  content: ""; }

.pt-icon-square::before {
  content: ""; }

.pt-icon-print::before {
  content: "⎙"; }

.pt-icon-presentation::before {
  content: ""; }

.pt-icon-ungroup-objects::before {
  content: ""; }

.pt-icon-chat::before {
  content: ""; }

.pt-icon-comment::before {
  content: ""; }

.pt-icon-circle-arrow-right::before {
  content: ""; }

.pt-icon-circle-arrow-left::before {
  content: ""; }

.pt-icon-circle-arrow-up::before {
  content: ""; }

.pt-icon-circle-arrow-down::before {
  content: ""; }

.pt-icon-upload::before {
  content: ""; }

.pt-icon-asterisk::before {
  content: "*"; }

.pt-icon-cloud::before {
  content: "☁"; }

.pt-icon-cloud-download::before {
  content: ""; }

.pt-icon-cloud-upload::before {
  content: ""; }

.pt-icon-repeat::before {
  content: ""; }

.pt-icon-move::before {
  content: ""; }

.pt-icon-chevron-left::before {
  content: ""; }

.pt-icon-chevron-right::before {
  content: ""; }

.pt-icon-chevron-up::before {
  content: ""; }

.pt-icon-chevron-down::before {
  content: ""; }

.pt-icon-random::before {
  content: ""; }

.pt-icon-fullscreen::before {
  content: ""; }

.pt-icon-log-in::before {
  content: ""; }

.pt-icon-heart::before {
  content: "♥"; }

.pt-icon-office::before {
  content: ""; }

.pt-icon-duplicate::before {
  content: ""; }

.pt-icon-ban-circle::before {
  content: ""; }

.pt-icon-camera::before {
  content: ""; }

.pt-icon-mobile-video::before {
  content: ""; }

.pt-icon-video::before {
  content: ""; }

.pt-icon-film::before {
  content: ""; }

.pt-icon-settings::before {
  content: ""; }

.pt-icon-volume-off::before {
  content: ""; }

.pt-icon-volume-down::before {
  content: ""; }

.pt-icon-volume-up::before {
  content: ""; }

.pt-icon-music::before {
  content: ""; }

.pt-icon-step-backward::before {
  content: ""; }

.pt-icon-fast-backward::before {
  content: ""; }

.pt-icon-pause::before {
  content: ""; }

.pt-icon-stop::before {
  content: ""; }

.pt-icon-play::before {
  content: ""; }

.pt-icon-fast-forward::before {
  content: ""; }

.pt-icon-step-forward::before {
  content: ""; }

.pt-icon-eject::before {
  content: "⏏"; }

.pt-icon-record::before {
  content: ""; }

.pt-icon-desktop::before {
  content: ""; }

.pt-icon-phone::before {
  content: "☎"; }

.pt-icon-lightbulb::before {
  content: ""; }

.pt-icon-glass::before {
  content: ""; }

.pt-icon-tint::before {
  content: ""; }

.pt-icon-flash::before {
  content: ""; }

.pt-icon-font::before {
  content: ""; }

.pt-icon-header::before {
  content: ""; }

.pt-icon-saved::before {
  content: ""; }

.pt-icon-floppy-disk::before {
  content: ""; }

.pt-icon-book::before {
  content: ""; }

.pt-icon-hand-right::before {
  content: ""; }

.pt-icon-hand-up::before {
  content: ""; }

.pt-icon-hand-down::before {
  content: ""; }

.pt-icon-hand-left::before {
  content: ""; }

.pt-icon-thumbs-up::before {
  content: ""; }

.pt-icon-thumbs-down::before {
  content: ""; }

.pt-icon-box::before {
  content: ""; }

.pt-icon-compressed::before {
  content: ""; }

.pt-icon-shopping-cart::before {
  content: ""; }

.pt-icon-shop::before {
  content: ""; }

.pt-icon-layout-linear::before {
  content: ""; }

.pt-icon-undo::before {
  content: "⎌"; }

.pt-icon-redo::before {
  content: ""; }

.pt-icon-code-block::before {
  content: ""; }

.pt-icon-double-caret-vertical::before {
  content: ""; }

.pt-icon-double-caret-horizontal::before {
  content: ""; }

.pt-icon-sort-alphabetical-desc::before {
  content: ""; }

.pt-icon-sort-numerical-desc::before {
  content: ""; }

.pt-icon-take-action::before {
  content: ""; }

.pt-icon-contrast::before {
  content: ""; }

.pt-icon-eye-off::before {
  content: ""; }

.pt-icon-timeline-area-chart::before {
  content: ""; }

.pt-icon-doughnut-chart::before {
  content: ""; }

.pt-icon-layer::before {
  content: ""; }

.pt-icon-grid::before {
  content: ""; }

.pt-icon-polygon-filter::before {
  content: ""; }

.pt-icon-add-to-folder::before {
  content: ""; }

.pt-icon-layout-balloon::before {
  content: ""; }

.pt-icon-layout-sorted-clusters::before {
  content: ""; }

.pt-icon-sort-asc::before {
  content: ""; }

.pt-icon-sort-desc::before {
  content: ""; }

.pt-icon-small-cross::before {
  content: ""; }

.pt-icon-small-tick::before {
  content: ""; }

.pt-icon-power::before {
  content: ""; }

.pt-icon-column-layout::before {
  content: ""; }

.pt-icon-arrow-top-left::before {
  content: "↖"; }

.pt-icon-arrow-top-right::before {
  content: "↗"; }

.pt-icon-arrow-bottom-right::before {
  content: "↘"; }

.pt-icon-arrow-bottom-left::before {
  content: "↙"; }

.pt-icon-mugshot::before {
  content: ""; }

.pt-icon-headset::before {
  content: ""; }

.pt-icon-text-highlight::before {
  content: ""; }

.pt-icon-hand::before {
  content: ""; }

.pt-icon-chevron-backward::before {
  content: ""; }

.pt-icon-chevron-forward::before {
  content: ""; }

.pt-icon-rotate-document::before {
  content: ""; }

.pt-icon-rotate-page::before {
  content: ""; }

.pt-icon-badge::before {
  content: ""; }

.pt-icon-grid-view::before {
  content: ""; }

.pt-icon-function::before {
  content: ""; }

.pt-icon-waterfall-chart::before {
  content: ""; }

.pt-icon-stacked-chart::before {
  content: ""; }

.pt-icon-pulse::before {
  content: ""; }

.pt-icon-new-person::before {
  content: ""; }

.pt-icon-exclude-row::before {
  content: ""; }

.pt-icon-pivot-table::before {
  content: ""; }

.pt-icon-segmented-control::before {
  content: ""; }

.pt-icon-highlight::before {
  content: ""; }

.pt-icon-filter-list::before {
  content: ""; }

.pt-icon-cut::before {
  content: ""; }

.pt-icon-annotation::before {
  content: ""; }

.pt-icon-pivot::before {
  content: ""; }

.pt-icon-ring::before {
  content: ""; }

.pt-icon-heat-grid::before {
  content: ""; }

.pt-icon-gantt-chart::before {
  content: ""; }

.pt-icon-variable::before {
  content: ""; }

.pt-icon-manual::before {
  content: ""; }

.pt-icon-add-row-top::before {
  content: ""; }

.pt-icon-add-row-bottom::before {
  content: ""; }

.pt-icon-add-column-left::before {
  content: ""; }

.pt-icon-add-column-right::before {
  content: ""; }

.pt-icon-remove-row-top::before {
  content: ""; }

.pt-icon-remove-row-bottom::before {
  content: ""; }

.pt-icon-remove-column-left::before {
  content: ""; }

.pt-icon-remove-column-right::before {
  content: ""; }

.pt-icon-double-chevron-left::before {
  content: ""; }

.pt-icon-double-chevron-right::before {
  content: ""; }

.pt-icon-double-chevron-up::before {
  content: ""; }

.pt-icon-double-chevron-down::before {
  content: ""; }

.pt-icon-key-control::before {
  content: ""; }

.pt-icon-key-command::before {
  content: ""; }

.pt-icon-key-shift::before {
  content: ""; }

.pt-icon-key-backspace::before {
  content: ""; }

.pt-icon-key-delete::before {
  content: ""; }

.pt-icon-key-escape::before {
  content: ""; }

.pt-icon-key-enter::before {
  content: ""; }

.pt-icon-calculator::before {
  content: ""; }

.pt-icon-horizontal-bar-chart::before {
  content: ""; }

.pt-icon-small-plus::before {
  content: ""; }

.pt-icon-small-minus::before {
  content: ""; }

.pt-icon-step-chart::before {
  content: ""; }

.pt-icon-euro::before {
  content: "€"; }

.pt-icon-drag-handle-vertical::before {
  content: ""; }

.pt-icon-drag-handle-horizontal::before {
  content: ""; }

.pt-icon-mobile-phone::before {
  content: ""; }

.pt-icon-sim-card::before {
  content: ""; }

.pt-icon-trending-up::before {
  content: ""; }

.pt-icon-trending-down::before {
  content: ""; }

.pt-icon-curved-range-chart::before {
  content: ""; }

.pt-icon-vertical-bar-chart-desc::before {
  content: ""; }

.pt-icon-horizontal-bar-chart-desc::before {
  content: ""; }

.pt-icon-document-open::before {
  content: ""; }

.pt-icon-document-share::before {
  content: ""; }

.pt-icon-horizontal-distribution::before {
  content: ""; }

.pt-icon-vertical-distribution::before {
  content: ""; }

.pt-icon-alignment-left::before {
  content: ""; }

.pt-icon-alignment-vertical-center::before {
  content: ""; }

.pt-icon-alignment-right::before {
  content: ""; }

.pt-icon-alignment-top::before {
  content: ""; }

.pt-icon-alignment-horizontal-center::before {
  content: ""; }

.pt-icon-alignment-bottom::before {
  content: ""; }

.pt-icon-git-pull::before {
  content: ""; }

.pt-icon-git-merge::before {
  content: ""; }

.pt-icon-git-branch::before {
  content: ""; }

.pt-icon-git-commit::before {
  content: ""; }

.pt-icon-git-push::before {
  content: ""; }

.pt-icon-build::before {
  content: ""; }

.pt-icon-symbol-circle::before {
  content: ""; }

.pt-icon-symbol-square::before {
  content: ""; }

.pt-icon-symbol-diamond::before {
  content: ""; }

.pt-icon-symbol-cross::before {
  content: ""; }

.pt-icon-symbol-triangle-up::before {
  content: ""; }

.pt-icon-symbol-triangle-down::before {
  content: ""; }

.pt-icon-wrench::before {
  content: ""; }

.pt-icon-application::before {
  content: ""; }

.pt-icon-send-to-graph::before {
  content: ""; }

.pt-icon-send-to-map::before {
  content: ""; }

.pt-icon-join-table::before {
  content: ""; }

.pt-icon-derive-column::before {
  content: ""; }

.pt-icon-image-rotate-left::before {
  content: ""; }

.pt-icon-image-rotate-right::before {
  content: ""; }

.pt-icon-known-vehicle::before {
  content: ""; }

.pt-icon-unknown-vehicle::before {
  content: ""; }

.pt-icon-scatter-plot::before {
  content: ""; }

.pt-icon-oil-field::before {
  content: ""; }

.pt-icon-rig::before {
  content: ""; }

.pt-icon-map-create::before {
  content: ""; }

.pt-icon-key-option::before {
  content: ""; }

.pt-icon-list-detail-view::before {
  content: ""; }

.pt-icon-swap-vertical::before {
  content: ""; }

.pt-icon-swap-horizontal::before {
  content: ""; }

.pt-icon-numbered-list::before {
  content: ""; }

.pt-icon-new-grid-item::before {
  content: ""; }

.pt-icon-git-repo::before {
  content: ""; }

.pt-icon-git-new-branch::before {
  content: ""; }

.pt-icon-manually-entered-data::before {
  content: ""; }

.pt-icon-airplane::before {
  content: ""; }

.pt-icon-merge-columns::before {
  content: ""; }

.pt-icon-split-columns::before {
  content: ""; }

.pt-icon-dashboard::before {
  content: ""; }

.pt-icon-publish-function::before {
  content: ""; }

.pt-icon-path::before {
  content: ""; }

.pt-icon-moon::before {
  content: ""; }

.pt-icon-remove-column::before {
  content: ""; }

.pt-icon-numerical::before {
  content: ""; }

.pt-icon-key-tab::before {
  content: ""; }

.pt-icon-regression-chart::before {
  content: ""; }

.pt-icon-translate::before {
  content: ""; }

.pt-icon-eye-on::before {
  content: ""; }

/*
Accessibility

Blueprint strives to provide accessible components out of the box. Many of the JS components
will apply accessible HTML attributes to support different modes of usage.

Styleguide a11y
*/
/*
Focus management

Focus states (that glowy blue outline around the active element) are essential for keyboard
navigation to indicate which element is currently active. They are less important, and
occasionally outright intrusive, when using a mouse because you can click wherever you want at
any time.

Blueprint includes a utility that manages the appearance of focus styles. When enabled, focus styles
will be hidden while the user interacts using the mouse and will appear when the
<kbd class="pt-key">tab</kbd> key is pressed to begin keyboard navigation. Try this out for yourself
below.

**You must explictly enable this feature in your app (and you probably want to):**

```ts
import { FocusStyleManager } from "@blueprintjs/core";

FocusStyleManager.onlyShowFocusOnTabs();
```

Note that the focus style for text inputs (a slightly thicker colored border) is not removed by this
utility because it is always useful to know where you're typing.

@react-example FocusExample

Styleguide a11y.focus
*/
/*
JavaScript API

This behavior is controlled by a singleton instance called `FocusStyleManager` that lives in the
__@blueprintjs/core__ package. It supports the following public methods:

- `FocusStyleManager.isActive(): boolean`: Returns whether the `FocusStyleManager` is currently running.
- `FocusStyleManager.onlyShowFocusOnTabs(): void`: Enable behavior which hides focus styles during mouse interaction.
- `FocusStyleManager.alwaysShowFocus(): void`: Stop this behavior (focus styles are always visible).

Styleguide a11y.focus.js
*/
:focus {
  outline: rgba(19, 124, 189, 0.5) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.pt-focus-disabled :focus {
  outline: none !important; }
  .pt-focus-disabled :focus ~ .pt-control-indicator {
    outline: none !important; }

/*
Color contrast

Colors have been designed to be accessible to as many people as possible, even those who are
visually impaired or experiencing any kind of colorblindness. Our colors have not only been chosen
to go well together but to also adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards.

Weight: 1

Styleguide a11y.contrast
*/
/*
Components

Blueprint's JavaScript components are stable and their APIs adhere to
[semantic versioning](http://semver.org/).
They are distributed in the __@blueprintjs/core__ package and can be consumed as CommonJS modules
[via NPM](https://www.npmjs.com/package/@blueprintjs/core).

Weight: 6

Styleguide components
*/
/*
Usage

Weight: -1

Styleguide components.usage
*/
/*
NPM installation

1. Install the core package with an NPM client like `npm` or `yarn`, pulling in all relevant
dependencies:

  ```sh
  npm install --save @blueprintjs/core
  ```

1. If you see `UNMET PEER DEPENDENCY` errors, you should manually install React:

  ```sh
  npm install --save react react-dom react-addons-css-transition-group
  ```

1. After installation, you'll be able to import the React JS components in your application:

  ```
  // extract specific components
  import { Intent, Spinner, DatePickerFactory } from "@blueprintjs/core";
  // or just take everything!
  import * as Blueprint from "@blueprintjs/core";

  // using JSX:
  const mySpinner = <Spinner intent={Intent.PRIMARY} />;

  // using the namespace import:
  const anotherSpinner = <Blueprint.Spinner intent={Blueprint.Intent.PRIMARY}/>;

  // use factories for React.createElement shorthand if you're not using JSX.
  // every component provides a corresponding <Name>Factory.
  const myDatePicker = DatePickerFactory();
  ```

1. Don't forget to include the main CSS file from each Blueprint package! Additionally, the
`resources/` directory contains supporting media such as fonts and images.

  ```html
  <!-- in plain old reliable HTML -->
  <!DOCTYPE HTML>
  <html>
    <head>
      ...
      <!-- include dependencies manually -->
      <link href="path/to/node_modules/normalize.css/normalize.css" rel="stylesheet" />
      <link href="path/to/node_modules/@blueprintjs/core/dist/blueprint.css" rel="stylesheet" />
      ...
    </head>
    ...
  </html>
  ```

  ```scss
  // or, using node-style package resolution in a CSS file:
  // (dependencies' stylesheets should be resolved automatically)
  @import "~@blueprintjs/core";
  ```

Weight: -1

Styleguide components.usage.npm
*/
/*
DOM4

Blueprint relies on a handful of DOM Level 4 API methods: `el.query`, `el.queryAll`, and
`el.closest()`. `@blueprintjs/core` depends on a [polyfill library called `dom4`][dom4] to ensure
these methods are available. This module is conditionally loaded if Blueprint is used in a browser
environment.

[dom4]: https://webreflection.github.io/dom4/

Styleguide components.usage.dom4
*/
/*
TypeScript

Blueprint is written in TypeScript and therefore its own `.d.ts` type definitions are distributed in
the NPM package and should be resolved automatically by the compiler. However, you'll need to
install typings for Blueprint's dependencies before you can consume it:

```sh
# required for all @blueprintjs packages:
npm install --save @types/pure-render-decorator @types/react @types/react-dom @types/react-addons-css-transition-group

# @blueprintjs/datetime requires:
npm install --save @types/moment

# @blueprintjs/table requires:
npm install --save @types/es6-shim
```

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  For more information, see the TypeScript Handbook for
  [guidance on consuming declaration files][handbook].
</div>

[handbook]: https://www.typescriptlang.org/docs/handbook/declaration-files/consumption.html

Styleguide components.usage.typescript
*/
/*
Vanilla JS APIs

JS Components are built using React, but that does not limit their usage to just React applications.
You can render any component in any JavaScript application with `ReactDOM.render`. Think of it like
using a JQuery plugin.

```
const myContainerElement = document.querySelector(".my-container");

// with JSX
ReactDOM.render(
    <Spinner className="pt-intent-primary pt-small" />,
    myContainerElement
);

// with vanilla JS, use the factory
ReactDOM.render(
    SpinnerFactory({
        className: "pt-intent-primary pt-small"
    }),
    myContainerElement
);
```

To remove the component from the DOM and clean up, unmount it:

```
ReactDOM.unmountComponentAtNode(myContainerElement);
```

Check out the [React API docs](https://facebook.github.io/react/docs/react-api.html) for more details.

Styleguide components.usage.vanilla
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Alerts

Alerts notify users of important information and force them to acknowledge the alert content before
continuing.

Although similar to [dialogs](#components.dialog), alerts are more restrictive and should only be
used for important notifications. The user can only exit the alert by clicking one of the
confirmation buttons — clicking the overlay or pressing the <kbd class="pt-key">esc</kbd> key will
not close the alert.

You can only use this component in controlled mode. Use the `onClick` handlers in the primary and
secondary action props to handle closing the `Alert`. Optionally, display an icon next to the body
to show the type of the alert.

@react-example AlertExample

Styleguide components.alert
*/
/*
JavaScript API

The `Alert` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

@interface IAlertProps

Styleguide components.alert.js
*/
.pt-alert {
  max-width: 400px;
  padding: 20px; }

.pt-alert-body {
  display: flex; }
  .pt-alert-body .pt-icon {
    margin-right: 20px;
    font-size: 40px; }

.pt-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px; }
  .pt-alert-footer .pt-button {
    margin-left: 10px; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Breadcrumbs

Breadcrumbs identify the current resource in an application.

Styleguide components.breadcrumbs
*/
/*
CSS API

* Begin with a `ul.pt-breadcrumbs`; each crumb should be in its own `li` as a direct descendant.
* Breadcrumbs are typically navigation links (for example, to the parent folder in a file path), and
  therefore should use `<a>` tags (except for the final breadcrumb).
* Each navigation breadcrumb should use `.pt-breadcrumb`.
* Make a breadcrumb non-interactive with the `.pt-disabled` class. You should only use this
  state when you want to indicate that the user cannot navigate to the breadcrumb (for example, if
  the user does not have permission to access it). Otherwise, clicking a breadcrumb should take the
  user to that resource.
* Mark the final breadcrumb `.pt-breadcrumb-current` for an emphasized appearance.
* The `.pt-breadcrumbs-collapsed` button-like element can be used as the target for a dropdown menu
  containing breadcrumbs that are collapsed due to layout constraints.
* When adding another element (such as a [tooltip](#components.tooltip) or
  [popover](#components.popover)) to a breadcrumb, wrap it around the contents of the `li`.


Markup:
<ul class="pt-breadcrumbs">
  <li><a class="pt-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="pt-breadcrumb pt-disabled">Folder one</a></li>
  <li><a class="pt-breadcrumb" href="#">Folder two</a></li>
  <li><a class="pt-breadcrumb" href="#">Folder three</a></li>
  <li><span class="pt-breadcrumb pt-breadcrumb-current">File</span></li>
</ul>

Styleguide components.breadcrumbs.css
*/
/*
JavaScript API

The `Breadcrumb` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

The component renders an `a.pt-breadcrumb`.
You are responsible for constructing the `ul.pt-breadcrumbs` list.
The [CollapsibleList component](#components.collapsiblelist) works nicely with this component
because its props are a subset of `IMenuItemProps`.

@interface IBreadcrumbProps

Styleguide components.breadcrumbs.js
*/
.pt-breadcrumbs {
  display: inline-block;
  margin: 0;
  cursor: default;
  padding: 0;
  list-style: none;
  vertical-align: top;
  line-height: 30px; }
  .pt-breadcrumbs > li {
    float: left; }
    .pt-breadcrumbs > li::after {
      line-height: 1;
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      padding: 0 5px;
      color: #5c7080;
      content: ""; }
    .pt-breadcrumbs > li:last-child::after {
      display: none; }

.pt-breadcrumb,
.pt-breadcrumb-current,
.pt-breadcrumbs-collapsed {
  display: inline-block;
  line-height: 19px;
  font-size: 16px; }

.pt-breadcrumb,
.pt-breadcrumbs-collapsed {
  color: #5c7080; }

.pt-breadcrumb:hover {
  text-decoration: none; }

.pt-breadcrumb.pt-disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-breadcrumb-current {
  color: inherit;
  font-weight: 600; }
  .pt-breadcrumb-current .pt-input {
    vertical-align: baseline;
    font-size: inherit;
    font-weight: inherit; }

.pt-breadcrumbs-collapsed {
  margin-right: 2px;
  border: none;
  border-radius: 3px;
  background: #ced9e0;
  cursor: pointer;
  padding: 0 5px; }
  .pt-breadcrumbs-collapsed::before {
    line-height: 1;
    font-family: "Icons20", sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 19px;
    content: ""; }
  .pt-breadcrumbs-collapsed:hover {
    background: #bfccd6;
    text-decoration: none;
    color: #182026; }

.pt-dark .pt-breadcrumb,
.pt-dark .pt-breadcrumbs-collapsed {
  color: #bfccd6; }

.pt-dark .pt-breadcrumbs > li::after {
  color: #bfccd6; }

.pt-dark .pt-breadcrumb.pt-disabled {
  color: rgba(191, 204, 214, 0.5); }

.pt-dark .pt-breadcrumb-current {
  color: #f5f8fa; }

.pt-dark .pt-breadcrumbs-collapsed {
  background: rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-breadcrumbs-collapsed:hover {
    background: rgba(16, 22, 26, 0.6);
    color: #f5f8fa; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Buttons

Buttons trigger actions when clicked.

Styleguide components.button
*/
/*
CSS API

Use the `pt-button` class to access button styles. You should implement buttons using the
`<button>` or `<a>` tags rather than `<div>` for the purposes of HTML accessibility and semantics.

- Make sure to include `type="button"` on `<button>` tags (use `type="submit"` when used in a
  `<form>`) and `role="button"` on `<a>` tags for accessibility.
- Add the attribute `tabindex="0"` to make `<a>` tags focusable. `<button>` elements are
  focusable by default.
- For buttons implemented with `<a>` tags, add `tabindex="-1"` to disabled buttons to prevent the
  user from focusing them by pressing <kbd class="pt-key">tab</kbd> on the keyboard.
- Note that `<a>` tags do not respond to the `:disabled` attribute; use `.pt-disabled` instead.

Markup:
<a role="button" class="pt-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="pt-button pt-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.pt-disabled - Disabled appearance
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger - Danger intent
.pt-active - Active appearance
.pt-large - Larger size
.pt-fill - Fill parent container

Styleguide components.button.css
*/
.pt-button {
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  line-height: 28px;
  /*
  Buttons with icons

  Add an icon before the button text with `pt-icon-*` classes.
  You _do not_ need to include an icon sizing class.

  Markup:
  <button type="button" class="pt-button pt-icon-add">Default button</button>
  <button type="button" class="pt-button pt-icon-refresh"></button>
  <button type="button" class="pt-button pt-large pt-icon-add">Large button</button>
  <button type="button" class="pt-button pt-large pt-icon-refresh"></button>

  Weight: -1

  Styleguide components.button.css.icon
  */
  /*
  Advanced icon layout

  You can use a `pt-icon-*` class on a button to add a single icon before the button
  text, but for more advanced icon layouts, use `<span>` tags inside the button.
  Add multiple icons to the same button, or move icons after the text.

  To adjust margins on right-aligned icons, add the class `pt-align-right` to the icon.

  Markup:
  <button type="button" class="pt-button pt-intent-success">
    Next step
    <span class="pt-icon-standard pt-icon-arrow-right pt-align-right"></span>
  </button>
  <button type="button" class="pt-button">
    <span class="pt-icon-standard pt-icon-user"></span>
    Profile settings
    <span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
  </button>
  <button type="button" class="pt-button pt-intent-danger">
    Reset
    <span class="pt-icon-standard pt-icon-refresh pt-align-right"></span>
  </button>
  <button type="button" class="pt-button pt-large">
    <span class="pt-icon-standard pt-icon-document"></span>
    upload.txt
    <span class="pt-icon-standard pt-icon-cross pt-align-right"></span>
  </button>

  Styleguide components.button.css.advanced
  */
  /*
  Minimal buttons

  For a subtler button that appears to fade into the UI, add the `.pt-minimal` modifier
  to any `.pt-button`. `pt-minimal` is compatible with all other button modifiers,
  except for `.pt-fill` (due to lack of visual affordances).

  Note that minimal buttons are _not supported_ in button groups at this time.

  Markup:
  <a role="button" class="pt-button pt-minimal {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
  <button type="button" class="pt-button pt-minimal pt-icon-add {{.modifier}}" {{:modifier}}>Button</button>

  .pt-disabled - Disabled appearance
  .pt-intent-primary - Primary intent
  .pt-intent-success - Success intent
  .pt-intent-warning - Warning intent
  .pt-intent-danger - Danger intent

  Styleguide components.button.css.minimal
  */ }
  .pt-button:disabled, .pt-button.pt-disabled {
    cursor: not-allowed; }
  .pt-button.pt-fill {
    width: 100%; }
  .pt-button:not([class*="pt-intent-"]) {
    background: #f5f8fa;
    background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
    background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    color: #182026; }
    .pt-button:not([class*="pt-intent-"]):hover {
      background: #ebf1f5;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
      border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
      background-clip: padding-box; }
    .pt-button:not([class*="pt-intent-"]):active, .pt-button.pt-active:not([class*="pt-intent-"]) {
      border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
      background-color: #ced9e0;
      background-image: none; }
    .pt-button:not([class*="pt-intent-"]):disabled, .pt-button.pt-disabled:not([class*="pt-intent-"]) {
      outline: none;
      border-color: transparent;
      box-shadow: none;
      background-clip: border-box;
      background-color: rgba(206, 217, 224, 0.5);
      background-image: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
  .pt-button.pt-intent-primary {
    background: #137cbd;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #137cbd;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #137cbd;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: border-box;
    color: #ffffff; }
    .pt-button.pt-intent-primary:hover, .pt-button.pt-intent-primary:active, .pt-intent-primary.pt-button.pt-active, .pt-button.pt-intent-primary:disabled, .pt-intent-primary.pt-button.pt-disabled {
      color: #ffffff; }
    .pt-button.pt-intent-primary:hover {
      background: #106ba3;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #106ba3;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #106ba3;
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-button.pt-intent-primary:active, .pt-intent-primary.pt-button.pt-active {
      border-color: rgba(16, 22, 26, 0.6) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: #0e5a8a;
      background-image: none; }
    .pt-button.pt-intent-primary:disabled, .pt-intent-primary.pt-button.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(19, 124, 189, 0.5);
      background-image: none; }
    .pt-button.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #ffffff; }
  .pt-button.pt-intent-success {
    background: #0f9960;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #0f9960;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #0f9960;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: border-box;
    color: #ffffff; }
    .pt-button.pt-intent-success:hover, .pt-button.pt-intent-success:active, .pt-intent-success.pt-button.pt-active, .pt-button.pt-intent-success:disabled, .pt-intent-success.pt-button.pt-disabled {
      color: #ffffff; }
    .pt-button.pt-intent-success:hover {
      background: #0d8050;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #0d8050;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #0d8050;
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-button.pt-intent-success:active, .pt-intent-success.pt-button.pt-active {
      border-color: rgba(16, 22, 26, 0.6) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: #0a6640;
      background-image: none; }
    .pt-button.pt-intent-success:disabled, .pt-intent-success.pt-button.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(15, 153, 96, 0.5);
      background-image: none; }
    .pt-button.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #ffffff; }
  .pt-button.pt-intent-warning {
    background: #d9822b;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #d9822b;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #d9822b;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: border-box;
    color: #ffffff; }
    .pt-button.pt-intent-warning:hover, .pt-button.pt-intent-warning:active, .pt-intent-warning.pt-button.pt-active, .pt-button.pt-intent-warning:disabled, .pt-intent-warning.pt-button.pt-disabled {
      color: #ffffff; }
    .pt-button.pt-intent-warning:hover {
      background: #bf7326;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #bf7326;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #bf7326;
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-button.pt-intent-warning:active, .pt-intent-warning.pt-button.pt-active {
      border-color: rgba(16, 22, 26, 0.6) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: #a66321;
      background-image: none; }
    .pt-button.pt-intent-warning:disabled, .pt-intent-warning.pt-button.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(217, 130, 43, 0.5);
      background-image: none; }
    .pt-button.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #ffffff; }
  .pt-button.pt-intent-danger {
    background: #db3737;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #db3737;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #db3737;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: border-box;
    color: #ffffff; }
    .pt-button.pt-intent-danger:hover, .pt-button.pt-intent-danger:active, .pt-intent-danger.pt-button.pt-active, .pt-button.pt-intent-danger:disabled, .pt-intent-danger.pt-button.pt-disabled {
      color: #ffffff; }
    .pt-button.pt-intent-danger:hover {
      background: #c23030;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #c23030;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #c23030;
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-button.pt-intent-danger:active, .pt-intent-danger.pt-button.pt-active {
      border-color: rgba(16, 22, 26, 0.6) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: #a82a2a;
      background-image: none; }
    .pt-button.pt-intent-danger:disabled, .pt-intent-danger.pt-button.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(219, 55, 55, 0.5);
      background-image: none; }
    .pt-button.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #ffffff; }
  .pt-button.pt-loading {
    position: relative; }
    .pt-button.pt-loading[class*="pt-icon-"]::before {
      visibility: hidden; }
    .pt-button.pt-loading .pt-button-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .pt-button.pt-loading > :not(.pt-button-spinner) {
      visibility: hidden; }
  .pt-button[class*="pt-icon-"]::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-right: 7px;
    color: #5c7080; }
  .pt-button .pt-icon, .pt-button .pt-icon-standard, .pt-button .pt-icon-large {
    margin-right: 7px;
    color: #5c7080; }
    .pt-button .pt-icon.pt-align-right, .pt-button .pt-icon-standard.pt-align-right, .pt-button .pt-icon-large.pt-align-right {
      margin-right: 0;
      margin-left: 7px; }
  .pt-dark .pt-button:not([class*="pt-intent-"]) {
    background: rgba(255, 255, 255, 0.1);
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
    border-color: rgba(16, 22, 26, 0.6);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: padding-box;
    color: #f5f8fa; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):hover, .pt-dark .pt-button:not([class*="pt-intent-"]):active, .pt-dark .pt-button.pt-active:not([class*="pt-intent-"]) {
      color: #f5f8fa; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):hover {
      background: rgba(255, 255, 255, 0.1);
      background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      border-color: rgba(16, 22, 26, 0.8);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
      background-clip: padding-box; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):active, .pt-dark .pt-button.pt-active:not([class*="pt-intent-"]) {
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: rgba(16, 22, 26, 0.1);
      background-image: none; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):disabled, .pt-dark .pt-button.pt-disabled:not([class*="pt-intent-"]) {
      border-color: rgba(206, 217, 224, 0.1);
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.1);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-button-spinner .pt-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #8a9ba8; }
    .pt-dark .pt-button:not([class*="pt-intent-"])[class*="pt-icon-"]::before {
      color: #bfccd6; }
    .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon, .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon-standard, .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon-large {
      color: #bfccd6; }
  .pt-dark .pt-button[class*="pt-intent-"] {
    border-color: rgba(16, 22, 26, 0.6);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: padding-box; }
    .pt-dark .pt-button[class*="pt-intent-"]:hover {
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-dark .pt-button[class*="pt-intent-"]:active, .pt-dark [class*="pt-intent-"].pt-button.pt-active {
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-dark .pt-button[class*="pt-intent-"]:disabled, .pt-dark [class*="pt-intent-"].pt-button.pt-disabled {
      border: none;
      box-shadow: none;
      background-image: none;
      color: rgba(255, 255, 255, 0.3); }
    .pt-dark .pt-button[class*="pt-intent-"] .pt-button-spinner .pt-spinner-head {
      stroke: #8a9ba8; }
  .pt-button:disabled::before, .pt-button.pt-disabled::before, .pt-button[class*="pt-intent-"]::before {
    color: inherit !important; }
  .pt-button:disabled .pt-icon, .pt-button.pt-disabled .pt-icon, .pt-button:disabled .pt-icon-standard, .pt-button.pt-disabled .pt-icon-standard, .pt-button:disabled .pt-icon-large, .pt-button.pt-disabled .pt-icon-large, .pt-button[class*="pt-intent-"] .pt-icon, .pt-button[class*="pt-intent-"] .pt-icon-standard, .pt-button[class*="pt-intent-"] .pt-icon-large {
    color: inherit !important; }
  .pt-button.pt-minimal {
    border-color: transparent;
    box-shadow: none;
    background: none; }
    .pt-button.pt-minimal:focus {
      box-shadow: none; }
    .pt-button.pt-minimal:hover {
      border-color: transparent;
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .pt-button.pt-minimal.pt-active, .pt-button.pt-minimal:active, .pt-minimal.pt-button.pt-active {
      border-color: transparent;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .pt-button.pt-minimal.pt-disabled, .pt-button.pt-minimal:disabled, .pt-minimal.pt-button.pt-disabled, .pt-button.pt-minimal:disabled:hover, .pt-minimal.pt-button.pt-disabled:hover {
      border-color: transparent;
      background: inherit;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-button.pt-minimal {
      border-color: transparent;
      box-shadow: none;
      background: none;
      color: inherit; }
      .pt-dark .pt-button.pt-minimal:hover, .pt-dark .pt-button.pt-minimal:active, .pt-dark .pt-minimal.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-active {
        border-color: transparent;
        box-shadow: none;
        background: none; }
      .pt-dark .pt-button.pt-minimal:hover {
        background: rgba(138, 155, 168, 0.15); }
      .pt-dark .pt-button.pt-minimal:active, .pt-dark .pt-minimal.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .pt-dark .pt-button.pt-minimal.pt-disabled, .pt-dark .pt-button.pt-minimal:disabled, .pt-dark .pt-minimal.pt-button.pt-disabled, .pt-dark .pt-button.pt-minimal:disabled:hover, .pt-dark .pt-minimal.pt-button.pt-disabled:hover {
        border-color: transparent;
        background: inherit;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
    .pt-button.pt-minimal.pt-intent-primary {
      color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:hover, .pt-button.pt-minimal.pt-intent-primary:active, .pt-minimal.pt-intent-primary.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-primary.pt-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15); }
      .pt-button.pt-minimal.pt-intent-primary:active, .pt-minimal.pt-intent-primary.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:disabled, .pt-minimal.pt-intent-primary.pt-button.pt-disabled, .pt-button.pt-minimal.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
      .pt-button.pt-minimal.pt-intent-primary .pt-button-spinner .pt-spinner-head {
        stroke: #106ba3; }
      .pt-dark .pt-button.pt-minimal.pt-intent-primary {
        color: #2b95d6; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #2b95d6; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:active, .pt-dark .pt-minimal.pt-intent-primary.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-intent-primary.pt-active {
          background: rgba(19, 124, 189, 0.3);
          color: #2b95d6; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:disabled, .pt-dark .pt-minimal.pt-intent-primary.pt-button.pt-disabled, .pt-dark .pt-button.pt-minimal.pt-intent-primary.pt-disabled {
          color: rgba(43, 149, 214, 0.5); }
    .pt-button.pt-minimal.pt-intent-success {
      color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:hover, .pt-button.pt-minimal.pt-intent-success:active, .pt-minimal.pt-intent-success.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-success.pt-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.15); }
      .pt-button.pt-minimal.pt-intent-success:active, .pt-minimal.pt-intent-success.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:disabled, .pt-minimal.pt-intent-success.pt-button.pt-disabled, .pt-button.pt-minimal.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
      .pt-button.pt-minimal.pt-intent-success .pt-button-spinner .pt-spinner-head {
        stroke: #0d8050; }
      .pt-dark .pt-button.pt-minimal.pt-intent-success {
        color: #15b371; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #15b371; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:active, .pt-dark .pt-minimal.pt-intent-success.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-intent-success.pt-active {
          background: rgba(15, 153, 96, 0.3);
          color: #15b371; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:disabled, .pt-dark .pt-minimal.pt-intent-success.pt-button.pt-disabled, .pt-dark .pt-button.pt-minimal.pt-intent-success.pt-disabled {
          color: rgba(21, 179, 113, 0.5); }
    .pt-button.pt-minimal.pt-intent-warning {
      color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:hover, .pt-button.pt-minimal.pt-intent-warning:active, .pt-minimal.pt-intent-warning.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-warning.pt-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15); }
      .pt-button.pt-minimal.pt-intent-warning:active, .pt-minimal.pt-intent-warning.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:disabled, .pt-minimal.pt-intent-warning.pt-button.pt-disabled, .pt-button.pt-minimal.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
      .pt-button.pt-minimal.pt-intent-warning .pt-button-spinner .pt-spinner-head {
        stroke: #bf7326; }
      .pt-dark .pt-button.pt-minimal.pt-intent-warning {
        color: #f29d49; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #f29d49; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:active, .pt-dark .pt-minimal.pt-intent-warning.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-intent-warning.pt-active {
          background: rgba(217, 130, 43, 0.3);
          color: #f29d49; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:disabled, .pt-dark .pt-minimal.pt-intent-warning.pt-button.pt-disabled, .pt-dark .pt-button.pt-minimal.pt-intent-warning.pt-disabled {
          color: rgba(242, 157, 73, 0.5); }
    .pt-button.pt-minimal.pt-intent-danger {
      color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:hover, .pt-button.pt-minimal.pt-intent-danger:active, .pt-minimal.pt-intent-danger.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-danger.pt-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15); }
      .pt-button.pt-minimal.pt-intent-danger:active, .pt-minimal.pt-intent-danger.pt-button.pt-active, .pt-button.pt-minimal.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:disabled, .pt-minimal.pt-intent-danger.pt-button.pt-disabled, .pt-button.pt-minimal.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
      .pt-button.pt-minimal.pt-intent-danger .pt-button-spinner .pt-spinner-head {
        stroke: #c23030; }
      .pt-dark .pt-button.pt-minimal.pt-intent-danger {
        color: #f55656; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #f55656; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:active, .pt-dark .pt-minimal.pt-intent-danger.pt-button.pt-active, .pt-dark .pt-button.pt-minimal.pt-intent-danger.pt-active {
          background: rgba(219, 55, 55, 0.3);
          color: #f55656; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:disabled, .pt-dark .pt-minimal.pt-intent-danger.pt-button.pt-disabled, .pt-dark .pt-button.pt-minimal.pt-intent-danger.pt-disabled {
          color: rgba(245, 86, 86, 0.5); }
  .pt-button.pt-large,
  .pt-large .pt-button {
    min-width: 40px;
    min-height: 40px;
    line-height: 38px;
    padding: 0 15px;
    font-size: 16px; }
    .pt-button.pt-large[class*="pt-icon-"]::before,
    .pt-large .pt-button[class*="pt-icon-"]::before {
      margin-right: 10px; }
    .pt-button.pt-large .pt-icon, .pt-button.pt-large .pt-icon-standard, .pt-button.pt-large .pt-icon-large,
    .pt-large .pt-button .pt-icon,
    .pt-large .pt-button .pt-icon-standard,
    .pt-large .pt-button .pt-icon-large {
      margin-right: 10px; }
      .pt-button.pt-large .pt-icon.pt-align-right, .pt-button.pt-large .pt-icon-standard.pt-align-right, .pt-button.pt-large .pt-icon-large.pt-align-right,
      .pt-large .pt-button .pt-icon.pt-align-right,
      .pt-large .pt-button .pt-icon-standard.pt-align-right,
      .pt-large .pt-button .pt-icon-large.pt-align-right {
        margin-right: 0;
        margin-left: 10px; }
  .pt-button[class*="pt-icon-"]:empty {
    padding: 0; }
    .pt-button[class*="pt-icon-"]:empty::before {
      margin-right: 0; }

a.pt-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.pt-button, a.pt-button:hover, a.pt-button:active, a.pt-button.pt-active {
    color: #182026; }
  a.pt-disabled.pt-button {
    color: rgba(92, 112, 128, 0.5); }

.pt-button.pt-active.pt-disabled, .pt-button.pt-active:disabled, .pt-active.pt-button.pt-disabled {
  background: rgba(216, 225, 232, 0.4); }
  .pt-dark .pt-button.pt-active.pt-disabled, .pt-dark .pt-button.pt-active:disabled, .pt-dark .pt-active.pt-button.pt-disabled {
    background: rgba(206, 217, 224, 0.2); }

/*
JavaScript API

The `Button` and `AnchorButton` components are available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

Button components render buttons with Blueprint classes and attributes.
See the [Buttons CSS docs](#components.button.css) for styling options.

You can provide your own props to these components as if they were regular JSX HTML elements. If you
provide a `className` prop, the class names you provide will be added alongside of the default
Blueprint class name. If you specify other attributes that the component provides, such as a `role`
for an `<AnchorButton>`, you'll overide the default value.

<div class="pt-callout pt-intent-danger pt-icon-error">
  <h5>Interactions with disabled buttons</h5>
  Use `AnchorButton` if you need mouse interaction events (such as hovering) on a disabled button.
  This is because `Button` and `AnchorButton` handle the `disabled` prop differently: `Button` uses
  the native `disabled` attribute on the `<button>` tag so the browser disables all interactions,
  but `AnchorButton` uses the class `.pt-disabled` because `<a>` tags do not support the `disabled`
  attribute. As a result, the `AnchorButton` component will prevent *only* the `onClick` handler
  when disabled but permit other events.
</div>

@react-example ButtonsExample

Styleguide components.button.js
*/
/*
Anchor button

```
<AnchorButton text="Click" />
// renders:
<a class="pt-button" role="button" tabIndex={0}>Click</a>
```

Styleguide: components.button.js.anchor-button
*/
/*
Button

```
<Button iconName="refresh" />
// renders:
<button class="pt-button pt-icon-refresh" type="button"></button>
```

@interface IButtonProps

Styleguide: components.button.js.button
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Button groups

Button groups arrange multiple buttons in a horizontal or vertical group.

Styleguide components.button-group
*/
/*
CSS API

Arrange multiple buttons in a group by wrapping them in `.pt-button-group`.
You can apply sizing directly on the button group container element.

You should implement interactive segmented controls as button groups.

Markup:
<div class="pt-button-group {{.modifier}}">
  <a class="pt-button pt-icon-database" tabindex="0" role="button">Queries</a>
  <a class="pt-button pt-icon-function" tabindex="0" role="button">Functions</a>
  <a class="pt-button" tabindex="0" role="button">
    Options <span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
  </a>
</div>
<br /><br />
<div class="pt-button-group {{.modifier}}">
  <a class="pt-button pt-icon-chart" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-control" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-graph" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-camera" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-map" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-code" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-th" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-time" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-compressed" tabindex="0" role="button"></a>
</div>
<br /><br />
<div class="pt-button-group {{.modifier}}">
  <button type="button" class="pt-button pt-intent-success">Save</button>
  <button type="button" class="pt-button pt-intent-success pt-icon-caret-down"></button>
</div>

.pt-large - Use large buttons
.pt-minimal - Use minimal buttons. Note that these minimal buttons will not automatically
              truncate text in an ellipsis because of the divider line added in CSS.

Styleguide components.button-group.css
*/
.pt-button-group {
  display: inline-flex;
  /*
  Responsive button groups

  Add the class `pt-fill` to a button group to make all buttons expand equally to fill the
  available space. Then add the class `pt-fixed` to individual buttons to revert them to their
  original default sizes.

  Alternatively, add the class `pt-fill` to an individual button (instead of to the container)
  to expand it to fill the available space while other buttons retain their original sizes.

  You can adjust the specific size of a button with the `flex-basis` CSS property.

  Markup:
  <div class="pt-button-group pt-large pt-fill">
    <a class="pt-button pt-intent-primary pt-fixed" tabindex="0" role="button">Start</a>
    <a class="pt-button pt-intent-primary" tabindex="0" role="button">Left</a>
    <a class="pt-button pt-intent-primary pt-active" tabindex="0" role="button">Middle</a>
    <a class="pt-button pt-intent-primary" tabindex="0" role="button">Right</a>
    <a class="pt-button pt-intent-primary pt-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="pt-button-group pt-fill">
    <button class="pt-button pt-icon-arrow-left"></button>
    <button class="pt-button pt-fill">Middle</button>
    <button class="pt-button pt-icon-arrow-right"></button>
  </div>

  Styleguide components.button-group.css.fill
  */
  /*
  Vertical button groups

  Add the class `pt-vertical` to create a vertical button group. The buttons in a vertical
  group all have the same size as the widest button in the group.

  Add the modifier class `pt-align-left` to left-align all button text and icons.

  You can also combine vertical groups with the `pt-fill` and `pt-minimal` class modifiers.

  Markup:
  <div class="pt-button-group pt-vertical" style="padding-right: 10px">
    <a class="pt-button pt-icon-search-template" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="pt-button-group pt-vertical" style="padding-right: 10px">
    <button type="button" class="pt-button pt-active">Home</button>
    <button type="button" class="pt-button">Pages</button>
    <button type="button" class="pt-button">Blog</button>
    <button type="button" class="pt-button">Calendar</button>
  </div>
  <div class="pt-button-group pt-vertical pt-align-left pt-large">
    <button type="button" class="pt-button pt-intent-primary pt-icon-document">Text</button>
    <button type="button" class="pt-button pt-intent-primary pt-icon-media pt-active">Media</button>
    <button type="button" class="pt-button pt-intent-primary pt-icon-link">Sources</button>
  </div>

  Styleguide components.button-group.css.vertical
  */ }
  .pt-button-group .pt-button {
    flex: 0 0 auto;
    position: relative;
    z-index: 0; }
    .pt-button-group .pt-button[class*="pt-intent-"] {
      z-index: 1; }
    .pt-button-group .pt-button:focus {
      z-index: 2; }
    .pt-button-group .pt-button:hover {
      z-index: 3; }
    .pt-button-group .pt-button:active, .pt-button-group .pt-button.pt-active, .pt-button-group .pt-button.pt-active {
      z-index: 4; }
    .pt-button-group .pt-button:disabled, .pt-button-group .pt-button.pt-disabled, .pt-button-group .pt-button.pt-disabled {
      z-index: 0; }
  .pt-button-group:not(.pt-vertical) .pt-button:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) {
    border-right-color: transparent; }
  .pt-button-group:not(.pt-minimal) > .pt-popover-target:not(:first-child) .pt-button,
  .pt-button-group:not(.pt-minimal) > .pt-button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .pt-button-group:not(.pt-minimal) > .pt-popover-target:not(:last-child) .pt-button,
  .pt-button-group:not(.pt-minimal) > .pt-button:not(:last-child) {
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-button-group.pt-minimal .pt-button {
    border-color: transparent;
    box-shadow: none;
    background: none;
    margin-right: 11px;
    border-radius: 3px !important;
    overflow: visible; }
    .pt-button-group.pt-minimal .pt-button:focus {
      box-shadow: none; }
    .pt-button-group.pt-minimal .pt-button:hover {
      border-color: transparent;
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .pt-button-group.pt-minimal .pt-button.pt-active, .pt-button-group.pt-minimal .pt-button:active, .pt-button-group.pt-minimal .pt-button.pt-active {
      border-color: transparent;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button:disabled, .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button:disabled:hover, .pt-button-group.pt-minimal .pt-button.pt-disabled:hover {
      border-color: transparent;
      background: inherit;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-button-group.pt-minimal .pt-button {
      border-color: transparent;
      box-shadow: none;
      background: none;
      color: inherit; }
      .pt-dark .pt-button-group.pt-minimal .pt-button:hover, .pt-dark .pt-button-group.pt-minimal .pt-button:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active {
        border-color: transparent;
        box-shadow: none;
        background: none; }
      .pt-dark .pt-button-group.pt-minimal .pt-button:hover {
        background: rgba(138, 155, 168, 0.15); }
      .pt-dark .pt-button-group.pt-minimal .pt-button:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button:disabled:hover, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-disabled:hover {
        border-color: transparent;
        background: inherit;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-primary {
      color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-button-group.pt-minimal .pt-intent-primary.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-button-group.pt-minimal .pt-intent-primary.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:disabled, .pt-button-group.pt-minimal .pt-intent-primary.pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary .pt-button-spinner .pt-spinner-head {
        stroke: #106ba3; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary {
        color: #2b95d6; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #2b95d6; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-dark .pt-button-group.pt-minimal .pt-intent-primary.pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
          background: rgba(19, 124, 189, 0.3);
          color: #2b95d6; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:disabled, .pt-dark .pt-button-group.pt-minimal .pt-intent-primary.pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-disabled {
          color: rgba(43, 149, 214, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-success {
      color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-button-group.pt-minimal .pt-intent-success.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.15); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-button-group.pt-minimal .pt-intent-success.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:disabled, .pt-button-group.pt-minimal .pt-intent-success.pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success .pt-button-spinner .pt-spinner-head {
        stroke: #0d8050; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success {
        color: #15b371; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #15b371; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-dark .pt-button-group.pt-minimal .pt-intent-success.pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
          background: rgba(15, 153, 96, 0.3);
          color: #15b371; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:disabled, .pt-dark .pt-button-group.pt-minimal .pt-intent-success.pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-disabled {
          color: rgba(21, 179, 113, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-warning {
      color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-button-group.pt-minimal .pt-intent-warning.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-button-group.pt-minimal .pt-intent-warning.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:disabled, .pt-button-group.pt-minimal .pt-intent-warning.pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning .pt-button-spinner .pt-spinner-head {
        stroke: #bf7326; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning {
        color: #f29d49; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #f29d49; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-dark .pt-button-group.pt-minimal .pt-intent-warning.pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
          background: rgba(217, 130, 43, 0.3);
          color: #f29d49; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:disabled, .pt-dark .pt-button-group.pt-minimal .pt-intent-warning.pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-disabled {
          color: rgba(242, 157, 73, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-danger {
      color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-button-group.pt-minimal .pt-intent-danger.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-button-group.pt-minimal .pt-intent-danger.pt-button.pt-active, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:disabled, .pt-button-group.pt-minimal .pt-intent-danger.pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger .pt-button-spinner .pt-spinner-head {
        stroke: #c23030; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger {
        color: #f55656; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #f55656; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-dark .pt-button-group.pt-minimal .pt-intent-danger.pt-button.pt-active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
          background: rgba(219, 55, 55, 0.3);
          color: #f55656; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:disabled, .pt-dark .pt-button-group.pt-minimal .pt-intent-danger.pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-disabled {
          color: rgba(245, 86, 86, 0.5); }
    .pt-button-group.pt-minimal .pt-button:focus {
      outline-style: solid; }
    .pt-button-group.pt-minimal .pt-button::after {
      margin: 5px;
      background: rgba(16, 22, 26, 0.15);
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 100%;
      content: ""; }
      .pt-dark .pt-button-group.pt-minimal .pt-button::after {
        background: rgba(255, 255, 255, 0.15); }
  .pt-button-group.pt-minimal > .pt-popover-target:last-child .pt-button,
  .pt-button-group.pt-minimal > .pt-button:last-child {
    margin-right: 0; }
    .pt-button-group.pt-minimal > .pt-popover-target:last-child .pt-button::after,
    .pt-button-group.pt-minimal > .pt-button:last-child::after {
      display: none; }
  .pt-button-group.pt-fill {
    display: flex; }
  .pt-button-group .pt-button.pt-fill,
  .pt-button-group.pt-fill .pt-button:not(.pt-fixed) {
    flex: 1 1 auto; }
  .pt-button-group.pt-vertical {
    flex-direction: column;
    align-items: stretch;
    vertical-align: top; }
    .pt-button-group.pt-vertical .pt-button {
      margin-right: 0 !important; }
      .pt-button-group.pt-vertical .pt-button:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) {
        border-bottom-color: transparent; }
    .pt-button-group.pt-vertical > .pt-popover-target:first-child .pt-button,
    .pt-button-group.pt-vertical > .pt-button:first-child {
      border-radius: 3px 3px 0 0; }
    .pt-button-group.pt-vertical > .pt-popover-target:last-child .pt-button,
    .pt-button-group.pt-vertical > .pt-button:last-child {
      border-radius: 0 0 3px 3px; }
    .pt-button-group.pt-vertical > .pt-popover-target:not(:last-child) .pt-button,
    .pt-button-group.pt-vertical > .pt-button:not(:last-child) {
      margin-bottom: -1px; }
    .pt-button-group.pt-vertical.pt-minimal .pt-button:not(:last-child) {
      margin-bottom: 11px; }
    .pt-button-group.pt-vertical.pt-minimal .pt-button::after {
      top: 100%;
      right: 0;
      left: 0;
      width: auto;
      height: 1px; }
  .pt-button-group.pt-align-left .pt-button {
    text-align: left; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Callouts

Callouts visually highlight important content for the user.

Styleguide components.callout
*/
/*
CSS API

Callouts use the same visual intent modifier classes as buttons. If you need a
heading, use the `<h5>` element.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  Note that the `<h5>` heading is entirely optional.
</div>

Markup:
<div class="pt-callout {{.modifier}}">
  <h5>Callout Heading</h5>
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex, delectus!
</div>

.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger  - Danger intent
.pt-icon-info-sign - With an icon

Styleguide components.callout.css
*/
.pt-callout {
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(138, 155, 168, 0.15);
  padding: 10px 12px 9px; }
  .pt-callout[class*="pt-icon-"] {
    padding-left: 40px; }
    .pt-callout[class*="pt-icon-"]::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #5c7080; }
  .pt-callout h5 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 20px; }
  .pt-dark .pt-callout {
    background-color: rgba(138, 155, 168, 0.2); }
    .pt-dark .pt-callout[class*="pt-icon-"]::before {
      color: #bfccd6; }
  .pt-callout.pt-intent-primary {
    background-color: rgba(19, 124, 189, 0.15); }
    .pt-callout.pt-intent-primary[class*="pt-icon-"]::before {
      color: #137cbd; }
    .pt-callout.pt-intent-primary h5 {
      color: #106ba3; }
    .pt-dark .pt-callout.pt-intent-primary {
      background-color: rgba(19, 124, 189, 0.25); }
      .pt-dark .pt-callout.pt-intent-primary h5 {
        color: #2b95d6; }
  .pt-callout.pt-intent-success {
    background-color: rgba(15, 153, 96, 0.15); }
    .pt-callout.pt-intent-success[class*="pt-icon-"]::before {
      color: #0f9960; }
    .pt-callout.pt-intent-success h5 {
      color: #0d8050; }
    .pt-dark .pt-callout.pt-intent-success {
      background-color: rgba(15, 153, 96, 0.25); }
      .pt-dark .pt-callout.pt-intent-success h5 {
        color: #15b371; }
  .pt-callout.pt-intent-warning {
    background-color: rgba(217, 130, 43, 0.15); }
    .pt-callout.pt-intent-warning[class*="pt-icon-"]::before {
      color: #d9822b; }
    .pt-callout.pt-intent-warning h5 {
      color: #bf7326; }
    .pt-dark .pt-callout.pt-intent-warning {
      background-color: rgba(217, 130, 43, 0.25); }
      .pt-dark .pt-callout.pt-intent-warning h5 {
        color: #f29d49; }
  .pt-callout.pt-intent-danger {
    background-color: rgba(219, 55, 55, 0.15); }
    .pt-callout.pt-intent-danger[class*="pt-icon-"]::before {
      color: #db3737; }
    .pt-callout.pt-intent-danger h5 {
      color: #c23030; }
    .pt-dark .pt-callout.pt-intent-danger {
      background-color: rgba(219, 55, 55, 0.25); }
      .pt-dark .pt-callout.pt-intent-danger h5 {
        color: #f55656; }
  .pt-running-text .pt-callout {
    margin: 20px 0; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Cards

A card is a bounded unit of UI content with a solid background color.

Styleguide components.card
*/
/*
CSS API

Start with `.pt-card` and add an elevation modifier class to apply a drop shadow that simulates
height in the UI.

You can also use the `.pt-elevation-*` classes by themselves to apply shadows to any arbitrary
element.

Markup:
<div class="pt-card {{.modifier}}">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec dapibus et mauris,
  vitae dictum metus.
</div>

.pt-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.pt-elevation-1 - First. Subtle drop shadow intended for static containers.
.pt-elevation-2 - Second. An even stronger shadow, moving on up.
.pt-elevation-3 - Third. For containers overlaying content temporarily.
.pt-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.

Styleguide components.card.css
*/
.pt-card {
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
  background-color: #ffffff;
  padding: 20px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-card.pt-dark,
  .pt-dark .pt-card {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }

.pt-elevation-0 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-elevation-0.pt-dark,
  .pt-dark .pt-elevation-0 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.pt-elevation-1 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-elevation-1.pt-dark,
  .pt-dark .pt-elevation-1 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4); }

.pt-elevation-2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2); }
  .pt-elevation-2.pt-dark,
  .pt-dark .pt-elevation-2 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4); }

.pt-elevation-3 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .pt-elevation-3.pt-dark,
  .pt-dark .pt-elevation-3 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.pt-elevation-4 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2); }
  .pt-elevation-4.pt-dark,
  .pt-dark .pt-elevation-4 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4); }

/*
Interactive cards

Add the `.pt-interactive` modifier class to make a `.pt-card` respond to user interactions. When you
hover over cards with this class applied, the mouse changes to a pointer and the elevation shadow on
the card increases by two levels.

Users expect an interactive card to be a single clickable unit.

Markup:
<div class="docs-card-example">
  <div class="pt-card pt-elevation-0 pt-interactive">
    <h5><a href="#">Trader Profile</a></h5>
    <p>Overview of employee activity, including risk model, scores and scenario alert history.</p>
  </div>
  <div class="pt-card pt-elevation-1 pt-interactive">
    <h5><a href="#">Desk Profile</a></h5>
    <p>Desk-level summary of trading activity and trading profiles.</p>
  </div>
  <div class="pt-card pt-elevation-2 pt-interactive">
    <h5><a href="#">Dataset Dashboards</a></h5>
    <p>Stats of dataset completeness and reference data join percentages.</p>
  </div>
</div>

Styleguide components.card.css.interactive
*/
.pt-card.pt-interactive:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  cursor: pointer; }
  .pt-card.pt-interactive:hover.pt-dark,
  .pt-dark .pt-card.pt-interactive:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.pt-card.pt-interactive:active {
  opacity: 0.9;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2);
  transition-duration: 0; }
  .pt-card.pt-interactive:active.pt-dark,
  .pt-dark .pt-card.pt-interactive:active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Collapse

The `Collapse` element shows and hides content with a built-in slide in/out animation.
You might use this to create a panel of settings for your application, with sub-sections
that can be expanded and collapsed.

@react-example CollapseExample

Styleguide components.collapse
*/
/*
JavaScript API

The `Collapse` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

Any content should be a child of the `Collapse` element. Content must be in the document
flow (e.g. `position: absolute;` wouldn't work, as the parent element would inherit a height of 0).

Toggling the `isOpen` prop triggers the open and close animations.
Once the component is in the closed state, the children are no longer rendered.

```
export interface ICollapseExampleState {
    isOpen?: boolean;
};

export class CollapseExample extends React.Component<{}, ICollapseExampleState> {
    public state = {
        isOpen: false,
    };

    public render() {
        return (
            <div>
                <Button onClick={this.handleClick}>
                    {this.state.isOpen ? "Hide" : "Show"} build logs
                </Button>
                <Collapse isOpen={this.state.isOpen}>
                    <pre>
                        Dummy text.
                    </pre>
                </Collapse>
            </div>
        );
    }

    private handleClick = () => {
        this.setState({isOpen: !this.state.isOpen});
    }
}
```

@interface ICollapseProps

Styleguide components.collapse.js
*/
.pt-collapse {
  height: 0;
  overflow: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-collapse .pt-collapse-body {
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

/*
Collapsible list

The `CollapsibleList` React component accepts a list of menu items and a count of visible items. It
shows precisely that many items and collapses the rest into a dropdown menu. The required
`renderVisibleItem` callback prop allows for customizing the appearance of visible items, using the
props from the `MenuItem` children.

@react-example CollapsibleListExample

Styleguide components.collapsiblelist
*/
/*
JavaScript API

The `CollapsibleList` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

Children of the `CollapsibleList` component _must_ be `MenuItem`s so they can be easily rendered
in the dropdown. Define a `renderVisibleItem` callback to customize the appearance of visible
items using their [`IMenuItemProps`](#components.menu.js.menu-item).

@interface ICollapsibleListProps

Weight: -10

Styleguide components.collapsiblelist.js
*/
/*
Separators

Often a list of items calls for separators between each item.
Adding separators to a `CollapsibleList` is easily achieved via CSS using `::after` pseudo-elements.

```css.scss
// pass `visibleItemClassName="my-list-item"` to component, then...

.my-list-item::after {
  display: inline-block;
  content: "";
  // custom separator styles...
}

// remove separator after the last item
.my-list-item:last-child::after {
  display: none;
}
```

Styleguide components.collapsiblelist.separator
*/
/*
Context menus

Context menus present the user with a custom list of actions upon right-click.

You can create context menus in either of the following ways:

- by adding the `@ContextMenuTarget` [decorator](#components.context-menu.decorator) to a React
  Component that implements `renderContextMenu(): JSX.Element`.
- via the [imperative](#components.context-menu.js) `ContextMenu.show` and `ContextMenu.hide` API
  methods, ideal for non-React-based applications.

@react-example ContextMenuExample

Styleguide components.context-menu
*/
/*
JavaScript API: decorator

The `ContextMenuTarget` decorator is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

The `@ContextMenuTarget` [class decorator][ts-decorator] can be applied to any `React.Component`
class that meets the following requirements:

- It defines an instance method called `renderContextMenu()` that returns a single `JSX.Element`
  (most likely a [`Menu`](#components.menu)).
- Its root element supports the `"contextmenu"` event and the `onContextMenu` prop.

  This is always true if the decorated class uses an intrinsic element, such as `<div>`, as its
  root. If it uses a custom element as its root, you must ensure that the prop is implemented
  correctly for that element.

When the user triggers the `"contextmenu"` event on the decorated class, `renderContextMenu()` is
called. If `renderContextMenu()` returns an element, the browser's native [context menu][wiki-cm] is
blocked and the returned element is displayed instead in a `Popover` at the cursor's location.

```
import { ContextMenuTarget, Menu, MenuItem } from "@blueprintjs/core";

@ContextMenuTarget
class RightClickMe extends React.Component<{}, {}> {
    public render() {
      // root element must support `onContextMenu`
      return <div>{...}</div>;
    }

    public renderContextMenu() {
        // return a single element, or nothing to use default browser behavior
        return (
            <Menu>
                <MenuItem onClick={this.handleSave} text="Save" />
                <MenuItem onClick={this.handleDelete} text="Delete" />
            </Menu>
        );
    }
}
```

[ts-decorator]: https://github.com/Microsoft/TypeScript-Handbook/blob/master/pages/Decorators.md
[wiki-cm]: https://en.wikipedia.org/wiki/Context_menu

Styleguide components.context-menu.decorator
*/
/*
JavaScript API: imperative

The `ContextMenu` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

The imperative API provides a single static `ContextMenu` object, enforcing the principle that only
one context menu can be open at a time.

- `ContextMenu.show(menu: JSX.Element, offset: IOffset, onClose?: () => void): void` &ndash;
  Show the given element at the given offset from the top-left corner of the viewport.
  Showing a menu closes the previously shown one automatically.

  The menu appears below-right of this point, but will flip to below-left instead if there is not
  enough room onscreen. The optional callback is invoked when this menu closes.

- `ContextMenu.hide(): void` &ndash; Hide the context menu, if it is open.
- `ContextMenu.isOpen(): boolean` &ndash; Whether a context menu is currently visible.

This API is ideal for non-React-based apps or for programmatically triggered menus.

```
import { ContextMenu, MenuFactory, MenuItemFactory } from "@blueprintjs/core";

const rightClickMe = document.query("#right-click-me") as HTMLElement;
rightClickMe.oncontextmenu = (e: MouseEvent) => {
    // prevent the browser's native context menu
    e.preventDefault();
    // render a Menu without JSX...
    const menu = MenuFactory({
        children: [
            MenuItemFactory({ onClick: handleSave, text: "Save" }),
            MenuItemFactory({ onClick: handleDelete, text: "Delete" }),
        ]
    });
    // mouse position is available on event
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
        // menu was closed; callback optional
    });
};
```

Styleguide components.context-menu.js
*/
.pt-context-menu .pt-popover-target {
  display: block; }

.pt-context-menu-popover-target {
  position: fixed; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Dialogs

Dialogs present content overlaid over other parts of the UI.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Terminology note</h5>
  The term "modal" is sometimes used to mean "dialog," but this is a misnomer.
  _Modal_ is an adjective that describes parts of a UI.
  An element is considered modal if it
  [blocks interaction with the rest of the application](https://en.wikipedia.org/wiki/Modal_window).
  We use the term "dialog" to avoid confusion with the adjective.
</div>

Styleguide components.dialog
*/
/*
JavaScript API

The `Dialog` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

There are two ways to render dialogs:

- in-place in the DOM tree. This is the default behavior.
- injected into a newly created element attached to `document.body`.
  Set `inline={false}` to enable this.

`Dialog` is implemented as a stateless React component. The children you provide to this component
are rendered as contents inside the `.pt-dialog` element.

```
interface IDialogExampleState {
    isOpen: boolean;
}

class DialogExample extends React.Component<{}, IDialogExampleState> {
    public state = { isOpen: false };

    public render() {
        return (
            <div>
                <Button onClick={this.toggleDialog} text="Show dialog" />
                <Dialog
                    iconName="inbox"
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title="Dialog Header"
                >
                    <div className="pt-dialog-body">
                        Some content
                    </div>
                    <div className="pt-dialog-footer">
                        <div className="pt-dialog-footer-actions">
                            <Button text="Secondary" />
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={this.toggleDialog}
                                text="Primary"
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }

    private toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });
}
```

@interface IDialogProps

@react-example DialogExample

Styleguide components.dialog.js
*/
.pt-dialog {
  opacity: 1;
  transform: translateX(50%) scale(1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 25%;
  right: 50%;
  z-index: 20;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #ebf1f5;
  width: 500px;
  padding-bottom: 20px; }
  .pt-dialog.pt-overlay-enter, .pt-dialog.pt-overlay-appear {
    opacity: 0;
    transform: translateX(50%) scale(0.5); }
  .pt-dialog.pt-overlay-enter-active, .pt-dialog.pt-overlay-appear-active {
    opacity: 1;
    transform: translateX(50%) scale(1);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-dialog.pt-overlay-leave {
    opacity: 1;
    transform: translateX(50%) scale(1); }
  .pt-dialog.pt-overlay-leave-active {
    opacity: 0;
    transform: translateX(50%) scale(0.5);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-dialog:focus {
    outline: 0; }
  .pt-dialog.pt-dark,
  .pt-dark .pt-dialog {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    background: #293742;
    color: #f5f8fa; }

.pt-dialog-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  background: #ffffff;
  min-height: 40px;
  padding-left: 20px; }
  .pt-dialog-header .pt-icon-large {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #5c7080; }
  .pt-dialog-header h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    margin: 0;
    line-height: inherit; }
    .pt-dialog-header h5:last-child {
      margin-right: 20px; }
  .pt-dark .pt-dialog-header {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
    background: #30404d; }
    .pt-dark .pt-dialog-header .pt-icon-large {
      color: #bfccd6; }
    .pt-dark .pt-dialog-header h5 {
      color: #f5f8fa; }

.pt-dialog-close-button {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5c7080;
  flex: 0 0 auto;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px; }
  .pt-dialog-close-button:hover {
    color: #182026; }
  .pt-dark .pt-dialog-close-button {
    color: #bfccd6; }
    .pt-dark .pt-dialog-close-button:hover {
      color: #f5f8fa; }

.pt-dialog-body {
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px; }

.pt-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.pt-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .pt-dialog-footer-actions .pt-button {
    margin-left: 10px; }

/*
CSS API

You can create dialogs manually using the HTML markup and `pt-dialog-*` classes below.
However, you should use the dialog [JavaScript APIs](#components.dialog.js) whenever possible,
as they automatically generate some of this markup.

More examples of dialog content are shown below.

Markup:
<div class="pt-dialog">
  <div class="pt-dialog-header">
    <span class="pt-icon-large pt-icon-inbox"></span>
    <h5>Dialog Header</h5>
    <button aria-label="Close" class="pt-dialog-close-button pt-icon-small-cross"></button>
  </div>
  <div class="pt-dialog-body">
    This dialog hasn't been wired up with any open or close interactions.
    It's just an example of markup and styles.
  </div>
  <div class="pt-dialog-footer">
    <div class="pt-dialog-footer-actions">
      <button type="button" class="pt-button">Secondary button</button>
      <button type="submit" class="pt-button pt-intent-primary">Primary button</button>
    </div>
  </div>
</div>

Styleguide components.dialog.css
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
.pt-select select {
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  background: #f5f8fa;
  background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
  box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    background: #ebf1f5;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box; }
  .pt-select select:active {
    border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
    background-color: #ced9e0;
    background-image: none; }
  .pt-select select:disabled {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-clip: border-box;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  background: rgba(255, 255, 255, 0.1);
  background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
  border-color: rgba(16, 22, 26, 0.6);
  box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
  background-clip: padding-box;
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    background: rgba(255, 255, 255, 0.1);
    background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
    background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
    border-color: rgba(16, 22, 26, 0.8);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: padding-box; }
  .pt-dark .pt-select select:active {
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background-color: rgba(16, 22, 26, 0.1);
    background-image: none; }
  .pt-dark .pt-select select:disabled {
    border-color: rgba(206, 217, 224, 0.1);
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.1);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "⌄";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
Editable text

`EditableText` looks like normal UI text, but transforms into a text input field when the user
focuses it.

The text input inherits all font styling from its ancestors, making the transition between reading
and editing text seamless.

You might use this component for inline renaming, or for an [editable multiline
description](#components.editable.multiline). You should not use `EditableText` when a static
always-editable `<input>` or `<textarea>` tag would suffice.

<div class="pt-callout pt-intent-danger pt-icon-error">
  <h5>Centering the component</h5>
  **Do not center this component** using `text-align: center`, as it will cause an infinite loop
  in the browser ([more details](https://github.com/JedWatson/react-select/issues/540)). Instead,
  you should center the component via flexbox or with `position` and `transform: translateX(-50%)`.
</div>

@react-example EditableTextExample

Styleguide components.editable
*/
/*
JavaScript API

The `EditableText` component is available in the __@blueprintjs/core__ package. Make sure to review
the [general usage docs for JS components](#components.usage).

`EditableText` can be used like an [`input`
element](https://facebook.github.io/react/docs/forms.html) and supports controlled or uncontrolled
usage through the `value` or `defaultValue` props, respectively.

The `onConfirm` and `onCancel` callbacks are invoked based on user interaction. The user presses
<kbd class="pt-key">enter</kbd> or blurs the input to confirm the current value, or presses <kbd
class="pt-key">esc</kbd> to cancel. Canceling resets the field to the last confirmed value.

`EditableText` by default supports _exactly one line of text_ and will grow or shrink horizontally
based on the length of text. See below for information on [multiline
support](#components.editable.multiline).

@interface IEditableTextProps

Styleguide components.editable.js
*/
/*
Multiline mode

```
<EditableText multiline minLines={3} maxLines={12} {...props} />
```

Provide the `multiline` prop to create an `EditableText` field that spans multiple lines. Multiline
mode uses a `<textarea>` instead of an `<input type="text">` to support multiple lines of text.

Users confirm text in multiline mode by pressing <kbd class="pt-key">ctrl</kbd> <kbd
class="pt-key">enter</kbd> or
<kbd class="pt-key">cmd</kbd> <kbd class="pt-key">enter</kbd> rather than simply <kbd
class="pt-key">enter</kbd>. (Pressing the <kbd class="pt-key">enter</kbd> key by itself moves the
cursor to the next line.)

Additionally, in multiline mode the component's width is fixed at 100%. It grows or shrinks
_vertically_ instead, based on the number of lines of text. You can use the `minLines` and
`maxLines` props to constrain the vertical size of the component.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Multiline prop format</h5>
  You should declare `multiline` as a valueless boolean prop, as in the example above
  (`<EditableText multiline ...>`). This prevents you from changing the value after declaring it,
  which would provide a sub-optimal experience for users (multiline text does not always render
  cleanly into a single line).
</div>

Styleguide components.editable.multiline
*/
.pt-editable-text {
  display: inline-block;
  position: relative;
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }
  .pt-editable-text::before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background-color: #ffffff; }
  .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-editable-text.pt-intent-primary .pt-editable-input,
  .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #137cbd; }
  .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }
  .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-success .pt-editable-input,
  .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #0f9960; }
  .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-warning .pt-editable-input,
  .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #d9822b; }
  .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-danger .pt-editable-input,
  .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #db3737; }
  .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-dark .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .pt-dark .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background-color: rgba(16, 22, 26, 0.3); }
  .pt-dark .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-dark .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #2b95d6; }
  .pt-dark .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(43, 149, 214, 0), 0 0 0 0 rgba(43, 149, 214, 0), inset 0 0 0 1px rgba(43, 149, 214, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #2b95d6, 0 0 0 3px rgba(43, 149, 214, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #15b371; }
  .pt-dark .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(21, 179, 113, 0), 0 0 0 0 rgba(21, 179, 113, 0), inset 0 0 0 1px rgba(21, 179, 113, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #15b371, 0 0 0 3px rgba(21, 179, 113, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #f29d49; }
  .pt-dark .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(242, 157, 73, 0), 0 0 0 0 rgba(242, 157, 73, 0), inset 0 0 0 1px rgba(242, 157, 73, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #f29d49, 0 0 0 3px rgba(242, 157, 73, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #f55656; }
  .pt-dark .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(245, 86, 86, 0), 0 0 0 0 rgba(245, 86, 86, 0), inset 0 0 0 1px rgba(245, 86, 86, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #f55656, 0 0 0 3px rgba(245, 86, 86, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.pt-editable-input,
.pt-editable-content {
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none; }

.pt-editable-input {
  border: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding: 0;
  white-space: pre-wrap; }
  .pt-editable-input:focus {
    outline: none; }
  .pt-editable-input::-ms-clear {
    display: none; }

.pt-editable-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .pt-editable-editing > .pt-editable-content {
    position: absolute;
    left: 0;
    visibility: hidden; }
  .pt-editable-placeholder > .pt-editable-content {
    color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-editable-placeholder > .pt-editable-content {
      color: rgba(191, 204, 214, 0.5); }

.pt-editable-text.pt-multiline {
  display: block; }
  .pt-editable-text.pt-multiline .pt-editable-content {
    overflow: auto;
    white-space: pre-wrap; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
.pt-select select {
  display: inline-block;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  background: #f5f8fa;
  background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
  box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    background: #ebf1f5;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box; }
  .pt-select select:active {
    border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
    background-color: #ced9e0;
    background-image: none; }
  .pt-select select:disabled {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-clip: border-box;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  background: rgba(255, 255, 255, 0.1);
  background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
  border-color: rgba(16, 22, 26, 0.6);
  box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
  background-clip: padding-box;
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    background: rgba(255, 255, 255, 0.1);
    background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
    background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
    border-color: rgba(16, 22, 26, 0.8);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: padding-box; }
  .pt-dark .pt-select select:active {
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background-color: rgba(16, 22, 26, 0.1);
    background-image: none; }
  .pt-dark .pt-select select:disabled {
    border-color: rgba(206, 217, 224, 0.1);
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.1);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "⌄";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Control groups

A `.pt-control-group` renders several distinct controls as one unit, squaring the borders between
them. It supports any number of `.pt-button`, `.pt-input`, `.pt-input-group`, and `.pt-select`
elements as direct children.

Note that `.pt-control-group` does not cascade any modifiers to its children. For example, each
child must be marked individually as `.pt-large` for uniform large appearance.

<div class="pt-callout pt-intent-success pt-icon-comparison">
  <h5>Control group vs. input group</h5>
  <p>Both components group multiple elements into a single unit, but their usage patterns are
  different.</p>
  <p>Think of `.pt-control-group` as a parent with multiple children, each of them a
  "control."</p>
  <p>Conversely, a `.pt-input-group` is a single control, and should function like so. A
  button inside of an input group should only affect that input; if its reach is further, then it
  should be promoted to live in a control group.</p>
</div>

Markup:
<div class="pt-control-group-example">
  <div class="pt-control-group">
    <button class="pt-button pt-icon-filter">Filter</button>
    <input type="text" class="pt-input" placeholder="Find filters..." />
  </div>
  <div class="pt-control-group">
    <div class="pt-select">
      <select>
        <option selected>Filter...</option>
        <option value="1">Issues</option>
        <option value="2">Requests</option>
        <option value="3">Features</option>
        <option value="4">Won't fixes</option>
      </select>
    </div>
    <div class="pt-input-group">
      <span class="pt-icon pt-icon-search"></span>
      <input type="text" class="pt-input" value="from:ggray to:allorca" />
    </div>
  </div>
  <div class="pt-control-group">
    <div class="pt-input-group">
      <span class="pt-icon pt-icon-people"></span>
      <input type="text" class="pt-input" placeholder="Find collaborators..." style="padding-right:94px" />
      <div class="pt-input-action">
        <button class="pt-button pt-minimal pt-intent-primary">
          can view<span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
        </button>
      </div>
    </div>
    <button class="pt-button pt-intent-primary">Add</button>
  </div>
</div>

Weight: 4

Styleguide components.forms.control-group
*/
.pt-control-group {
  display: flex;
  align-items: flex-start;
  /*
  Vertical control groups

  Add the class `pt-vertical` to create a vertical control group. Controls in a vertical group
  will all have the same width as the widest control.

  Markup:
  <div class="pt-control-group pt-vertical" style="width: 300px;">
    <div class="pt-input-group pt-large">
      <span class="pt-icon pt-icon-person"></span>
      <input type="text" class="pt-input" placeholder="Username" />
    </div>
    <div class="pt-input-group pt-large">
      <span class="pt-icon pt-icon-lock"></span>
      <input type="password" class="pt-input" placeholder="Password" />
    </div>
    <button class="pt-button pt-large pt-intent-primary">Login</button>
  </div>

  Styleguide components.forms.control-group.vertical
  */ }
  .pt-control-group > * {
    flex: 0 0 auto; }
  .pt-control-group .pt-button,
  .pt-control-group .pt-input,
  .pt-control-group .pt-select select {
    border-radius: inherit; }
    .pt-control-group .pt-button:focus,
    .pt-control-group .pt-input:focus,
    .pt-control-group .pt-select select:focus {
      z-index: 2; }
  .pt-control-group:not(.pt-vertical) > * {
    margin-right: -1px; }
  .pt-control-group:not(.pt-vertical) .pt-button:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]),
  .pt-control-group:not(.pt-vertical) .pt-input:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) {
    border-right-color: transparent; }
  .pt-control-group:not(.pt-vertical) .pt-select:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) select {
    border-right-color: transparent; }
  .pt-control-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .pt-control-group > :last-child {
    margin-right: 0;
    border-radius: 0 3px 3px 0; }
  .pt-control-group .pt-button,
  .pt-control-group .pt-select {
    z-index: 1; }
  .pt-control-group .pt-input:focus {
    position: relative;
    border-radius: 3px; }
  .pt-control-group .pt-button:focus,
  .pt-control-group select:focus {
    position: relative; }
  .pt-control-group .pt-input-group > .pt-icon,
  .pt-control-group .pt-input-group > .pt-button,
  .pt-control-group .pt-input-group > .pt-input-action,
  .pt-control-group .pt-select::after {
    z-index: 3; }
  .pt-control-group .pt-input-group .pt-button {
    border-radius: 3px; }
  .pt-control-group.pt-vertical {
    flex-direction: column; }
    .pt-control-group.pt-vertical > * {
      margin-top: -1px;
      width: 100%; }
    .pt-control-group.pt-vertical .pt-button:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]),
    .pt-control-group.pt-vertical .pt-input:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) {
      border-bottom-color: transparent; }
    .pt-control-group.pt-vertical .pt-select:not(:last-child):not(:hover):not(:active):not(.pt-active):not([class*="pt-intent-"]) select {
      border-bottom-color: transparent; }
    .pt-control-group.pt-vertical > :first-child {
      margin-top: 0;
      border-radius: 3px 3px 0 0; }
    .pt-control-group.pt-vertical > :last-child {
      border-radius: 0 0 3px 3px; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
.pt-control {
  display: block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 20px;
  padding-left: 26px;
  text-transform: none;
  line-height: 16px;
  /*
  Checkboxes

  Blueprint's custom checkboxes use an extra `.pt-control-indicator` element after the `<input>` to
  achieve their custom styling. You should then wrap the whole thing in a `<label>` with the classes
  `.pt-control.pt-checkbox`.

  Note that attribute modifiers (`:checked`, `:disabled`) are applied on the internal `<input>`
  element. Further note that `:indeterminate` can only be set via JavaScript (the `Checkbox` React
  component supports it handily with a prop).

  Weight: 2

  Styleguide components.forms.checkbox
  */
  /*
  CSS API

  Markup:
  <label class="pt-control pt-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide components.forms.checkbox.css
  */
  /*
  JavaScript API

  The `Checkbox` component is available in the __@blueprintjs/core__ package.
  Make sure to review the [general usage docs for JS components](#components.usage).

  ```
  // simple usage for string labels
  <Checkbox checked={this.state.isEnabled} label="Enabled" onChange={this.handleEnabledChange} />

  // advanced usage for JSX content
  <Checkbox checked={this.state.isEnabled} onChange={this.handleEnabledChange}>
      <span className="pt-icon-standard pt-icon-user" />
      Gilad Gray
  </Checkbox>
  ```

  Note that this component supports the full range of props available on HTML `input` elements.
  Use `checked` instead of `value` in controlled mode to avoid typings issues.
  The most common options are detailed below.

  @interface ICheckboxProps

  Styleguide: components.forms.checkbox.js
  */
  /*
  Radios

  Blueprint's custom radio buttons use an extra `.pt-control-indicator` element after the `<input>`
  to achieve their custom styling. You should then wrap the whole thing in a `<label>` with the
  classes `.pt-control.pt-radio`.

  Note that attribute modifiers (`:checked`, `:disabled`) are applied on the internal `<input>`
  element.

  Weight: 2

  Styleguide components.forms.radio
  */
  /*
  CSS API

  Markup:
  <label class="pt-control pt-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide components.forms.radio.css
  */
  /*
  JavaScript API

  The `Radio` and `RadioGroup` components are available in the __@blueprintjs/core__ package. Make
  sure to review the [general usage docs for JS components](#components.usage).

  Typically, radio buttons are used in a group to choose one option from several, similar to how a
  `<select>` tag contains several `<option>` tags. As such, you can use the `RadioGroup` component
  with a series of `Radio` children. `RadioGroup` is responsible for managing state and interaction.

  ```
  <RadioGroup
      label="Meal Choice"
      onChange={this.handleMealChange}
      selectedValue={this.state.mealType}
  >
      <Radio label="Soup" value="one" />
      <Radio label="Salad" value="two" />
      <Radio label="Sandwich" value="three" />
  </RadioGroup>
  ```

  Note that this component supports the full range of props available on HTML `input` elements.
  The most common options are detailed below.

  @interface IRadioProps

  Styleguide components.forms.radio.js
  */
  /*
  Switches

  A switch is simply an alternate appearance for a [checkbox](#components.forms.checkbox) that
  simulates on/off instead of checked/unchecked.

  Weight: 2

  Styleguide components.forms.switch
  */
  /*
  CSS API

  Markup:
  <label class="pt-control pt-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide components.forms.switch.css
  */
  /*
  JavaScript API

  The `Switch` component is available in the __@blueprintjs/core__ package.
  Make sure to review the [general usage docs for JS components](#components.usage).

  ```
  <Switch checked={this.state.isPublic} label="Public" onChange={this.handlePublicChange} />
  ```

  Note that this component supports the full range of props available on HTML `input` elements.
  The most common options are detailed below.

  @interface ISwitchProps

  Styleguide components.forms.switch.js
  */
  /*
  Inline controls

  Checkboxes, radios, and switches all support the `.pt-inline` modifier to make them `display:
  inline-block`. Note that this modifier functions slightly differently on these elements than it
  does on `.pt-label`. On `.pt-label`, it only adjusts the layout of text _within_ the label and not
  the display of the label itself.

  Here's an example of how you might group together some controls and label them.

  Markup:
  <label class="pt-label">A group of related options</label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    First
  </label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    Second
  </label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    Third
  </label>

  Weight: 10

  Styleguide components.forms.checkbox.inline
  */ }
  .pt-control.pt-disabled {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-control.pt-inline {
    display: inline-block;
    margin-right: 20px; }
  .pt-control input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1; }
  .pt-control .pt-control-indicator {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: #f5f8fa;
    background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
    background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    border: 1px solid;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    cursor: pointer;
    width: 16px;
    height: 16px;
    line-height: 16px;
    user-select: none; }
    .pt-control .pt-control-indicator::before {
      position: relative;
      top: -1px;
      left: -1px;
      content: ""; }
  .pt-control input:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
    background: #137cbd;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #137cbd;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #137cbd;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: border-box;
    color: #ffffff; }
  .pt-control:hover .pt-control-indicator {
    background: #ebf1f5;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
    background-clip: padding-box; }
  .pt-control:hover input:checked ~ .pt-control-indicator, .pt-control:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control:hover input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox:hover input:indeterminate ~ .pt-control-indicator {
    background: #106ba3;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #106ba3;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #106ba3;
    border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-control input:not(:disabled):active ~ .pt-control-indicator {
    border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
    background: #ced9e0;
    background-clip: padding-box; }
  .pt-control input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
    border-color: rgba(16, 22, 26, 0.6) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #0e5a8a; }
  .pt-control input:focus ~ .pt-control-indicator {
    outline: rgba(19, 124, 189, 0.5) auto 2px;
    outline-offset: 2px;
    -moz-outline-radius: 6px; }
  .pt-control input:disabled ~ .pt-control-indicator {
    border-color: transparent;
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed; }
  .pt-control input:disabled:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator {
    border-color: transparent;
    box-shadow: none;
    background: rgba(19, 124, 189, 0.5); }
  .pt-control.pt-checkbox .pt-control-indicator {
    border-radius: 3px;
    font-size: 16px; }
  .pt-control.pt-checkbox input:checked ~ .pt-control-indicator::before, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before {
    content: ""; }
  .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before {
    content: ""; }
  .pt-control.pt-radio .pt-control-indicator {
    border-radius: 50%;
    font-size: 6px; }
  .pt-control.pt-radio input:checked ~ .pt-control-indicator::before, .pt-control.pt-radio .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-radio input:indeterminate ~ .pt-control-indicator::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;
    width: 1em;
    height: 1em;
    content: ""; }
  .pt-control.pt-radio input:checked:disabled ~ .pt-control-indicator::before, .pt-control.pt-radio .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-radio input:disabled:indeterminate ~ .pt-control-indicator::before {
    box-shadow: none; }
  .pt-control.pt-radio input:focus ~ .pt-control-indicator {
    -moz-outline-radius: 16px; }
  .pt-control.pt-switch {
    padding-left: 38px; }
    .pt-control.pt-switch .pt-control-indicator {
      border: none;
      border-radius: 28px;
      box-shadow: none;
      background: rgba(167, 182, 194, 0.5);
      width: 28px;
      height: 16px;
      transition: background 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
      .pt-control.pt-switch .pt-control-indicator::before {
        display: block;
        top: 2px;
        left: 2px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.1);
        background: #ffffff;
        background-clip: padding-box;
        width: 12px;
        height: 12px;
        content: "";
        transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .pt-control.pt-switch input:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator {
      box-shadow: none;
      background-color: #137cbd;
      width: 28px;
      height: 16px; }
      .pt-control.pt-switch input:checked ~ .pt-control-indicator::before, .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator::before {
        left: 14px;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-control.pt-switch:hover .pt-control-indicator {
      background-color: rgba(115, 134, 148, 0.5); }
    .pt-control.pt-switch:hover input:checked ~ .pt-control-indicator, .pt-control.pt-switch:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch:hover input:indeterminate ~ .pt-control-indicator {
      background-color: #106ba3; }
    .pt-control.pt-switch input:not(:disabled):active ~ .pt-control-indicator {
      box-shadow: none;
      background-color: rgba(92, 112, 128, 0.5); }
    .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      background-color: #0e5a8a; }
    .pt-control.pt-switch input:disabled ~ .pt-control-indicator {
      background-color: rgba(206, 217, 224, 0.5); }
      .pt-control.pt-switch input:disabled ~ .pt-control-indicator::before {
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.8); }
    .pt-control.pt-switch input:disabled:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:disabled:indeterminate ~ .pt-control-indicator {
      background-color: rgba(19, 124, 189, 0.5); }
  .pt-control.pt-align-right {
    padding-right: 26px;
    padding-left: 0; }
    .pt-control.pt-align-right .pt-control-indicator {
      right: 0;
      left: auto; }
  .pt-control.pt-large {
    padding-left: 30px;
    line-height: 20px;
    font-size: 16px; }
    .pt-control.pt-large .pt-control-indicator {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-family: "Icons20";
      font-size: 20px; }
    .pt-control.pt-large.pt-checkbox input:checked ~ .pt-control-indicator::before, .pt-control.pt-large.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-large.pt-checkbox
    .pt-control.pt-checkbox:hover input:indeterminate ~ .pt-control-indicator::before {
      top: 0; }
    .pt-control.pt-large.pt-radio .pt-control-indicator {
      font-size: 8px; }
    .pt-control.pt-large.pt-switch {
      padding-left: 42px; }
      .pt-control.pt-large.pt-switch .pt-control-indicator {
        width: 32px;
        height: 20px; }
        .pt-control.pt-large.pt-switch .pt-control-indicator::before {
          width: 16px;
          height: 16px; }
      .pt-control.pt-large.pt-switch input:checked ~ .pt-control-indicator, .pt-control.pt-large.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-large.pt-switch input:indeterminate ~ .pt-control-indicator {
        width: 32px;
        height: 20px; }
        .pt-control.pt-large.pt-switch input:checked ~ .pt-control-indicator::before, .pt-control.pt-large.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-large.pt-switch input:indeterminate ~ .pt-control-indicator::before {
          left: 14px; }
  .pt-dark .pt-control {
    color: #f5f8fa; }
    .pt-dark .pt-control .pt-control-indicator {
      background: rgba(255, 255, 255, 0.1);
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
      border-color: rgba(16, 22, 26, 0.6);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-dark .pt-control input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
      border-color: rgba(16, 22, 26, 0.6);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
      background-clip: padding-box; }
    .pt-dark .pt-control:hover .pt-control-indicator {
      background: rgba(255, 255, 255, 0.1);
      background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      border-color: rgba(16, 22, 26, 0.8); }
    .pt-dark .pt-control input:not(:disabled):active ~ .pt-control-indicator {
      border-color: rgba(16, 22, 26, 0.8);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background: rgba(16, 22, 26, 0.1); }
    .pt-dark .pt-control input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      border-color: rgba(16, 22, 26, 0.6);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-clip: padding-box;
      background-color: #0e5a8a; }
    .pt-dark .pt-control input:disabled ~ .pt-control-indicator {
      border-color: transparent;
      box-shadow: none;
      background: rgba(206, 217, 224, 0.1);
      cursor: not-allowed; }
    .pt-dark .pt-control input:disabled:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator {
      border-color: transparent;
      box-shadow: none;
      background: rgba(14, 90, 138, 0.5); }
    .pt-dark .pt-control.pt-checkbox input:checked:disabled ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-control.pt-radio input:checked:disabled ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-radio .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-dark .pt-control.pt-radio input:disabled:indeterminate ~ .pt-control-indicator::before {
      background: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-control.pt-switch .pt-control-indicator {
      box-shadow: none;
      background: rgba(16, 22, 26, 0.5); }
      .pt-dark .pt-control.pt-switch .pt-control-indicator::before {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
        background: #30404d; }
    .pt-dark .pt-control.pt-switch input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator {
      box-shadow: none;
      background-color: #137cbd; }
      .pt-dark .pt-control.pt-switch input:checked ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator::before {
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-dark .pt-control.pt-switch:hover .pt-control-indicator {
      background: rgba(16, 22, 26, 0.7); }
    .pt-dark .pt-control.pt-switch:hover input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch:hover input:indeterminate ~ .pt-control-indicator {
      background: #2b95d6; }
    .pt-dark .pt-control.pt-switch input:not(:disabled):active ~ .pt-control-indicator {
      box-shadow: none;
      background: rgba(16, 22, 26, 0.9); }
    .pt-dark .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      background: #48aff0; }
    .pt-dark .pt-control.pt-switch input:disabled ~ .pt-control-indicator {
      background: rgba(206, 217, 224, 0.1); }
      .pt-dark .pt-control.pt-switch input:disabled ~ .pt-control-indicator::before {
        box-shadow: none;
        background: rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control.pt-switch input:disabled:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:disabled:indeterminate ~ .pt-control-indicator {
      background: rgba(14, 90, 138, 0.5); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
File upload

Use the standard `input type="file"` along with a `span` with class `pt-file-upload-input`.
Wrap that all in a `label` with class `pt-file-upload`.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Static file name</h5>
  File name does not update on file selection. To get this behavior,
  you must implement it separately in JS.
</div>

Markup:
<label class="pt-file-upload">
  <input type="file" />
  <span class="pt-file-upload-input">Choose file...</span>
</label>

Weight: 10

Styleguide components.forms.fileupload
*/
.pt-file-upload {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 30px; }
  .pt-file-upload input {
    opacity: 0;
    margin: 0;
    min-width: 200px; }
  .pt-file-upload .pt-file-upload-input {
    outline: none;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;
    height: 30px;
    padding: 0 10px;
    vertical-align: middle;
    line-height: 30px;
    color: #182026;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif, "Icons16";
    font-size: 14px;
    font-weight: 400;
    transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-right: 70px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 30px;
    line-height: 30px;
    color: #5c7080;
    user-select: none; }
    .pt-file-upload .pt-file-upload-input::-webkit-input-placeholder {
      opacity: 1;
      color: rgba(92, 112, 128, 0.5); }
    .pt-file-upload .pt-file-upload-input::-moz-placeholder {
      opacity: 1;
      color: rgba(92, 112, 128, 0.5); }
    .pt-file-upload .pt-file-upload-input:-moz-placeholder {
      opacity: 1;
      color: rgba(92, 112, 128, 0.5); }
    .pt-file-upload .pt-file-upload-input:-ms-input-placeholder {
      opacity: 1;
      color: rgba(92, 112, 128, 0.5); }
    .pt-file-upload .pt-file-upload-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-file-upload .pt-file-upload-input:disabled, .pt-file-upload .pt-file-upload-input.pt-disabled {
      box-shadow: none;
      background: rgba(206, 217, 224, 0.5);
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5);
      resize: none; }
    .pt-file-upload .pt-file-upload-input[type="search"], .pt-file-upload .pt-file-upload-input.pt-round {
      border-radius: 30px;
      box-sizing: border-box;
      padding-left: 10px; }
    .pt-file-upload .pt-file-upload-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
    .pt-file-upload .pt-file-upload-input::after {
      background: #f5f8fa;
      background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
      background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
      border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
      background-clip: padding-box;
      color: #182026;
      position: absolute;
      top: 0;
      right: 0;
      left: 100%;
      margin-left: -1px;
      border-width: 1px;
      border-style: solid;
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 70px;
      height: 30px;
      padding: 0 10px;
      text-align: center;
      line-height: 28px;
      content: "Browse"; }
      .pt-file-upload .pt-file-upload-input::after:hover {
        background: #ebf1f5;
        background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
        border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
        box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
        background-clip: padding-box; }
      .pt-file-upload .pt-file-upload-input::after:active {
        border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
        box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
        background-color: #ced9e0;
        background-image: none; }
      .pt-file-upload .pt-file-upload-input::after:disabled {
        outline: none;
        border-color: transparent;
        box-shadow: none;
        background-clip: border-box;
        background-color: rgba(206, 217, 224, 0.5);
        background-image: none;
        cursor: not-allowed;
        color: rgba(92, 112, 128, 0.5); }
    .pt-file-upload .pt-file-upload-input:not(:hover):not(:active)::after {
      border-left-color: transparent; }
    .pt-file-upload .pt-file-upload-input:hover::after {
      background: #ebf1f5;
      background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
      border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
      background-clip: padding-box; }
    .pt-file-upload .pt-file-upload-input:active::after {
      border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
      background-color: #ced9e0;
      background-image: none; }
    .pt-dark .pt-file-upload .pt-file-upload-input {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
      background: rgba(16, 22, 26, 0.3);
      color: #f5f8fa;
      color: #bfccd6; }
      .pt-dark .pt-file-upload .pt-file-upload-input::-webkit-input-placeholder {
        color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-file-upload .pt-file-upload-input::-moz-placeholder {
        color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-file-upload .pt-file-upload-input:-moz-placeholder {
        color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-file-upload .pt-file-upload-input:-ms-input-placeholder {
        color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-file-upload .pt-file-upload-input:focus {
        box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-file-upload .pt-file-upload-input:disabled, .pt-dark .pt-file-upload .pt-file-upload-input.pt-disabled {
        box-shadow: none;
        background: rgba(216, 225, 232, 0.1);
        color: rgba(255, 255, 255, 0.3); }
      .pt-dark .pt-file-upload .pt-file-upload-input[readonly] {
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-file-upload .pt-file-upload-input::after {
        background: rgba(255, 255, 255, 0.1);
        background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
        border-color: rgba(16, 22, 26, 0.6);
        box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
        background-clip: padding-box;
        color: #f5f8fa; }
        .pt-dark .pt-file-upload .pt-file-upload-input::after:hover, .pt-dark .pt-file-upload .pt-file-upload-input::after:active {
          color: #f5f8fa; }
        .pt-dark .pt-file-upload .pt-file-upload-input::after:hover {
          background: rgba(255, 255, 255, 0.1);
          background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
          background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
          border-color: rgba(16, 22, 26, 0.8);
          box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
          background-clip: padding-box; }
        .pt-dark .pt-file-upload .pt-file-upload-input::after:active {
          box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
          background-color: rgba(16, 22, 26, 0.1);
          background-image: none; }
        .pt-dark .pt-file-upload .pt-file-upload-input::after:disabled {
          border-color: rgba(206, 217, 224, 0.1);
          box-shadow: none;
          background-color: rgba(206, 217, 224, 0.1);
          background-image: none;
          color: rgba(191, 204, 214, 0.5); }
        .pt-dark .pt-file-upload .pt-file-upload-input::after .pt-button-spinner .pt-spinner-head {
          background: rgba(16, 22, 26, 0.5);
          stroke: #8a9ba8; }
      .pt-dark .pt-file-upload .pt-file-upload-input:hover::after {
        background: rgba(255, 255, 255, 0.1);
        background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
        background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
        border-color: rgba(16, 22, 26, 0.8);
        box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
        background-clip: padding-box; }
      .pt-dark .pt-file-upload .pt-file-upload-input:active::after {
        box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
        background-color: rgba(16, 22, 26, 0.1);
        background-image: none; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Text input groups

An input group allows you to add icons and buttons _within_ a text input to expand its
functionality. For example, you might use an input group to build a visibility toggle for a password
field.

Weight: 3

Styleguide components.forms.input-group
*/
/*
CSS API

You can place a single `.pt-icon` or `.pt-button.pt-icon-*` on either end of the input. The order is
dictated by the HTML markup: an element specified before the `input` appears on the left edge, and
vice versa. You do not need to apply sizing classes to the children&mdash;they inherit the size of
the parent input.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Icons only</h5>
  <p>You cannot use buttons with text in the CSS API for input groups. The padding for text inputs
  in CSS cannot accomodate buttons whose width varies due to text content. You should use icons on
  buttons instead.</p>

  Conversely, the [`InputGroup`](#components.forms.input-group.js) React component _does_ support
  arbitrary content in its right element.
</div>

Markup:
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-filter"></span>
  <input type="text" class="pt-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="pt-input-group {{.modifier}}">
  <input type="password" class="pt-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="pt-button pt-minimal pt-intent-warning pt-icon-lock" {{:modifier}}></button>
</div>
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-search"></span>
  <input type="text" class="pt-input" {{:modifier}} placeholder="Search" />
  <button class="pt-button pt-minimal pt-intent-primary pt-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.pt-disabled</code> to <code>.pt-input-group</code> for icon coloring (not shown below).
.pt-round - Rounded caps. Button will also be rounded.
.pt-large - Large group. Children will adjust size accordingly.
.pt-intent-primary - Primary intent. (All 4 intents are supported.)

Styleguide components.forms.input-group.css
*/
.pt-input-group {
  display: block;
  position: relative; }
  .pt-input-group .pt-input {
    width: 100%; }
    .pt-input-group .pt-input:not(:first-child) {
      padding-left: 30px; }
    .pt-input-group .pt-input:not(:last-child) {
      padding-right: 30px; }
  .pt-input-group .pt-input-action,
  .pt-input-group > .pt-button,
  .pt-input-group > .pt-icon {
    position: absolute;
    top: 0; }
    .pt-input-group .pt-input-action:first-child,
    .pt-input-group > .pt-button:first-child,
    .pt-input-group > .pt-icon:first-child {
      left: 0; }
    .pt-input-group .pt-input-action:last-child,
    .pt-input-group > .pt-button:last-child,
    .pt-input-group > .pt-icon:last-child {
      right: 0; }
  .pt-input-group .pt-button {
    min-width: 24px;
    min-height: 24px;
    line-height: 22px;
    margin: 3px;
    padding-top: 0;
    padding-bottom: 0; }
  .pt-input-group .pt-icon {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0 7px;
    line-height: 30px; }
  .pt-input-group .pt-tag {
    margin: 5px; }
  .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus),
  .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) {
    color: #5c7080; }
    .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-standard, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-large,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-standard,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-large {
      color: #5c7080; }
  .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled, .pt-input-group .pt-input:not(:focus) + .pt-minimal.pt-button.pt-disabled,
  .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled,
  .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-minimal.pt-button.pt-disabled {
    color: rgba(92, 112, 128, 0.5) !important; }
    .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon, .pt-input-group .pt-input:not(:focus) + .pt-minimal.pt-button.pt-disabled .pt-icon, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon-standard, .pt-input-group .pt-input:not(:focus) + .pt-minimal.pt-button.pt-disabled .pt-icon-standard, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon-large, .pt-input-group .pt-input:not(:focus) + .pt-minimal.pt-button.pt-disabled .pt-icon-large,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-minimal.pt-button.pt-disabled .pt-icon,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon-standard,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-minimal.pt-button.pt-disabled .pt-icon-standard,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon-large,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-minimal.pt-button.pt-disabled .pt-icon-large {
      color: rgba(92, 112, 128, 0.5) !important; }
  .pt-input-group.pt-disabled {
    cursor: not-allowed; }
    .pt-input-group.pt-disabled .pt-icon {
      color: rgba(92, 112, 128, 0.5); }
  .pt-input-group.pt-large .pt-button {
    min-width: 30px;
    min-height: 30px;
    line-height: 28px;
    margin: 5px;
    line-height: 0; }
  .pt-input-group.pt-large .pt-icon {
    margin: 0 12px;
    line-height: 40px; }
  .pt-input-group.pt-large .pt-input {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .pt-input-group.pt-large .pt-input[type="search"], .pt-input-group.pt-large .pt-input.pt-round {
      padding: 0 15px; }
    .pt-input-group.pt-large .pt-input:not(:first-child) {
      padding-left: 40px; }
    .pt-input-group.pt-large .pt-input:not(:last-child) {
      padding-right: 40px; }
  .pt-input-group.pt-round .pt-button,
  .pt-input-group.pt-round .pt-input,
  .pt-input-group.pt-round .pt-tag {
    border-radius: 30px; }
  .pt-dark .pt-input-group.pt-disabled .pt-icon {
    color: rgba(191, 204, 214, 0.5); }
  .pt-input-group.pt-intent-primary .pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-primary .pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-primary .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
  .pt-input-group.pt-intent-primary .pt-icon {
    color: #137cbd; }
  .pt-input-group.pt-intent-success .pt-input {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-success .pt-input:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-success .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
  .pt-input-group.pt-intent-success .pt-icon {
    color: #0f9960; }
  .pt-input-group.pt-intent-warning .pt-input {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-warning .pt-input:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-warning .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
  .pt-input-group.pt-intent-warning .pt-icon {
    color: #d9822b; }
  .pt-input-group.pt-intent-danger .pt-input {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-danger .pt-input:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-danger .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
  .pt-input-group.pt-intent-danger .pt-icon {
    color: #db3737; }

/*
JavaScript API

The `InputGroup` component is available in the __@blueprintjs/core__ package. Make sure to review
the [general usage docs for JS components](#components.usage).

The `InputGroup` React Component encapsulates the `.pt-input-group`
[CSS API](#components.forms.input-group.css): it supports one non-interactive icon on the left side
and one arbitrary element on the right side. Unlike the CSS API, the React Component supports
_content of any length_ on the right side, not just icon buttons, because it is able to measure the
content and ensure there is always space for it.

`InputGroup` can be used just like a standard React `input` element, in controlled or uncontrolled
fashion. In addition to its own content props, it supports all valid props for HTML `input` elements
and proxies them to that element in the DOM; the most common ones are detailed below.

@interface IInputGroupProps

@react-example InputGroupExample

Styleguide components.forms.input-group.js
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Text inputs

Use the `pt-input` class on an `input[type="text"]`. You should also specify `dir="auto"` [to better
support RTL languages](http://www.w3.org/International/questions/qa-html-dir#dirauto) (in all
browsers except Internet Explorer).

Markup:
<input class="pt-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.pt-round - Rounded ends
.pt-large - Larger size
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger - Danger intent
.pt-fill - Take up full width of parent element

Weight: 3

Styleguide components.forms.input
*/
.pt-input {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif, "Icons16";
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-input::-webkit-input-placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-input::-moz-placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-input:-moz-placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-input:-ms-input-placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-input:focus {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-input:disabled, .pt-input.pt-disabled {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .pt-input[type="search"], .pt-input.pt-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .pt-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-input.pt-large {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .pt-input.pt-large[type="search"], .pt-input.pt-large.pt-round {
      padding: 0 15px; }
  .pt-input.pt-fill {
    width: 100%; }
  .pt-dark .pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark .pt-input::-webkit-input-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-input::-moz-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-input:-moz-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-input:-ms-input-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-input:disabled, .pt-dark .pt-input.pt-disabled {
      box-shadow: none;
      background: rgba(216, 225, 232, 0.1);
      color: rgba(255, 255, 255, 0.3); }
    .pt-dark .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .pt-input.pt-intent-primary {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-primary:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-primary[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .pt-dark .pt-input.pt-intent-primary {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-primary:focus {
        box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-primary[readonly] {
        box-shadow: inset 0 0 0 1px #137cbd; }
  .pt-input.pt-intent-success {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-success:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-success[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .pt-dark .pt-input.pt-intent-success {
      box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-success:focus {
        box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-success[readonly] {
        box-shadow: inset 0 0 0 1px #0f9960; }
  .pt-input.pt-intent-warning {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-warning:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-warning[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .pt-dark .pt-input.pt-intent-warning {
      box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-warning:focus {
        box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-warning[readonly] {
        box-shadow: inset 0 0 0 1px #d9822b; }
  .pt-input.pt-intent-danger {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-danger:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-danger[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .pt-dark .pt-input.pt-intent-danger {
      box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-danger:focus {
        box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-danger[readonly] {
        box-shadow: inset 0 0 0 1px #db3737; }
  .pt-input::-ms-clear {
    display: none; }

/*
Search field

Changing the `<input>` element's `type` attribute to `"search"` styles it to look like a search
field, giving it a rounded appearance. This style is equivalent to the `.pt-round` modifier, but it
is applied automatically for `[type="search"]` inputs.

Note that some browsers also implement a handler for the <kbd class="pt-key">esc</kbd> key to clear
the text in a search field.

Markup:
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-search"></span>
  <input class="pt-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-input-group</code> for icon coloring (not shown below).
.pt-large - Large

Styleguide components.forms.input.search
*/
/*
Text areas

Text areas are similar to text inputs, but they are resizable.

You should also specify `dir="auto"` on text areas
[to better support RTL languages](http://www.w3.org/International/questions/qa-html-dir#dirauto)
(in all browsers except Internet Explorer).

Markup:
<textarea class="pt-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.pt-large - Larger font size
.pt-intent-primary - Primary intent
.pt-intent-danger  - Danger intent
.pt-fill  - Take up full width of parent element

Weight: 3

Styleguide components.forms.textarea
*/
textarea.pt-input {
  max-width: 100%;
  height: auto;
  padding: 10px;
  line-height: 1.28581; }
  textarea.pt-input.pt-large {
    line-height: 1.28581;
    font-size: 16px; }
  .pt-dark textarea.pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark textarea.pt-input::-webkit-input-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark textarea.pt-input::-moz-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark textarea.pt-input:-moz-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark textarea.pt-input:-ms-input-placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark textarea.pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .pt-dark textarea.pt-input:disabled, .pt-dark textarea.pt-input.pt-disabled {
      box-shadow: none;
      background: rgba(216, 225, 232, 0.1);
      color: rgba(255, 255, 255, 0.3); }
    .pt-dark textarea.pt-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Labels

Add labels to inputs to enhance the usability of your forms.

Putting the `<input>` element _inside_ a `<label>` element also increases the area where the user
can click to activate the control. Notice how in the example below, clicking a `<label>` below
focuses its `<input>`.

Markup:
<label class="pt-label {{.modifier}}">
  Label A
  <input class="pt-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
</label>
<label class="pt-label {{.modifier}}">
  Label B
  <div class="pt-input-group">
    <span class="pt-icon pt-icon-calendar"></span>
    <input class="pt-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
  </div>
</label>

.pt-inline - Inline

Weight: 1

Styleguide components.forms.label
*/
/*
Disabled labels

Add the `.pt-label` and `.pt-disabled` class modifiers to a `<label>` to make the label appear
disabled.

This styles the label text, but does not disable any nested children like inputs or selects. You
must add the `:disabled` attribute directly to any nested elements to disable them. Similarly the respective
`pt-*` form control will need a `.pt-disabled` modifier. See the examples below.

Markup:
<label class="pt-label pt-disabled">
  Label A
  <input disabled class="pt-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
</label>
<label class="pt-label pt-disabled">
  Label B
  <div class="pt-input-group pt-disabled">
    <span class="pt-icon pt-icon-calendar"></span>
    <input disabled class="pt-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
  </div>
</label>
<label class="pt-label pt-disabled">
  Label C
  <div class="pt-select pt-disabled">
    <select disabled>
      <option selected>Choose an item...</option>
      <option value="1">One</option>
    </select>
  </div>
</label>

Weight: 1

Styleguide components.forms.label.disabled
*/
label.pt-label {
  display: block;
  margin: 0 0 10px; }
  label.pt-label .pt-input,
  label.pt-label .pt-select {
    display: block;
    margin-top: 5px;
    text-transform: none; }
  label.pt-label .pt-select select {
    width: 100%;
    vertical-align: top;
    font-weight: 400; }
  label.pt-label.pt-inline {
    line-height: 30px; }
    label.pt-label.pt-inline .pt-input,
    label.pt-label.pt-inline .pt-input-group,
    label.pt-label.pt-inline .pt-select {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: top; }
    label.pt-label.pt-inline .pt-input-group .pt-input {
      margin-left: 0; }
    label.pt-label.pt-inline.pt-large {
      line-height: 40px; }
  label.pt-label.pt-disabled {
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark label.pt-label {
    color: #f5f8fa; }
    .pt-dark label.pt-label.pt-disabled {
      color: rgba(191, 204, 214, 0.5); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Selects

Styling `<select>` tags requires a wrapper element to customize the dropdown caret. Put class
modifiers on the wrapper and attribute modifiers directly on the `<select>`.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  Check out [Dropdown Menus](#components.menu.js.dropdown) for a simple JavaScript alternative
  to the `<select>` tag.
</div>

Markup:
<div class="pt-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
</div>

:disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-select</code> for icon coloring (not shown below).
.pt-large - Large
.pt-fill - Expand to fill parent container

Weight: 5

Styleguide components.forms.select
*/
.pt-select {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: normal; }
  .pt-select select::-ms-expand {
    display: none; }
  .pt-select.pt-large::after {
    top: 0;
    right: 12px;
    line-height: 40px; }
  .pt-select.pt-fill,
  .pt-select.pt-fill select {
    width: 100%; }
  .pt-dark .pt-select option {
    background-color: #30404d;
    color: #f5f8fa; }
  .pt-dark .pt-select::after {
    color: #bfccd6; }

/*
Labeled static dropdown

You can label `<select>` tags, similar to how you label any other form control.

Markup:
<label class="pt-label {{.modifier}}">
  Label A
  <div class="pt-select">
    <select>
      <option selected>Choose an item...</option>
      <option value="1">One</option>
    </select>
  </div>
</label>

.pt-inline - Inline

Styleguide components.forms.select.labeled
*/
/*
Form controls

The following controls often appear inside HTML forms. Blueprint does not strictly require the use
of any `<form>` elements, so you can use these controls anywhere in the UI.

Many of the components in this section provide React components for simpler idiomatic usage. These
React components try to transparently mirror the underlying HTML element, so they support the full
range of appropriate HTML props. If you provide a `className` prop, the class names you provide will
be added to the default Blueprint class name. If you specify other attributes that the component
provides, you'll overide the default value.

Styleguide components.forms
*/
form {
  display: block; }

/*
 * Copyright 2016 Palantir Technologies, Inc. All rights reserved.
 */
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Hotkeys

Hotkeys enable you to create interactions based on user keyboard events.

To add hotkeys to your React component, use the `@HotkeyTarget` class decorator
and add a `renderHotkeys()` method. The decorator will call `renderHotkeys()`
and attach the appropriate key listeners.

### Hotkey scope

`Hotkey`s can have either local or global scope. Local hotkeys will only be
triggered when the target is focused, while global hotkeys can be triggered no
matter which element is focused.

Additionally, any keyboard input that occurs inside a text input (such as a
`<textarea>`, `<input>`, or `<div contenteditable>`) is ignored.

### Hotkey dialog

If you define hotkeys for your page, you'll want to display the hotkeys in a
nice format for the user. If you register any global or local hotkeys, we
automatically attach a hotkey for <kbd class="pt-key">?</kbd>, which will
display the hotkeys dialog.

The dialog will always include all available global hotkeys, and if you are
focused on an element that has any hotkeys, those will be shown as well.

If you would like to change the style of the dialog (for example, to apply the
dark theme class), call the `setHotkeysDialogProps` function with
`IDialogProps`.

Styleguide components.hotkeys
*/
/*
Piano example

Also known as the keyboard keyboard. First, click the keys or press
<span class="pt-key-combo">
  <kbd class="pt-key pt-modifier-key">
    <span class="pt-icon-standard pt-icon-key-shift"></span>
    shift
  </kbd><kbd class="pt-key">P</kbd>
</span>
to focus the piano, then press the keys on your keyboard to play some music!

@react-example HotkeyPiano

Weight: 0

Styleguide components.hotkeys.piano
*/
/*
JavaScript API

1. Add the `@HotkeysTarget` class decorator to your react component.
1. Implement the `renderHotkeys()` method.
1. Define your `<Hotkey>`s inside a `<Hotkeys>` element.

```
import { Hotkey, Hotkeys, HotkeysTarget } from "@blueprintjs/core";
import * as React from "react";

@HotkeysTarget
export class MyComponent extends React.Component<{}, {}> {
    public render() {
        return <div>Whatever content</div>;
    }

    public renderHotkeys() {
        return <Hotkeys>
            <Hotkey
                global={true}
                combo="shift + a"
                label="Be awesome all the time"
                onKeyDown={() => console.log("Awesome!")}
            />
            <Hotkey
                group="Fancy Shortcuts"
                combo="shift + f"
                label="Be fancy only when focused"
                onKeyDown={() => console.log("So Fancy!")}
            />
        </Hotkeys>;
    }
}
```

Weight: 1

Styleguide components.hotkeys.api
*/
/*
Decorator

The `@HotkeysTarget` decorator allows you to easily add global and local
hotkeys to any React component. Add the decorator to the top of the class and
make sure to implement the `renderHotkeys` method.

@interface IHotkeysTarget

Weight: 2

Styleguide components.hotkeys.api.hotkeys-target
*/
/*
Hotkeys

Wrap your `Hotkey`s in the `Hotkeys` element. For example:

```
<Hotkeys>
  <Hotkey label="Quit" combo="ctrl+q" global onKeyDown={handleQuit} />
  <Hotkey label="Save" combo="ctrl+s" group="File" onKeyDown={handleSave} />
</Hotkey>
```

@interface IHotkeysProps

Weight: 3

Styleguide components.hotkeys.api.hotkeys
*/
/*
Hotkey

@interface IHotkeyProps

Weight: 4

Styleguide components.hotkeys.api.hotkey
*/
/*
Key combos

Each hotkey must be assigned a key combo that will trigger its events. A key
combo consists of zero or more modifier keys (`alt`, `ctrl`, `shift`, `meta`,
`cmd`) and exactly one action key, such as `A`, `return`, or `up`.

Some key combos have aliases. For example, `shift + 1` can equivalently be
expressed as `!` and `cmd` is equal to `meta`. However, normal alphabetic
characters do not have this aliasing, so `X` is equivalent to `x` but is not
equivalent to `shift + x`.


##### Examples of valid key combos

* `cmd+plus`
* `!` or, equivalently `shift+1`
* `return` or, equivalently `enter`
* `alt + shift + x`
* `ctrl + left`

Note that spaces are ignored.

##### Named keys

* `plus`
* `minus`
* `backspace`
* `tab`
* `enter`
* `capslock`
* `esc`
* `space`
* `pageup`
* `pagedown`
* `end`
* `home`
* `left`
* `up`
* `right`
* `down`
* `ins`
* `del`

##### Aliased keys

* `option` &rarr; `alt`
* `cmd` &rarr; `meta`
* `command` &rarr; `meta`
* `return` &rarr; `enter`
* `escape` &rarr; `esc`
* `win` &rarr; `meta`

The special modifier `mod` will choose the OS-preferred modifier key — `cmd`
for macOS and iOS, or `ctrl` for Windows and Linux.

##### Hotkey tester

Below is a little widget to quickly help you try out hotkey combos and see how
they will look in the dialog. See the key combos section above for more about
specifying key combo props.

@react-example HotkeyTester

Weight: 5

Styleguide components.hotkeys.api.hotkey-tester
*/
.pt-key {
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  min-width: 25px;
  height: 25px;
  padding: 3px 5px;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  color: #5c7080;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif, "Icons16";
  font-size: 12px; }
  .pt-key.pt-modifier-key {
    padding: 3px 8px 3px 6px; }
    .pt-key.pt-modifier-key .pt-icon-standard {
      margin-right: 5px; }
  .pt-dark .pt-key {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4), inset 0 1px 0 rgba(255, 255, 255, 0.05);
    background: #394b59;
    color: #bfccd6; }

.pt-key-combo .pt-key:not(:last-child) {
  margin-right: 5px; }

.pt-hotkey-dialog {
  top: 40px;
  padding-bottom: 0; }
  .pt-hotkey-dialog .pt-dialog-body {
    margin: 0;
    padding: 0; }
  .pt-hotkey-dialog .pt-key-combo {
    flex: 1 0 auto;
    width: 160px;
    text-align: right; }

.pt-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }
  .pt-hotkey-column .pt-hotkey-group {
    margin-bottom: 30px; }
    .pt-hotkey-column .pt-hotkey-group:not(:first-child) {
      margin-top: 50px; }

.pt-hotkey {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0; }
  .pt-hotkey:not(:last-child) {
    margin-bottom: 10px; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Submenus

To add a submenu to a `Menu`, simply nest `MenuItem`s within another `MenuItem`.
The submenu opens to the right of its parent by default, but will adjust and flip to the left if
there is not enough room to the right.

```
<MenuItem text="Submenu">
  <MenuItem text="Child one" />
  <MenuItem text="Child two" />
  <MenuItem text="Child three" />
</MenuItem>
```

Alternatively, you can pass an array of `IMenuItemProps` to the `submenu` prop:

```
React.createElement(MenuItem, {
    submenu: [
        { text: "Child one" },
        { text: "Child two" },
        { text: "Child three" },
    ],
    text: "parent",
});
```

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>JavaScript only</h5>
  Submenus are only supported in the React components. They cannot be created with CSS alone because
  they rely on the [`Popover`](#components.popover) component for positioning and transitions.
</div>

Weight: 2

Styleguide components.menu.js.submenu
*/
.pt-submenu > .pt-popover-target {
  display: inherit; }
  .pt-submenu > .pt-popover-target > .pt-menu-item {
    padding-right: 26px; }
    .pt-submenu > .pt-popover-target > .pt-menu-item::after {
      line-height: 1;
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      right: 5px;
      content: "〉"; }
      .pt-large .pt-submenu > .pt-popover-target > .pt-menu-item::after {
        line-height: 20px; }

.pt-submenu .pt-popover {
  position: relative;
  top: -5px;
  left: 5px; }
  .pt-submenu .pt-popover.pt-align-left {
    right: 5px;
    left: auto; }

/*
Menus

Menus display lists of interactive items.

Styleguide components.menu
*/
/*
JavaScript API

The `Menu`, `MenuItem`, and `MenuDivider` components are available in the __@blueprintjs/core__
package. Make sure to review the [general usage docs for JS components](#components.usage).

The Menu API includes three stateless React components:

- [`Menu`](#components.menu.js.menu)
- [`MenuItem`](#components.menu.js.menu-item)
- [`MenuDivider`](#components.menu.js.menu-divider)

### Sample Usage

```
const { Menu, MenuItem, MenuDivider } = BlueprintComponents;

class MenuExample extends React.Component<{}, {}> {
    public render() {
        return (
            <Menu>
                <MenuItem
                    iconName="new-text-box"
                    onClick={this.handleClick}
                    text="New text box" />
                <MenuItem
                    iconName="new-object"
                    onClick={this.handleClick}
                    text="New object" />
                <MenuItem
                    iconName="new-link"
                    onClick={this.handleClick}
                    text="New link" />
                <MenuDivider />
                <MenuItem text="Settings..." iconName="cog" />
            </Menu>
        );
    }

    private handleClick(e: React.MouseEvent) {
        console.log("clicked", (e.target as HTMLElement).textContent);
    }
}
```

@react-example MenuExample

Styleguide components.menu.js
*/
/*
Menu

A `Menu`'s children (menu items and dividers) are rendered as the contents of a `.pt-menu` element.

You can add the `pt-large` class to the `Menu` to make a larger version of the menu.

@interface IMenuProps

Styleguide components.menu.js.menu
*/
/*
Menu item

A `MenuItem` is a single interactive item in a `Menu`.

This component renders an `a.pt-menu-item`. Make the `MenuItem` a link by providing the `href`,
`target`, and `onClick` props as necessary.

Create submenus by nesting `MenuItem`s inside each other as `children`, or by providing a `submenu`
prop with an array of `MenuItem`s.

@interface IMenuItemProps

Styleguide components.menu.js.menu-item
*/
/*
Menu divider

Use `MenuDivider` to separate menu sections. Optionally, add a title to the divider.

@interface IMenuDividerProps

Weight: 1

Styleguide components.menu.js.menu-divider
*/
/*
Dropdown menus

The `Menu` component by itself simply renders a menu list. To make a dropdown menu, use a `Menu`
element as the `content` property of a `Popover`:

```
<Popover content={<Menu>...</Menu>} position={Position.RIGHT_TOP}>
    <Button iconName="share" text="Open in..." />
</Popover>
```

When the user clicks a menu item that is not disabled and is not part of a submenu, the popover is
automatically dismissed (in other words, the menu closes). This is because the `MenuItem` component
adds the `pt-popover-dismiss` class to these items by default (see [Popover JavaScript
API](#components.popover.js) for more information). If you want to opt out of this behavior, you can
add the `shouldDismissPopover` prop to a `MenuItem`.

Notice that selecting the menu item labeled **Table** in the example below does not automatically
dismiss the `Popover`. Selecting other menu items does dismiss the popover.

@react-example DropdownMenuExample

Weight: 3

Styleguide components.menu.js.dropdown
*/
/*
CSS API

Menus can be constructed manually using the HTML markup and `pt-menu-*` classes below. However, you
should use the menu [React components](#components.menu.js) instead wherever possible, as they
abstract away the tedious parts of implementing a menu.

- Begin with a `ul.pt-menu`. Each `li` child denotes a single entry in the menu.

- Put a `.pt-menu-item` element inside an `li` to create a clickable entry. Use either `<button>` or
`<a>` tags for menu items to denote interactivity.

- Add icons to menu items the same way you would to buttons: simply add the appropriate
`pt-icon-<name>` class*.

- Make menu items non-interactive with the class `pt-disabled`.

- Wrap menu item text in a `<span>` element for proper alignment. (Note that React automatically
does this.)

- Add a right-aligned label to a menu item by adding a `span.pt-menu-item-label` inside the
`.pt-menu-item`, after the content. Add an icon to the label by adding icon classes to the label
element (`pt-icon-standard` size is recommended).

- Add a divider between items with `li.pt-menu-divider`.

- If you want the popover to close when the user clicks a menu item, add the class
`pt-popover-dismiss` to any relevant menu items.

<small>\* You do not need to add a `pt-icon-<sizing>` class to menu items&mdash;icon sizing is
defined as part of `.pt-menu-item`.</small>

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  Note that the following examples are `display: inline-block`; you may need to adjust
  menu width in your own usage.
</div>

Markup:
<ul class="pt-menu {{.modifier}} pt-elevation-1">
  <li>
    <a class="pt-menu-item pt-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="pt-menu-item pt-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="pt-menu-item pt-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="pt-menu-divider"></li>
  <li>
    <a class="pt-menu-item pt-icon-trash pt-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.pt-large - Large size (only supported on <code>.pt-menu</code>)

Styleguide components.menu.css
*/
.pt-menu {
  margin: 0;
  border-radius: 3px;
  background: #ffffff;
  min-width: 180px;
  padding: 5px;
  list-style: none;
  text-align: left;
  color: #182026; }

.pt-menu-divider {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15); }
  .pt-dark .pt-menu-divider {
    border-top-color: rgba(255, 255, 255, 0.15); }

.pt-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: block;
  border-radius: 2px;
  padding: 7px;
  line-height: 16px;
  color: inherit;
  user-select: none; }
  .pt-menu-item:not(.pt-disabled):hover, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled) {
    background: #137cbd;
    cursor: pointer;
    color: #ffffff; }
  .pt-menu-item.pt-disabled {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-menu-item {
    color: inherit; }
    .pt-dark .pt-menu-item.pt-disabled {
      color: rgba(191, 204, 214, 0.5); }
  .pt-menu-item::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-right: 7px; }
  .pt-menu-item::before, .pt-menu-item::after {
    color: #5c7080; }
  .pt-menu-item .pt-menu-item-label {
    color: #5c7080; }
  .pt-menu-item:not(.pt-disabled):hover::before, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled)::before, .pt-menu-item:not(.pt-disabled):hover::after, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled)::after,
  .pt-menu-item:not(.pt-disabled):hover .pt-menu-item-label, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled) .pt-menu-item-label {
    color: #ffffff; }
  .pt-menu-item:not(.pt-disabled):hover.pt-intent-primary, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled).pt-intent-primary {
    background-color: #137cbd; }
  .pt-menu-item:not(.pt-disabled):hover.pt-intent-success, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled).pt-intent-success {
    background-color: #0f9960; }
  .pt-menu-item:not(.pt-disabled):hover.pt-intent-warning, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled).pt-intent-warning {
    background-color: #d9822b; }
  .pt-menu-item:not(.pt-disabled):hover.pt-intent-danger, .pt-submenu > .pt-popover-open > .pt-menu-item:not(.pt-disabled).pt-intent-danger {
    background-color: #db3737; }
  .pt-menu-item.pt-disabled {
    outline: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-menu-item.pt-disabled::before, .pt-menu-item.pt-disabled::after {
      color: rgba(92, 112, 128, 0.5); }
    .pt-menu-item.pt-disabled .pt-menu-item-label {
      color: rgba(92, 112, 128, 0.5); }
  .pt-large .pt-menu-item {
    padding: 10px 7px;
    line-height: 20px;
    font-size: 16px; }
    .pt-large .pt-menu-item::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 10px; }

a.pt-menu-item, a.pt-menu-item:hover, .pt-submenu > .pt-popover-open > a.pt-menu-item {
  text-decoration: none; }

button.pt-menu-item {
  border: none;
  background: none;
  width: 100%;
  text-align: left; }

.pt-menu-item-label {
  float: right;
  margin-left: 7px; }

/*
Section headers

Add an `li.pt-menu-header`. Wrap the text in an `<h6>` tag for proper typography and borders.

Markup:
<ul class="pt-menu pt-elevation-1">
  <li class="pt-menu-header"><h6>Layouts</h6></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-grid">Grid</button></li>
  <li class="pt-menu-header"><h6>Views</h6></li>
  <li><button type="button" class="pt-menu-item pt-icon-history">History</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-star">Favorites</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-envelope">Messages</button></li>
</ul>

Styleguide components.menu.css.header
*/
.pt-menu-header {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  cursor: default;
  padding-left: 2px; }
  .pt-dark .pt-menu-header {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .pt-menu-header:first-of-type {
    border-top: none; }
  .pt-menu-header > h6 {
    color: #182026;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin: 0;
    padding: 10px 7px 0 1px;
    line-height: 17px; }
  .pt-menu-header:first-of-type > h6 {
    padding-top: 0; }
  .pt-large .pt-menu-header > h6 {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px; }
  .pt-large .pt-menu-header:first-of-type > h6 {
    padding-top: 0; }

.pt-dark .pt-menu {
  background: #30404d;
  color: #f5f8fa; }

.pt-dark .pt-menu-item::before, .pt-dark .pt-menu-item::after {
  color: #bfccd6; }

.pt-dark .pt-menu-item .pt-menu-item-label {
  color: #bfccd6; }

.pt-dark .pt-menu-item:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-menu-item::before, .pt-dark .pt-menu-item:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-menu-item::after {
  color: #ffffff; }

.pt-dark .pt-menu-item.pt-disabled, .pt-dark .pt-menu-item.pt-disabled:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-disabled.pt-menu-item {
  color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-menu-item.pt-disabled::before, .pt-dark .pt-menu-item.pt-disabled::after, .pt-dark .pt-menu-item.pt-disabled:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-disabled.pt-menu-item::before, .pt-dark .pt-menu-item.pt-disabled:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-disabled.pt-menu-item::after {
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-menu-item.pt-disabled .pt-menu-item-label, .pt-dark .pt-menu-item.pt-disabled:hover .pt-menu-item-label, .pt-dark .pt-submenu > .pt-popover-open > .pt-disabled.pt-menu-item .pt-menu-item-label {
    color: rgba(191, 204, 214, 0.5); }

.pt-dark .pt-menu-divider,
.pt-dark .pt-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.pt-dark .pt-menu-header > h6 {
  color: #f5f8fa; }

.pt-label .pt-menu {
  margin-top: 5px; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Navbars

Navbars present useful navigation controls at the top of an application.

The `Navbar` component can have up to two groups of elements: a left-aligned group and a
right-aligned group. These groups can contain multiple elements, which are laid out horizontally.

Styleguide components.navbar
*/
/*
CSS API

Use the following classes to construct a navbar:

- `nav.pt-navbar` &ndash; The parent element. Use a `<nav>` element for accessibility.
- `.pt-navbar-group.pt-align-(left|right)` &ndash; Left- or right-aligned group.
- `.pt-navbar-heading` &ndash; Larger text for your application title.
- `.pt-navbar-divider` &ndash; Thin vertical line that can be placed between groups of elements.

Markup:
<nav class="pt-navbar {{.modifier}}">
  <div class="pt-navbar-group pt-align-left">
    <div class="pt-navbar-heading">Blueprint</div>
    <input class="pt-input" placeholder="Search files..." type="text" />
  </div>
  <div class="pt-navbar-group pt-align-right">
    <button class="pt-button pt-minimal pt-icon-home">Home</button>
    <button class="pt-button pt-minimal pt-icon-document">Files</button>
    <span class="pt-navbar-divider"></span>
    <button class="pt-button pt-minimal pt-icon-user"></button>
    <button class="pt-button pt-minimal pt-icon-notifications"></button>
    <button class="pt-button pt-minimal pt-icon-cog"></button>
  </div>
</nav>

.pt-dark - Dark theme.

Styleguide components.navbar.css
*/
.pt-navbar {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  height: 50px;
  padding: 0 15px; }
  .pt-navbar.pt-dark,
  .pt-dark .pt-navbar {
    background-color: #394b59; }
  .pt-navbar.pt-dark {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-navbar {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-navbar.pt-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }
  .pt-navbar .pt-logo {
    margin-right: 15px;
    width: 20px; }

.pt-navbar-heading {
  margin-right: 15px;
  font-size: 16px; }

.pt-navbar-group {
  display: flex;
  align-items: center;
  height: 50px; }
  .pt-navbar-group.pt-align-left {
    float: left; }
  .pt-navbar-group.pt-align-right {
    float: right; }

.pt-navbar-divider {
  margin: 0 10px;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px; }
  .pt-dark .pt-navbar-divider {
    border-left-color: rgba(255, 255, 255, 0.15); }

/*
Fixed to viewport top

Add the `.pt-fixed-top` class to the `.pt-navbar` to attach it to the top of the viewport using
`position: fixed; top: 0;`. This is so-called "sticky" behavior: the navbar stays at the top of the
screen as the user scrolls through the document.

This modifier is not illustrated here because it breaks the documentation flow.

<div class="pt-callout pt-intent-danger pt-icon-error">
  <h5>Body padding required</h5>
  The fixed navbar will lie on top of your other content unless you add padding to the top of the
  `<body>` element equal to the height of the navbar. Use the `$pt-navbar-height` Sass variable to
  access the height of the navbar (50px).
</div>

Styleguide components.navbar.css.fixed-top
*/
/*
Fixed width

If your application is inside a fixed-width container (instead of spanning the entire viewport), you
can align the navbar to match.

Wrap your `.pt-navbar-group`s in an element with your desired `width` and `margin: 0 auto;` to
horizontally center it.

Markup:
<nav class="pt-navbar pt-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="pt-navbar-group pt-align-left">
      <div class="pt-navbar-heading">Blueprint</div>
    </div>
    <div class="pt-navbar-group pt-align-right">
      <button class="pt-button pt-minimal pt-icon-home">Home</button>
      <button class="pt-button pt-minimal pt-icon-document">Files</button>
      <span class="pt-navbar-divider"></span>
      <button class="pt-button pt-minimal pt-icon-user"></button>
      <button class="pt-button pt-minimal pt-icon-notifications"></button>
      <button class="pt-button pt-minimal pt-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide components.navbar.css.fixed-width
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Non-ideal state

[Non-ideal UI states](https://github.com/palantir/blueprint/wiki/Non-ideal-UI-states)
inform the user that some content is unavailable. There are several types of non-ideal states,
including:

* blank states (when a container has just been created and has no data in it yet,
  or when a container's contents have been intentionally removed)
* loading states (when a container is preparing to populate with data).
  A good practice is to show a spinner for this state, with optional explanatory text
  below the spinner.
* error states (when something went wrong&mdash;for instance, 404 and 500 HTTP errors).
  In this case, a good practice is to add a call to action directing the user what to do next.

Styleguide components.nonidealstate
*/
/*
CSS API

You may use the provided styles without using the [React component](#components.nonidealstate.js).
See the example below.

Markup:
<div class="pt-non-ideal-state">
  <div class="pt-non-ideal-state-visual pt-non-ideal-state-icon">
    <span class="pt-icon pt-icon-folder-open"></span>
  </div>
  <h4 class="pt-non-ideal-state-title">This Folder Is Empty</h4>
  <div class="pt-non-ideal-state-description">
    Create a new file to populate the folder.
  </div>
</div>

Styleguide components.nonidealstate.css
*/
/*
JavaScript API

The `NonIdealState` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

A `NonIdealState` component's props determine the content displayed. The content should
reflect the situation the user is in: no files found, an empty document, a 404 error, etc.

@interface INonIdealStateProps

@react-example NonIdealStateExample

Styleguide components.nonidealstate.js
*/
.pt-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 400px; }

.pt-non-ideal-state-visual {
  margin-bottom: 20px; }

.pt-non-ideal-state-icon .pt-icon {
  color: rgba(92, 112, 128, 0.5);
  font-size: 60px; }
  .pt-dark .pt-non-ideal-state-icon .pt-icon {
    color: rgba(191, 204, 214, 0.5); }

.pt-non-ideal-state-title {
  margin-bottom: 0; }

.pt-non-ideal-state-description {
  margin-top: 15px;
  text-align: center; }

.pt-non-ideal-state-action {
  margin-top: 15px; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Overlays

`Overlay` is a generic low-level component for rendering content _above_ its siblings, or above the
entire application.

It combines a [`Portal`](#components.portal.js), which allows the JSX children to be rendered at a
different place in the DOM tree, with a
[`CSSTransitionGroup`](https://facebook.github.io/react/docs/animation.html) to support elegant
enter and leave transitions.

An optional "backdrop" element can be rendered behind the overlaid children to provide modal
behavior, whereby the overlay prevents interaction with anything behind it.

`Overlay` is the backbone of the [`Dialog`](#components.dialog) component. In most use cases, the
`Dialog` component should be sufficient; only use `Overlay` directly if an existing component _truly
does not_ meet your needs.

@react-example OverlayExample

Styleguide components.overlay
*/
/*
JavaScript API

The `Overlay` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

`Overlay` is a controlled component that renders its children only when `isOpen={true}`. The
optional backdrop element will be inserted before the children if `hasBackdrop={true}`.

The `onClose` callback prop is invoked when user interaction causes the overlay to close,
but your application is responsible for updating the state that actually closes the overlay.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>A note about overlay content positioning</h5>
  When rendered inline, content will automatically be set to `position: absolute` to respect
  document flow. Otherwise, content will be set to `position: fixed` to cover the entire viewport.
</div>

```
<div>
    <Button text="Show overlay" onClick={this.toggleOverlay} />
    <Overlay isOpen={this.state.isOpen} onClose={this.toggleOverlay}>
        Overlaid contents...
    </Overlay>
</div>
```


@interface IOverlayProps

Styleguide components.overlay.js
*/
/*
Scrollable overlays

Overlays rely heavily on fixed and absolute positioning. By default, a large overlay will not cause
the page to scroll, and any overflowing content will be hidden. Fortunately, Blueprint makes
scrolling support very easy: simply pass `"pt-overlay-scroll-container"` as the Overlay `className`,
and we'll take care of the rest.

```
<Overlay className="pt-overlay-scroll-container" ... />
```

The `Dialog` component does this automatically internally, except when it is used `inline`.

Styleguide components.overlay.scroll
*/
body.pt-overlay-open {
  overflow: hidden; }

.pt-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto; }
  .pt-overlay-backdrop.pt-overlay-enter, .pt-overlay-backdrop.pt-overlay-appear {
    opacity: 0; }
  .pt-overlay-backdrop.pt-overlay-enter-active, .pt-overlay-backdrop.pt-overlay-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-overlay-backdrop.pt-overlay-leave {
    opacity: 1; }
  .pt-overlay-backdrop.pt-overlay-leave-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-overlay-backdrop:focus {
    outline: none; }

.pt-overlay-content {
  position: fixed; }

.pt-overlay-inline .pt-overlay-backdrop,
.pt-overlay-inline .pt-overlay-content {
  position: absolute; }

.pt-overlay-scroll-container.pt-overlay-open {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  overflow: auto; }
  .pt-overlay-scroll-container.pt-overlay-open .pt-overlay-content {
    position: absolute; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Popovers

Popovers display floating content next to a target element.

@react-example PopoverExample

Styleguide components.popover
*/
/*
JavaScript API

The `Popover` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

When creating a popover, you must specify both:
- its _content_, by setting the `content` prop, and
- its _target_, as a single child element.

The target acts as the trigger for the popover. The popover's position on the page is based on the
location of the target.

Internally, the child of a `Popover` component is wrapped in a `<span>` and rendered inline in the
HTML in the component's place.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Button targets</h5>
  Buttons make great popover targets, but the `disabled` attribute on a `<button>` blocks all
  events, which interferes with the popover functioning. If you need to disable a button that
  triggers a popover, you should use [`AnchorButton`](#components.button.js.anchor-button) instead.
  See the [callout here](#components.button.js) for more details.
</div>

```
const { Popover, PopoverInteractionKind, Position } = BlueprintComponents;

export class PopoverExample extends React.Component<{}, {}> {
    public render() {
        let popoverContent = (
            <div>
                <h5>Popover Title</h5>
                <p>...</p>
                <button className="pt-button pt-popover-dismiss">Dismiss</button>
            </div>
        );

        // popover content gets no padding by default, so we can add the
        // .pt-popover-content-sizing class to get nice padding between
        // the edge of the popover and our popover content. We also get
        // a default width for our content if the popover is inline.
        return (
            <Popover content={popoverContent}
                     interactionKind={PopoverInteractionKind.CLICK}
                     popoverClassName="pt-popover-content-sizing"
                     position={Position.RIGHT}
                     useSmartPositioning={false}>
                <button className="pt-button pt-intent-primary">Popover Target</button>
            </Popover>
        );
    }
}
```

@interface IPopoverProps

Weight: -10

Styleguide components.popover.js
*/
/*
Controlled mode

If you prefer to have more control over your popover's behavior, you can specify the `isOpen`
property to use the component in __controlled mode__. You are now in charge of the component's
state.

Providing a non-null value for `isOpen` disables all automatic interaction and instead invokes
the `onInteraction` callback prop any time the opened state _would have changed_ in response to
user interaction under the current `interactionKind`. As a result, the `isDisabled` prop is
incompatible with `isOpen`, and an error is thrown if both are set.

Note that there are cases where `onInteraction` is invoked with an unchanged open state.
It is important to pay attention to the value of the `nextOpenState` parameter and determine
in your application logic whether you should care about a particular invocation (for instance,
if the `nextOpenState` is not the same as the `Popover`'s current state).

##### Example Controlled Usage

```
const { Popover, Position } = BlueprintComponent;

export class ControlledPopoverExample extends React.Component<{}, {isOpen: boolean}> {
    public state = {
        isOpen: false
    };

    public render() {
        let popoverContent = (
            <div>
                <h5>Popover Title</h5>
                <p>...</p>
                <button class="pt-button pt-popover-dismiss">Close Popover</button>
            </div>
        );

        return (
            <Popover content={popoverContent}
                     interactionKind={PopoverInteractionKind.CLICK}
                     isOpen={this.state.isOpen}
                     onInteraction={(state) => this.handleInteraction(state)}
                     position={Position.RIGHT}
                     useSmartPositioning={false}>
                <button className="pt-button pt-intent-primary">Popover Target</button>
            </Popover>
        );
    }

    private handleInteraction(nextOpenState: boolean) {
        this.setState({ isOpen: nextOpenState });
    }
}
```

Weight: -10

Styleguide components.popover.js.controlled
*/
/*
Inline popovers

By default, popover contents are rendered in a newly created element appended to `document.body`.

This works well for most layouts, because you want popovers to appear above everything else in your
application without having to manually adjust z-indices. For these "detached" popovers, we use the
[Tether](http://github.hubspot.com/tether/) library to handle positioning popovers correctly
relative to their targets. Tether is great at maintaining position in complex, dynamic UIs.

However, there are cases where it's preferable to render the popover contents inline.

Take, for example, a scrolling table where certain cells have tooltips attached to them. As row
items go out of view, you want their tooltips to slide out of the viewport as well. This is best
accomplished with inline popovers. Enable this feature by setting `inline={true}`.

It is also important to note that "inline" popovers are much more performant than "detached" ones,
particularly in response to page scrolling, because their position does not need to be recomputed on
every interaction.

Weight: -9

Styleguide components.popover.js.inline
*/
/*
Opening & closing popovers

<div class="pt-callout pt-intent-success pt-icon-info-sign">
  <h5>Conditionally styling popover targets</h5>
  When a popover is open, the target has a `.pt-popover-open` class applied to it.
  You can use this to style the target differently depending on whether the popover is open.
</div>

The different interaction kinds specify whether the popover closes when the user interacts with the
target or the rest of the document, but by default, a user interacting with a popover's *contents*
does __not__ close the popover.

To enable click-to-close behavior on an element inside a popover, simply add the class
`pt-popover-dismiss` to that element. The "Dismiss" button in the demo [above](#components.popover)
has this class. To enable this behavior on the entire popover, pass the
`popoverClassName="pt-popover-dismiss"` prop.

Note that dismiss elements won't have any effect in a popover with
`PopoverInteractionKind.HOVER_TARGET_ONLY` because there is no way to interact with the popover
content itself (the popover is dismissed the moment the user mouses away from the target).

Styleguide components.popover.js.closing
*/
/*
Modal popovers

Setting the `isModal` prop to `true` will:

- Render a transparent backdrop beneath the popover that covers the entire viewport and prevents
  interaction with the document until the popover is closed. This is useful for preventing stray
  clicks or hovers in your app when the user tries to close a popover.
- Focus the popover when opened to allow keyboard accessibility.

Clicking the backdrop will:

- _in uncontrolled mode_, close the popover.
- _in controlled mode_, invoke the `onInteraction` callback with an argument of `false`.

Modal behavior is only available for popovers with `interactionKind={PopoverInteractionKind.CLICK}`
and an error is thrown if used otherwise.

By default, the popover backdrop is invisible, but you can easily add your own styles to
`.pt-popover-backdrop` to customize the appearance of the backdrop (for example, you could give it
a translucent background color, like the backdrop for the [`Dialog`](#components.dialog) component).

The backdrop element has the same opacity fade transition as the `Dialog` backdrop.

<div class="pt-callout pt-intent-danger pt-icon-error">
  <h5>Dangerous edge case</h5>
  Rendering a `<Popover isOpen={true} isModal={true}>` outside the viewport bounds can easily break
  your application by covering the UI with an invisible non-interactive backdrop. This edge case
  must be handled by your application code or simply avoided if possible.
</div>

Styleguide components.popover.js.modal
*/
/*
Sizing popovers

Popovers by default have a max-width but no max-height. To constrain the height of a popover
and make its content scrollable, set the appropriate CSS rules on `.pt-popover-content`:

```css.less
// pass "my-popover" to `popoverClassName` prop.
.my-popover .pt-popover-content {
  max-height: $pt-grid-size * 30;
  overflow-y: auto;
}
```

Styleguide components.popover.js.sizing
*/
/*
SVG popover

`SVGPopover` is a convenience component provided for SVG contexts. It is a simple wrapper around
`Popover` that sets `rootElementTag="g"`.

Styleguide components.popover.js.svg
*/
/*
Minimal popovers

You can create a minimal popover with the `pt-minimal` modifier: `popoverClassName="pt-minimal"`.
This removes the arrow from the popover and makes the transitions more subtle.

This minimal style is recommended for popovers that are not triggered by an obvious action like the
user clicking or hovering over something. For example, a minimal popover is useful for making
typeahead menus where the menu appears almost instantly after the user starts typing.

Minimal popovers are also useful for context menus that require quick enter and leave animations to
support fast workflows. You can see an example in the [Context Menus](#components.context-menu)
documentation.

Styleguide components.popover.js.minimal
*/
.pt-popover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1);
  display: inline-block;
  z-index: 20;
  border-radius: 3px;
  /*
  Dark theme

  The `Popover` component automatically detects whether its trigger is nested inside a `.pt-dark`
  container and applies the same class to itself. You can also explicitly apply the dark theme to
  the React component by providing the prop `popoverClassName="pt-dark"`.

  As a result, any component that you place inside a `Popover` (such as a `Menu`) automatically
  inherits the dark theme styles. Note that `Tooltip` uses `Popover` internally, so it also benefits
  from this behavior.

  This behavior can be disabled when the `Popover` is not rendered inline via the `inheritDarkTheme`
  prop.

  Weight: 10

  Styleguide components.popover.js.dark
  */ }
  .pt-popover .pt-popover-arrow {
    position: absolute;
    width: 30px;
    height: 30px; }
    .pt-popover .pt-popover-arrow::before {
      margin: 5px;
      width: 20px;
      height: 20px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover {
    margin-top: -17px;
    margin-bottom: 17px; }
    .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow {
      bottom: -11px; }
      .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(-90deg); }
  .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover {
    margin-left: 17px; }
    .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow {
      left: -11px; }
      .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(0); }
  .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover {
    margin-top: 17px; }
    .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow {
      top: -11px; }
      .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(90deg); }
  .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover {
    margin-right: 17px;
    margin-left: -17px; }
    .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow {
      right: -11px; }
      .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(180deg); }
  .pt-tether-element-attached-middle > .pt-popover > .pt-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .pt-tether-element-attached-center > .pt-popover > .pt-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .pt-tether-element-attached-top.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow {
    top: -0.3934px; }
  .pt-tether-element-attached-right.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow {
    right: -0.3934px; }
  .pt-tether-element-attached-left.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow {
    left: -0.3934px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow {
    bottom: -0.3934px; }
  .pt-tether-element-attached-top.pt-tether-element-attached-left > .pt-popover {
    transform-origin: top left; }
  .pt-tether-element-attached-top.pt-tether-element-attached-center > .pt-popover {
    transform-origin: top center; }
  .pt-tether-element-attached-top.pt-tether-element-attached-right > .pt-popover {
    transform-origin: top right; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-left > .pt-popover {
    transform-origin: center left; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-center > .pt-popover {
    transform-origin: center center; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-right > .pt-popover {
    transform-origin: center right; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-left > .pt-popover {
    transform-origin: bottom left; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-center > .pt-popover {
    transform-origin: bottom center; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-right > .pt-popover {
    transform-origin: bottom right; }
  .pt-popover .pt-popover-content {
    background: #ffffff;
    color: inherit; }
  .pt-popover .pt-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .pt-popover .pt-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .pt-popover .pt-popover-arrow-fill {
    fill: #ffffff; }
  .pt-popover-enter > .pt-popover, .pt-popover-appear > .pt-popover {
    transform: scale(0.3); }
  .pt-popover-enter-active > .pt-popover, .pt-popover-appear-active > .pt-popover {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-popover-leave > .pt-popover {
    transform: scale(1); }
  .pt-popover-leave-active > .pt-popover {
    transform: scale(0.3);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-popover .pt-popover-content {
    position: relative;
    border-radius: 3px; }
  .pt-popover.pt-popover-content-sizing .pt-popover-content {
    max-width: 350px;
    padding: 20px; }
  .pt-popover-target .pt-popover.pt-popover-content-sizing {
    width: 350px; }
  .pt-popover.pt-minimal {
    margin: 0 !important; }
    .pt-popover.pt-minimal .pt-popover-arrow {
      display: none; }
    .pt-popover.pt-minimal.pt-popover {
      transform: scale(1); }
      .pt-popover-enter > .pt-popover.pt-minimal.pt-popover, .pt-popover-appear > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1); }
      .pt-popover-enter-active > .pt-popover.pt-minimal.pt-popover, .pt-popover-appear-active > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
      .pt-popover-leave > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1); }
      .pt-popover-leave-active > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
  .pt-popover.pt-dark,
  .pt-dark .pt-popover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .pt-popover.pt-dark .pt-popover-content,
    .pt-dark .pt-popover .pt-popover-content {
      background: #30404d;
      color: inherit; }
    .pt-popover.pt-dark .pt-popover-arrow::before,
    .pt-dark .pt-popover .pt-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .pt-popover.pt-dark .pt-popover-arrow-border,
    .pt-dark .pt-popover .pt-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .pt-popover.pt-dark .pt-popover-arrow-fill,
    .pt-dark .pt-popover .pt-popover-arrow-fill {
      fill: #30404d; }

.pt-popover-arrow::before {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 2px;
  content: ""; }

.pt-tether-pinned .pt-popover-arrow {
  display: none; }

.pt-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.pt-transition-container {
  opacity: 1;
  z-index: 20; }
  .pt-transition-container.pt-popover-enter, .pt-transition-container.pt-popover-appear {
    opacity: 0; }
  .pt-transition-container.pt-popover-enter-active, .pt-transition-container.pt-popover-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-transition-container.pt-popover-leave {
    opacity: 1; }
  .pt-transition-container.pt-popover-leave-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-transition-container:focus {
    outline: none; }
  .pt-transition-container.pt-popover-leave .pt-popover-content {
    pointer-events: none; }

.pt-popover-target {
  display: inline-block;
  position: relative;
  vertical-align: top; }
  .pt-popover-target > .pt-popover-target {
    display: inline-block; }
  .pt-popover-target .pt-transition-container {
    position: absolute; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-bottom.pt-tether-target-attached-top {
      bottom: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-left.pt-tether-target-attached-right {
      left: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-top.pt-tether-target-attached-bottom {
      top: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-right.pt-tether-target-attached-left {
      right: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-top.pt-tether-target-attached-top {
      top: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-right.pt-tether-target-attached-right {
      right: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-bottom.pt-tether-target-attached-bottom {
      bottom: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-left.pt-tether-target-attached-left {
      left: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-middle {
      top: 50%;
      transform: translateY(-50%); }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-center {
      left: 50%;
      transform: translateX(-50%); }

.pt-button-group.pt-vertical .pt-popover-target {
  display: block; }

.pt-button-group:not(.pt-vertical) .pt-popover-target,
.pt-button-group:not(.pt-vertical) .pt-tether-target {
  float: left; }

/*
Portals

The `Portal` component renders its children into a new "subtree" outside of the current component
hierarchy. It is essential piece of [`Overlay`](#components.overlay), responsible for ensuring that
the overlay contents cover the application below. In most cases you do not need to use a `Portal`
directly; this documentation is provided simply for reference.

Styleguide components.portal
*/
/*
JavaScript API

The `Portal` component is available in the __@blueprintjs/core__ package. Make sure to review the
[general usage docs for JS components](#components.usage).

The `Portal` component functions like a declarative `appendChild()`, or jQuery's `$.fn.appendTo()`.
The children of a `Portal` component are appended to the `<body>` element.

`Portal` is used inside [`Overlay`](#components.overlay) to actually overlay the content on the
application.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>A note about responsive layouts</h5>
  For a single-page app, if the `<body>` is styled with `width: 100%` and `height: 100%`, a `Portal`
  may take up extra whitespace and cause the window to undesirably scroll. To fix this, instead
  apply `position: absolute` to the `<body>` tag.
</div>

@interface IPortalProps

Styleguide components.portal.js
*/
.pt-portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Progress

Blueprint provides two ways to indicate progress: a horizontal progress bar and a circular spinner.

Styleguide components.progress
*/
/*
Progress bars

Progress bars can indicate determinate progress towards the completion of a task or an indeterminate
loading state.

Styleguide components.progress.bar
*/
/*
CSS API

Set the current progress of the bar via a `width` style rule on the inner `.pt-progress-meter`
element. This is a very simple CSS-only component, and input validation for `width` values is
limited: values above `100%` appear as 100% progress and values below `0%` appear as 0%.

Omitting `width` will result in an "indeterminate" progress meter that fills the entire bar.

Markup:
<div class="pt-progress-bar {{.modifier}}">
  <div class="pt-progress-meter" style="width: 25%"></div>
</div>
<div class="pt-progress-bar pt-intent-primary {{.modifier}}">
  <div class="pt-progress-meter" style="width: 50%"></div>
</div>
<div class="pt-progress-bar pt-intent-success {{.modifier}}">
  <div class="pt-progress-meter" style="width: 75%"></div>
</div>
<div class="pt-progress-bar pt-intent-danger {{.modifier}}">
  <div class="pt-progress-meter" style="width: 100%"></div>
</div>

.pt-no-stripes   - No stripes
.pt-no-animation - No animation

Styleguide components.progress.bar.css
*/
/*
JavaScript API

The `ProgressBar` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

A `ProgressBar` is a simple stateless component that renders the appropriate HTML markup.
It supports a `value` prop between 0 and 1 that determines the width of the progress meter.
Omitting `value` will result in an "indeterminate" progress meter that fills the entire bar.

Note that the CSS modifiers described in the [CSS API](#components.progress.bar.css)
are supported via the `className` prop.

@interface IProgressBarProps

@react-example ProgressExample

Styleguide components.progress.bar.js
*/
@-webkit-keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

@-moz-keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.pt-progress-bar {
  display: block;
  position: relative;
  border-radius: 40px;
  background: rgba(92, 112, 128, 0.2);
  width: 100%;
  height: 8px;
  overflow: hidden; }
  .pt-progress-bar .pt-progress-meter {
    background-color: rgba(255, 255, 255, 0.2);
    background-image: -webkit-linear-gradient(-225deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    background-image: linear-gradient(-45deg,rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    display: inline-block;
    position: absolute;
    border-radius: 40px;
    background-color: rgba(92, 112, 128, 0.8);
    background-size: 30px 30px;
    width: 100%;
    height: 100%;
    transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-progress-bar:not(.pt-no-animation):not(.pt-no-stripes) .pt-progress-meter {
    -webkit-animation: linear-progress-bar-stripes 300ms linear infinite reverse;
    -moz-animation: linear-progress-bar-stripes 300ms linear infinite reverse;
    animation: linear-progress-bar-stripes 300ms linear infinite reverse; }
  .pt-progress-bar.pt-no-stripes .pt-progress-meter {
    background-image: none; }

.pt-dark .pt-progress-bar,
.pt-progress-bar.pt-dark {
  background: rgba(16, 22, 26, 0.5); }
  .pt-dark .pt-progress-bar .pt-progress-meter,
  .pt-progress-bar.pt-dark .pt-progress-meter {
    background-color: #8a9ba8; }

.pt-progress-bar.pt-intent-primary .pt-progress-meter {
  background-color: #137cbd; }

.pt-progress-bar.pt-intent-success .pt-progress-meter {
  background-color: #0f9960; }

.pt-progress-bar.pt-intent-warning .pt-progress-meter {
  background-color: #d9822b; }

.pt-progress-bar.pt-intent-danger .pt-progress-meter {
  background-color: #db3737; }

/*
Skeletons

Skeletons allow you to show a loading state that mimics the shape of your yet-to-load content.

Weight: 1

Styleguide components.progress.skeleton
*/
/*
CSS API

Apply the class `.pt-skeleton` to elements that you would like to cover up with a loading animation.
The skeleton inherits the dimensions of whatever element the class is applied to. This means that
when using skeletons to show loading text, you should use some sort of placeholder text that is
approximately the length of your expected text.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Manually disable focusable elements</h5>
  When using the `.pt-skeleton` class on focusable elements such as inputs and buttons, be sure to
  disable the element, via either the `disabled` or `tabindex="-1"` attributes. Failing to do so
  will allow these skeleton elements to be focused when they shouldn't be.
</div>

Markup:
<div class="pt-card">
  <h5><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="pt-button {{.modifier}}" tabindex="-1">Submit</button>
</div>

.pt-skeleton - Covers up your element with a skeleton animation.

Styleguide components.progress.skeleton.css
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
@keyframes glow {
  0%,
  100% {
    border-color: rgba(167, 182, 194, 0.2);
    background-color: rgba(167, 182, 194, 0.2); }
  50% {
    border-color: rgba(92, 112, 128, 0.2);
    background-color: rgba(92, 112, 128, 0.2); } }

/* stylelint-disable declaration-no-important */
.pt-skeleton {
  border-color: rgba(167, 182, 194, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: rgba(167, 182, 194, 0.2) !important;
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  animation: 2000ms linear infinite glow;
  pointer-events: none;
  user-select: none; }

/* stylelint-enable declaration-no-important */
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Sliders

A slider is a numeric input for choosing one or two numbers between lower and upper bounds.
The `Slider` component also has a labeled axis that supports custom formatting.

To adjust a slider value, the user clicks and drags a handle or clicks the axis to move the nearest
handle to that spot. Users can also use arrow keys on the keyboard to adjust the value.

Use `Slider` for choosing a single value and `RangeSlider` for choosing two values.

Styleguide components.slider
*/
/*
Single slider

@react-example SliderExample

Weight: -1

Styleguide components.slider.single
*/
/*
JavaScript API

The `Slider` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

`Slider` is a controlled component, so the `value` prop determines its current appearance. Provide
an `onChange` handler to receive updates and an `onRelease` handler to determine when the user has
stopped interacting with the slider.

@interface ISliderProps

Styleguide components.slider.single.js
*/
.pt-slider {
  position: relative;
  cursor: default;
  width: 100%;
  min-width: 150px;
  height: 40px;
  outline: none;
  user-select: none; }
  .pt-slider:hover {
    cursor: pointer; }
  .pt-slider:active {
    cursor: grabbing; }
  .pt-slider.pt-disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .pt-slider.pt-slider-unlabeled {
    height: 16px; }

.pt-slider-track,
.pt-slider-progress {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  border-radius: 3px;
  background: rgba(92, 112, 128, 0.2);
  height: 6px; }
  .pt-dark .pt-slider-track, .pt-dark
  .pt-slider-progress {
    background: rgba(16, 22, 26, 0.5); }

.pt-slider-progress,
.pt-dark .pt-slider-progress {
  background: #137cbd; }

.pt-slider-handle {
  background: #f5f8fa;
  background: -webkit-linear-gradient( top , #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
  box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
  color: #182026;
  position: absolute;
  top: 0;
  left: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  cursor: pointer;
  width: 16px;
  height: 16px; }
  .pt-slider-handle:hover {
    background: #ebf1f5;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box; }
  .pt-slider-handle:active {
    border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
    background-color: #ced9e0;
    background-image: none; }
  .pt-slider-handle:disabled {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-clip: border-box;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-slider-handle:focus {
    z-index: 1; }
  .pt-slider-handle:hover {
    background: #ebf1f5;
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #ebf1f5;
    border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    z-index: 2;
    cursor: grab; }
  .pt-slider-handle.pt-active {
    border-color: rgba(16, 22, 26, 0.35) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25) rgba(16, 22, 26, 0.25);
    box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.1);
    background-color: #ced9e0;
    background-image: none;
    cursor: grabbing; }
  .pt-disabled .pt-slider-handle {
    border-color: #bfccd6;
    box-shadow: none;
    background: #bfccd6;
    pointer-events: none; }
  .pt-dark .pt-slider-handle {
    background: rgba(255, 255, 255, 0.1);
    background: -webkit-linear-gradient( top , rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.06)) left no-repeat;
    border-color: rgba(16, 22, 26, 0.6);
    box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
    background-clip: padding-box;
    color: #f5f8fa; }
    .pt-dark .pt-slider-handle:hover, .pt-dark .pt-slider-handle:active {
      color: #f5f8fa; }
    .pt-dark .pt-slider-handle:hover {
      background: rgba(255, 255, 255, 0.1);
      background: -webkit-linear-gradient( top , rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      background: linear-gradient(to bottom, rgba(16, 22, 26, 0.1), rgba(16, 22, 26, 0.2)) left no-repeat, center no-repeat rgba(255, 255, 255, 0.1);
      border-color: rgba(16, 22, 26, 0.8);
      box-shadow: 0 1px 1px rgba(16, 22, 26, 0.2);
      background-clip: padding-box; }
    .pt-dark .pt-slider-handle:active {
      box-shadow: inset 0 1px 1px rgba(16, 22, 26, 0.2);
      background-color: rgba(16, 22, 26, 0.1);
      background-image: none; }
    .pt-dark .pt-slider-handle:disabled {
      border-color: rgba(206, 217, 224, 0.1);
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.1);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-slider-handle .pt-button-spinner .pt-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #8a9ba8; }
    .pt-dark .pt-slider-handle, .pt-dark .pt-slider-handle:hover {
      background-color: #394b59; }
    .pt-dark .pt-slider-handle.pt-active {
      background-color: #293742; }
  .pt-dark .pt-disabled .pt-slider-handle {
    border-color: #5c7080;
    box-shadow: none;
    background: #5c7080; }
  .pt-slider-handle .pt-slider-label {
    transform: translate(-50%, 19px);
    margin-left: 7px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    background: #394b59;
    color: #f5f8fa; }
    .pt-dark .pt-slider-handle .pt-slider-label {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
      background: #e1e8ed;
      color: #394b59; }
    .pt-disabled .pt-slider-handle .pt-slider-label {
      box-shadow: none; }

.pt-slider-label {
  display: inline-block;
  padding: 2px 5px;
  vertical-align: top;
  line-height: 1;
  font-size: 12px;
  position: absolute;
  transform: translate(-50%, 20px); }

/*
Range slider

`RangeSlider` allows the user to choose a range between upper and lower bounds. The component
functions identically to `Slider` except that the user can select both ends of the range. It exposes
its selected value as `[number, number]`: a two-element array with minimum and maximum range bounds.

@react-example RangeSliderExample

Styleguide components.slider.range
*/
/*
JavaScript API

The `RangeSlider` component is available in the __@blueprintjs/core__ package. Make sure to review
the [general usage docs for JS components](#components.usage).

`RangeSlider` is a controlled component, so the `value` prop determines its current appearance.
Provide an `onChange` handler to receive updates and an `onRelease` handler to determine when the
user has stopped interacting with the slider.

@interface IRangeSliderProps

Styleguide components.slider.range.js
*/
.pt-range-slider .pt-slider-handle {
  width: 8px; }
  .pt-range-slider .pt-slider-handle:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-range-slider .pt-slider-handle:last-of-type {
    margin-left: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .pt-range-slider .pt-slider-handle:last-of-type .pt-slider-label {
      margin-left: 0; }

.pt-range-slider .pt-slider-progress {
  border-radius: 0; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Spinners

Spinners indicate indeterminate progress.

Styleguide components.progress.spinner
*/
/*
CSS API

You can create spinners manually by inserting their whole markup into your HTML.
Spinners created via markup use same modifier classes as the
[React `Spinner` component](#components.progress.spinner.js).

Markup:
<div class="pt-spinner {{.modifier}}">
  <div class="pt-spinner-svg-container">
    <svg viewBox="0 0 100 100">
      <path class="pt-spinner-track" d="M 50,50 m 0,-44.5 a 44.5,44.5 0 1 1 0,89 a 44.5,44.5 0 1 1 0,-89"></path>
      <path class="pt-spinner-head" d="M 94.5 50 A 44.5 44.5 0 0 0 50 5.5"></path>
    </svg>
  </div>
</div>

.pt-small - Small spinner
.pt-large - Large spinner

Styleguide components.progress.spinner.css
*/
/*
JavaScript API

The `Spinner` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

A `Spinner` is a simple stateless component that renders HTML/SVG markup.
It supports a `value` prop between 0 and 1 that determines how much of the track is filled by the
head. When this prop is defined, the spinner head will not spin but it will smoothly animate as
`value` updates. Omitting `value` will result in an "indeterminate" spinner where the head spins
indefinitely (this is the default appearance).

Note that the CSS modifiers described in the [CSS API](#components.progress.spinner.css)
are supported via the `className` prop.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>IE11 compatibility note</h5>
  IE11 [does not support CSS transitions on SVG elements][msdn-css-svg] so spinners with known
  `value` will not smoothly transition as `value` changes. Indeterminate spinners still animate
  correctly because they rely on CSS animations, not transitions.
</div>

@interface ISpinnerProps

@react-example SpinnerExample

[msdn-css-svg]: https://developer.microsoft.com/en-us/microsoft-edge/platform/status/csstransitionsforsvgelements/?q=svg

Styleguide components.progress.spinner.js
*/
/*
SVG spinner

Use the `SVGSpinner` component to render a spinner inside an SVG element.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Sizing note</h5>
  Because of the way SVG elements are sized, you may need to manually scale the spinner inside your
  SVG to make it an appropriate size.
</div>

Styleguide components.progress.spinner.js.svg
*/
.pt-spinner:not(.pt-svg-spinner) {
  width: 50px; }
  .pt-spinner:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 400ms; }

.pt-spinner.pt-svg-spinner {
  animation-duration: 400ms; }

.pt-spinner .pt-svg-spinner-transform-group {
  transform: scale(0.5); }

.pt-spinner path {
  stroke-width: 5; }

.pt-spinner path {
  fill-opacity: 0; }

.pt-spinner .pt-spinner-head {
  transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  stroke: rgba(92, 112, 128, 0.8);
  stroke-linecap: round; }

.pt-spinner .pt-spinner-track {
  stroke: rgba(92, 112, 128, 0.2); }

.pt-spinner.pt-small:not(.pt-svg-spinner) {
  width: 24px; }
  .pt-spinner.pt-small:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 400ms; }

.pt-spinner.pt-small.pt-svg-spinner {
  animation-duration: 400ms; }

.pt-spinner.pt-small .pt-svg-spinner-transform-group {
  transform: scale(0.24); }

.pt-spinner.pt-small path {
  stroke-width: 12; }

.pt-spinner.pt-large:not(.pt-svg-spinner) {
  width: 100px; }
  .pt-spinner.pt-large:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 450ms; }

.pt-spinner.pt-large.pt-svg-spinner {
  animation-duration: 450ms; }

.pt-spinner.pt-large .pt-svg-spinner-transform-group {
  transform: scale(1); }

.pt-spinner.pt-large path {
  stroke-width: 3; }

.pt-spinner:not(.pt-svg-spinner) {
  display: inline-block; }
  .pt-spinner:not(.pt-svg-spinner) .pt-spinner-svg-container {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0;
    padding-bottom: 100%;
    animation: pt-spinner-animation 400ms linear infinite; }
  .pt-spinner:not(.pt-svg-spinner).pt-no-spin .pt-spinner-svg-container {
    animation: none; }
  .pt-spinner:not(.pt-svg-spinner) svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.pt-svg-spinner {
  transform-origin: center;
  animation: pt-spinner-animation 400ms linear infinite; }
  .pt-svg-spinner.pt-no-spin {
    animation: none; }

.pt-svg-spinner-transform-group {
  transform-origin: center; }

.pt-dark .pt-spinner .pt-spinner-head {
  stroke: #8a9ba8; }

.pt-dark .pt-spinner .pt-spinner-track {
  stroke: rgba(16, 22, 26, 0.5); }

.pt-spinner.pt-intent-primary .pt-spinner-head {
  stroke: #137cbd; }

.pt-spinner.pt-intent-success .pt-spinner-head {
  stroke: #0f9960; }

.pt-spinner.pt-intent-warning .pt-spinner-head {
  stroke: #d9822b; }

.pt-spinner.pt-intent-danger .pt-spinner-head {
  stroke: #db3737; }

@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Table (HTML)

This component adds Blueprint styling to native HTML tables.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>This is not @blueprintjs/table</h5>
  This table component is a simple CSS-only skin for HTML `<table>` elements.
  It is ideal for basic static tables. If you're looking for more complex
  spreadsheet-like features, check out [**@blueprintjs/table**](#components.table-js).
</div>

Styleguide components.table
*/
/*
CSS API

Apply the `pt-table` class to a `<table>` element. You can apply modifiers as additional classes.

Markup:
<table class="pt-table {{.modifier}}">
  <thead>
    <th>Project</th>
    <th>Description</th>
    <th>Technologies</th>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
    </tr>
  </tbody>
</table>

.pt-condensed - Condensed appearance
.pt-striped   - Striped appearance
.pt-bordered  - Bordered appearance
.pt-interactive  - Enables hover styles on rows

Styleguide components.table.css
*/
table.pt-table {
  border-spacing: 0;
  font-size: 14px; }
  table.pt-table th,
  table.pt-table td {
    padding: 11px;
    vertical-align: top;
    text-align: left; }
  table.pt-table th {
    color: #182026;
    font-weight: 600; }
  table.pt-table td {
    color: #182026; }
  table.pt-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-condensed th,
  table.pt-table.pt-condensed td {
    padding-top: 6px;
    padding-bottom: 6px; }
  table.pt-table.pt-striped tbody tr:nth-child(odd) td {
    background: rgba(191, 204, 214, 0.2); }
  table.pt-table.pt-bordered th:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-bordered tbody tr td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
    table.pt-table.pt-bordered tbody tr td:not(:first-child) {
      box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td {
    box-shadow: none; }
    table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td:not(:first-child) {
      box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-interactive tbody tr:hover td {
    background-color: rgba(191, 204, 214, 0.4);
    cursor: pointer; }
  .pt-dark table.pt-table th {
    color: #f5f8fa; }
  .pt-dark table.pt-table td {
    color: #f5f8fa; }
  .pt-dark table.pt-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-striped tbody tr:nth-child(odd) td {
    background: rgba(92, 112, 128, 0.15); }
  .pt-dark table.pt-table.pt-bordered th:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-bordered tbody tr td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
    .pt-dark table.pt-table.pt-bordered tbody tr td:not(:first-child) {
      box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
    .pt-dark table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td:first-child {
      box-shadow: none; }
  .pt-dark table.pt-table.pt-interactive tbody tr:hover td {
    background-color: rgba(92, 112, 128, 0.3);
    cursor: pointer; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Tabs

Tabs allow the user to switch between panels of content.

Styleguide components.tabs
*/
/*
CSS API

In addition to the [JavaScript API](#components.tabs.js), Blueprint also offers tab styles with the
class `pt-tabs`. You should add the proper accessibility attributes (`role`, `aria-selected`, and
`aria-hidden`) if you choose to implement tabs with CSS.

`.pt-tab-panel` elements with `aria-hidden="true"` are hidden automatically by the Blueprint CSS.
You may also simply omit hidden tabs from your markup to improve performance (the `Tabs`
JavaScript component does this by default).

Markup:
<div class="pt-tabs">
    <ul class="pt-tab-list {{.modifier}}" role="tablist">
        <li class="pt-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="pt-tab" role="tab">Another tab</li>
        <li class="pt-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="pt-tab-panel" role="tabpanel">Selected panel</div>
    <div class="pt-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="pt-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.pt-large - Large tabs

Styleguide components.tabs.css
*/
/*
JavaScript API

The `Tabs`, `TabList`, `Tab`, and `TabPanel` components are available in the __@blueprintjs/core__
package. Make sure to review the [general usage docs for JS components](#components.usage).

Four components are necessary to render tabs: `Tabs`, `TabList`, `Tab`, and `TabPanel`.

For performance reasons, only the currently active `TabPanel` is rendered into the DOM. When the
user switches tabs, data stored in the DOM is lost. This is not an issue in React applications
because of how the library manages the virtual DOM for you.

### Sample Usage

```
<Tabs>
    <TabList>
        <Tab>First tab</Tab>
        <Tab>Second tab</Tab>
        <Tab>Third tab</Tab>
        <Tab isDisabled={true}>Fourth tab</Tab>
    </TabList>
    <TabPanel>
        First panel
    </TabPanel>
    <TabPanel>
        Second panel
    </TabPanel>
    <TabPanel>
        Third panel
    </TabPanel>
    <TabPanel>
        Fourth panel
    </TabPanel>
</Tabs>
```

Every component accepts a `className` prop that can be used to set additional classes on the
component's root element. You can get larger tabs by using the `pt-large` class on `TabList`.

You can use the `Tabs` API in controlled or uncontrolled mode. The props you supply will differ
between these approaches.

@react-example TabsExample

Styleguide components.tabs.js
*/
/*
Tabs props

@interface ITabsProps

Styleguide components.tabs.js.tabs
*/
/*
Tab props

@interface ITabProps

Styleguide components.tabs.js.tab
*/
/*
Usage with React Router

Often, you'll want to link tab navigation to overall app navigation, including updating the URL.
[react-router](https://github.com/reactjs/react-router) is the standard routing solution for React
applications. Here's how you might configure tabs to work with it:

```
import { render } from "react-dom";
import { Router, Route } from "react-router";
import { Tabs, TabList, Tab, TabPanel } from "@blueprintjs/core";

const App = () => { ... };

// keys are necessary in JSX.Element lists to keep React happy
const contents = [
    <TabList key={0}>
        <Tab>Home</Tab>
        <Tab>Projects</Tab>
    </TabList>,
    <TabPanel key={1}>
        home things
    </TabPanel>,
    <TabPanel key={2}>
        projects things
    </TabPanel>,
];

// using SFCs from TS 1.8, but easy to do without them
export const Home = () => <Tabs selectedTabIndex={0}>{contents}</Tabs>;
export const Projects = () => <Tabs selectedTabIndex={1}>{contents}</Tabs>;

render(
    <Router path="/" component={App}>
        <Route path="home" component={Home}/>
        <Route path="projects" component={Projects}/>
    </Router>,
    document.querySelector("#app")
);
```

Weight: 3

Styleguide components.tabs.js.router
*/
.pt-tabs.pt-vertical {
  display: flex; }
  .pt-tabs.pt-vertical > .pt-tab-list {
    flex-direction: column;
    align-items: flex-start; }
    .pt-tabs.pt-vertical > .pt-tab-list .pt-tab {
      width: 100%;
      padding: 0 10px; }
    .pt-tabs.pt-vertical > .pt-tab-list .pt-tab-indicator-wrapper .pt-tab-indicator {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      background-color: rgba(19, 124, 189, 0.2);
      height: auto; }
  .pt-tabs.pt-vertical > .pt-tab-panel {
    margin-top: 0;
    padding-left: 20px; }

.pt-tab-list {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  position: relative;
  margin: 0;
  border: none;
  padding: 0;
  list-style: none; }
  .pt-tab-list.pt-large .pt-tab {
    line-height: 40px;
    font-size: 16px; }

.pt-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  vertical-align: top;
  line-height: 30px;
  color: #182026;
  font-size: 14px; }
  .pt-tab-indicator-wrapper ~ .pt-tab {
    box-shadow: none !important; }
  .pt-tab:not(:last-of-type) {
    margin-right: 20px; }
  .pt-tab[aria-disabled="true"] {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-tab[aria-selected="true"] {
    border-radius: 0;
    box-shadow: inset 0 -3px 0 #106ba3; }
  .pt-tab[aria-selected="true"], .pt-tab:not([aria-disabled="true"]):hover {
    color: #106ba3; }
  .pt-tab:focus {
    -moz-outline-radius: 0; }

.pt-tab-panel {
  margin-top: 20px; }
  .pt-tab-panel[aria-hidden="true"] {
    display: none; }

.pt-tab-indicator-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  pointer-events: none; }
  .pt-tab-indicator-wrapper .pt-tab-indicator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #106ba3;
    height: 3px; }
  .pt-tab-indicator-wrapper.pt-no-animation {
    transition: none; }

.pt-dark .pt-tab {
  color: #f5f8fa; }
  .pt-dark .pt-tab[aria-disabled="true"] {
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-tab[aria-selected="true"] {
    box-shadow: inset 0 -3px 0 #2b95d6; }
  .pt-dark .pt-tab[aria-selected="true"], .pt-dark .pt-tab:not([aria-disabled="true"]):hover {
    color: #2b95d6; }

.pt-dark .pt-tab-indicator {
  background-color: #2b95d6; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Tags

Tags are great for lists of strings.

Styleguide components.tag
*/
/*
CSS API

An optional "remove" button can be added inside a tag as a `button.pt-tag-remove`. Also add the
class `.pt-tag-removable` to the `.pt-tag` itself to adjust padding. The button is a separate
element to support interaction handlers in your framework of choice.

A simple `.pt-tag` without the remove button can easily function as a badge.

Markup:
<p>
  <span class="pt-tag {{.modifier}}">125</span>
  <span class="pt-tag {{.modifier}}">Done</span>
  <span class="pt-tag pt-tag-removable {{.modifier}}">
    Tracking
    <button class="pt-tag-remove"></button>
  </span>
  <span class="pt-tag pt-tag-removable {{.modifier}}">
    Crushed
    <button class="pt-tag-remove"></button>
  </span>
</p>
<span class="pt-tag pt-tag-removable {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="pt-tag-remove"></button>
</span>

.pt-large - Large
.pt-round - Rounded corners, ideal for badges
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger  - Danger intent

Styleguide components.tag.css
*/
.pt-tag {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background: #5c7080;
  min-width: 20px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 12px;
  /*
  Minimal tags

  Add the `.pt-minimal` modifier for a lighter tag appearance. The translucent background color
  will adapt to its container's background color.

  Markup:
  <div class="pt-tag pt-minimal {{.modifier}}">125</div>
  <div class="pt-tag pt-minimal {{.modifier}}">Done</div>
  <div class="pt-tag pt-minimal pt-tag-removable {{.modifier}}">
    Tracking
    <button class="pt-tag-remove"></button>
  </div>

  .pt-large - Large
  .pt-round - Rounded corners, ideal for badges
  .pt-intent-primary - Primary intent
  .pt-intent-success - Success intent
  .pt-intent-warning - Warning intent
  .pt-intent-danger  - Danger intent

  Styleguide components.tag.css.minimal
  */ }
  .pt-tag.pt-round {
    border-radius: 10px; }
  .pt-dark .pt-tag:not([class*="pt-intent-"]) {
    background: #bfccd6;
    color: #182026; }
  .pt-tag.pt-tag-removable {
    padding-right: 20px; }
  .pt-tag.pt-intent-primary {
    background: #137cbd;
    color: #ffffff; }
  .pt-tag.pt-intent-success {
    background: #0f9960;
    color: #ffffff; }
  .pt-tag.pt-intent-warning {
    background: #d9822b;
    color: #ffffff; }
  .pt-tag.pt-intent-danger {
    background: #db3737;
    color: #ffffff; }
  .pt-tag.pt-large,
  .pt-large .pt-tag {
    min-width: 30px;
    padding: 5px 10px;
    line-height: 20px;
    font-size: 14px; }
    .pt-tag.pt-large.pt-round,
    .pt-large .pt-tag.pt-round {
      border-radius: 15px; }
    .pt-tag.pt-large.pt-tag-removable,
    .pt-large .pt-tag.pt-tag-removable {
      padding-right: 30px; }
  .pt-tag.pt-minimal:not([class*="pt-intent-"]) {
    background: rgba(138, 155, 168, 0.2);
    color: #182026; }
    .pt-dark .pt-tag.pt-minimal:not([class*="pt-intent-"]) {
      background: rgba(138, 155, 168, 0.2);
      color: #f5f8fa; }
  .pt-tag.pt-minimal.pt-intent-primary {
    background: rgba(19, 124, 189, 0.15);
    color: #106ba3; }
  .pt-dark .pt-tag.pt-minimal.pt-intent-primary {
    background: rgba(19, 124, 189, 0.2);
    color: #2b95d6; }
  .pt-tag.pt-minimal.pt-intent-success {
    background: rgba(15, 153, 96, 0.15);
    color: #0d8050; }
  .pt-dark .pt-tag.pt-minimal.pt-intent-success {
    background: rgba(15, 153, 96, 0.2);
    color: #15b371; }
  .pt-tag.pt-minimal.pt-intent-warning {
    background: rgba(217, 130, 43, 0.15);
    color: #bf7326; }
  .pt-dark .pt-tag.pt-minimal.pt-intent-warning {
    background: rgba(217, 130, 43, 0.2);
    color: #f29d49; }
  .pt-tag.pt-minimal.pt-intent-danger {
    background: rgba(219, 55, 55, 0.15);
    color: #c23030; }
  .pt-dark .pt-tag.pt-minimal.pt-intent-danger {
    background: rgba(219, 55, 55, 0.2);
    color: #f55656; }

.pt-tag-remove {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
  color: #ffffff; }
  .pt-tag-remove:hover {
    opacity: 0.8;
    background: none;
    text-decoration: none; }
  .pt-tag-remove::before {
    content: ""; }
  .pt-dark .pt-tag:not(.pt-minimal):not([class*="pt-intent-"]) .pt-tag-remove {
    color: #182026; }
  .pt-large .pt-tag-remove {
    line-height: 1;
    font-family: "Icons20", sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    margin-left: 5px;
    padding: 5px; }
  .pt-minimal .pt-tag-remove {
    color: inherit; }
    .pt-intent-primary .pt-minimal .pt-tag-remove {
      color: #137cbd; }
    .pt-dark .pt-intent-primary .pt-minimal .pt-tag-remove {
      color: #137cbd; }
    .pt-intent-success .pt-minimal .pt-tag-remove {
      color: #0f9960; }
    .pt-dark .pt-intent-success .pt-minimal .pt-tag-remove {
      color: #0f9960; }
    .pt-intent-warning .pt-minimal .pt-tag-remove {
      color: #d9822b; }
    .pt-dark .pt-intent-warning .pt-minimal .pt-tag-remove {
      color: #d9822b; }
    .pt-intent-danger .pt-minimal .pt-tag-remove {
      color: #db3737; }
    .pt-dark .pt-intent-danger .pt-minimal .pt-tag-remove {
      color: #db3737; }

/*
JavaScript API

The `Tag` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

Tag components render `.pt-tag` elements with optional close buttons. Provide tag content as `children`.

You can provide your own props to these components as if they were regular JSX HTML elements. If
you provide a `className` prop, the class names you provide will be added alongside of the default
Blueprint class name.

```
<Tag intent={Intent.PRIMARY} onRemove={this.deleteTag}>Done</Tag>
// renders:
<span class="pt-tag pt-intent-primary pt-tag-removable">
  Done
  <button class="pt-tag-remove" onClick={this.deleteTag}></button>
</span>
```

@interface ITagProps

@react-example TagExample

Styleguide components.tag.js
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Toasts

A toast is a lightweight, ephemeral notice from an application in direct response to a user's action.

`Toast`s have a built-in timeout of five seconds. Users can also dismiss them manually by clicking the &times; button.
Hovering the cursor over a toast prevents it from disappearing. When the cursor leaves the toast, the toast's timeout restarts.
Similarly, focusing the toast (for example, by hitting the <kbd class="pt-key">tab</kbd> key) halts the timeout, and
blurring restarts the timeout.

You can add one additional action button to a toast. You might use this to undo the user's action, for example.

You can also apply the same visual intent styles to `Toast`s that you can to [`Button`s](components.button.css).

Toasts can be configured to appear at either the top or the bottom of an application window, and it is possible to
have more than one toast onscreen at a time.

@react-example ToastExample

Styleguide components.toaster
*/
/*
JavaScript API

The `Toast` and `Toaster` components are available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

The `Toaster` component provides the static `create` method that returns a new `Toaster` instance, rendered into an
element attached to `<body>`. (You can also specify the element to render into if desired.) A `Toaster` instance
has a collection of methods to show and hide toasts in its given container.

Your application can contain several `Toaster` instances and easily share them across the codebase as modules.

```
// toaster.ts
import { Position, Toaster } from "@blueprintjs/core";

export const OurToaster = Toaster.create({
    className: "my-toaster",
    position: Position.BOTTOM_RIGHT,
});
```

```
// application.ts
import { OurToaster } from "./toaster";

const key = OurToaster.show({ message: "Toasted!" });
OurToaster.update(key, { message: "Still toasted!" });
```

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Working with multiple toasters</h5>
  You can have multiple toasters in a single application, but you must ensure that each has a unique
  `position` to prevent overlap.
</div>

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Toaster focus</h5>
  `Toaster` always disables `Overlay`'s `enforceFocus` behavior (meaning that you're not blocked
  from accessing other parts of the application while a toast is active), and by default also
  disables `autoFocus` (meaning that focus will not switch to a toast when it appears). You can
  enable `autoFocus` for a `Toaster` via a prop, if desired.
</div>

Styleguide components.toaster.js
*/
/*
Static method

```ts
Toaster.create(props?: IToasterProps, container = document.body): IToaster
```

Create a new `Toaster` instance. The `Toaster` will be rendered into a new element appended to the
given `container`. The `container` determines which element toasts are positioned relative to; the
default value of `<body>` allows them to use the entire viewport.

Note that the return type is `IToaster`, which is a minimal interface that exposes only the instance
methods detailed below. It can be thought of as `Toaster` minus the `React.Component` methods,
because the `Toaster` should not be treated as a normal React component.

@interface IToasterProps

Styleguide components.toaster.js.create
*/
/*
Instance methods

<div class="docs-interface-name">IToaster</div>

- `show(props: IToastProps): string` &ndash; Show a new toast to the user.
  Returns the unique key of the new toast.
- `update(key: string, props: IToastProps): void` &ndash;
  Updates the toast with the given key to use the new props.
  Updating a key that does not exist is effectively a no-op.
- `dismiss(key: string): void` &ndash; Dismiss the given toast instantly.
- `clear(): void` &ndash; Dismiss all toasts instantly.
- `getToasts(): IToastProps[]` &ndash; Returns the options for all current toasts.

@interface IToastProps

Styleguide components.toaster.js.instance
*/
/*
React component

The `Toaster` React component is a stateful container for a single list of toasts. Internally, it
uses [`Overlay`](#components.overlay) to manage children and transitions. It can be vertically
aligned along the top or bottom edge of its container (new toasts will slide in from that edge) and
horizontally aligned along the left edge, center, or right edge of its container.

You should use [`Toaster.create`](#components.toaster.js.create), rather than using the `Toaster`
component API directly in React, to avoid having to use `ref` to access the instance.

```
import { Button, Position, Toaster } from "@blueprintjs/core";

class MyComponent extends React.Component<{}, {}> {
    private toaster: Toaster;
    private refHandlers = {
        toaster: (ref: Toaster) => this.toaster = ref,
    };

    public render() {
        return (
            <div>
                <Button onClick={this.addToast} text="Procure toast" />
                <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
            </div>
        )
    }

    private addToast = () => {
        this.toaster.show({ message: "Toasted!" });
    }
}
```

Styleguide components.toaster.js.react
*/
.pt-toast {
  display: flex;
  align-items: flex-start;
  position: relative !important;
  margin: 15px 0 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  min-width: 300px;
  max-width: 500px;
  pointer-events: all; }
  .pt-toast.pt-toast-enter, .pt-toast.pt-toast-appear {
    transform: translateY(-40px); }
  .pt-toast.pt-toast-enter-active, .pt-toast.pt-toast-appear-active {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-toast.pt-toast-enter ~ .pt-toast, .pt-toast.pt-toast-appear ~ .pt-toast {
    transform: translateY(-40px); }
  .pt-toast.pt-toast-enter-active ~ .pt-toast, .pt-toast.pt-toast-appear-active ~ .pt-toast {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-toast.pt-toast-leave {
    opacity: 1;
    filter: blur(0); }
  .pt-toast.pt-toast-leave-active {
    opacity: 0;
    filter: blur(10px);
    transition-property: opacity, filter;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-toast.pt-toast-leave ~ .pt-toast {
    transform: translateY(0); }
  .pt-toast.pt-toast-leave-active ~ .pt-toast {
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 50ms; }
  .pt-toast .pt-button-group {
    flex: 0 0 auto;
    padding: 5px;
    padding-left: 0; }
  .pt-toast > .pt-icon-standard {
    padding: 12px;
    padding-right: 0; }
  .pt-toast.pt-dark,
  .pt-dark .pt-toast {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
    background-color: #394b59; }
  .pt-toast[class*="pt-intent-"] a {
    color: rgba(255, 255, 255, 0.7); }
    .pt-toast[class*="pt-intent-"] a:hover {
      color: #ffffff; }
  .pt-toast[class*="pt-intent-"] > .pt-icon-standard {
    color: #ffffff; }
  .pt-toast[class*="pt-intent-"] .pt-button, .pt-toast[class*="pt-intent-"] .pt-button::before, .pt-toast[class*="pt-intent-"] .pt-button:active, .pt-toast[class*="pt-intent-"] .pt-button.pt-active {
    color: rgba(255, 255, 255, 0.7) !important; }
  .pt-toast[class*="pt-intent-"] .pt-button:focus {
    outline-color: rgba(255, 255, 255, 0.5); }
  .pt-toast[class*="pt-intent-"] .pt-button:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: #ffffff !important; }
  .pt-toast[class*="pt-intent-"] .pt-button:active, .pt-toast[class*="pt-intent-"] .pt-button.pt-active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #ffffff !important; }
  .pt-toast[class*="pt-intent-"] .pt-button::after {
    background: rgba(255, 255, 255, 0.3) !important; }
  .pt-toast.pt-intent-primary {
    background-color: #137cbd;
    color: #ffffff; }
  .pt-toast.pt-intent-success {
    background-color: #0f9960;
    color: #ffffff; }
  .pt-toast.pt-intent-warning {
    background-color: #d9822b;
    color: #ffffff; }
  .pt-toast.pt-intent-danger {
    background-color: #db3737;
    color: #ffffff; }

.pt-toast-message {
  flex: 1 1 auto;
  padding: 11px; }

.pt-toast-container {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
  padding: 0 15px 15px;
  pointer-events: none; }
  .pt-toast-container > span {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .pt-toast-container.pt-toast-container-top {
    top: 0; }
  .pt-toast-container.pt-toast-container-bottom {
    bottom: 0; }
    .pt-toast-container.pt-toast-container-bottom > span {
      flex-direction: column-reverse; }
  .pt-toast-container.pt-toast-container-left > span {
    align-items: flex-start; }
  .pt-toast-container.pt-toast-container-right > span {
    align-items: flex-end; }

.pt-toast-container-bottom .pt-toast.pt-toast-enter:not(.pt-toast-enter-active),
.pt-toast-container-bottom .pt-toast.pt-toast-enter:not(.pt-toast-enter-active) ~ .pt-toast,
.pt-toast-container-bottom .pt-toast.pt-toast-leave-active ~ .pt-toast {
  transform: translateY(55px); }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Tooltips

Tooltips display a small string of text next to a target element.

@react-example TooltipExample

Styleguide components.tooltip
*/
/*
JavaScript API

The `Tooltip` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

When creating a tooltip, you must specify both:
- its _content_, by setting the `content` prop, and
- its _target_, as a single child element or as plain text

When the user hovers over the target, the content is displayed in a tooltip above the target.

Content can be a `string` or a single `JSX.Element` (typically used to format said string),
but you should keep it simple. If you need more space, consider using a popover instead of a tooltip.

<div class="pt-callout pt-intent-warning pt-icon-warning-sign">
  <h5>Button targets</h5>
  Buttons make great tooltip targets, but the `disabled` attribute will prevent all events so the enclosing `Tooltip`
  will not know when to respond. Use [`AnchorButton`](#components.button.js.anchor-button) instead;
  see the [callout here](#components.button.js) for more details.
</div>

@interface ITooltipProps

Weight: -10

Styleguide components.tooltip.js
*/
/*
Controlled mode

The `Tooltip` component supports controlled mode in exactly the same way the `Popover` component
does. Please refer to the [controlled mode documentation](#components.popover.js.controlled) for
`Popover` for details.

Styleguide components.tooltip.js.controlled
*/
/*
Inline tooltips

Inline tooltips (with `inline={true}`) do not have a set width, and therefore will not break long
content into multiple lines. This is enforced with `white-space: nowrap`.

If you want to create an inline tooltip with content spanning multiple lines, you must override the
default styles and set an appropriate size for `.pt-tooltip`.

Styleguide components.tooltip.js.inline
*/
/*
Combining with popover

You can give a single target both a popover and a tooltip. You must put the `Tooltip` inside the
`Popover` (and the target inside the `Tooltip`).

This order is required because when the popover is open, the tooltip is disabled, to prevent both
elements from appearing at the same time.

```
<Popover content={<h1>Popover!</h1>} position={Position.RIGHT}>
    <Tooltip content="I has a popover!" position={Position.RIGHT}>
        <button className="pt-button pt-intent-success">Hover and click me</button>
    </Tooltip>
</Popover>
```

Styleguide components.tooltip.js.popover
*/
/*
SVG tooltip

`SVGTooltip` is a convenience component provided for SVG contexts. It is a simple wrapper around
`Tooltip` that sets `rootElementTag="g"`.

Styleguide components.tooltip.js.svg
*/
.pt-tooltip {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1);
  /*
  Dark theme

  If the trigger for a tooltip is nested inside a `.pt-dark` container, the tooltip will
  automatically have the dark theme applied as well.

  You can also explicitly apply the dark theme to a tooltip by adding the prop
  `tooltipClassName="pt-dark"`.

  Weight: 10

  Styleguide components.tooltip.js.dark
  */ }
  .pt-tooltip .pt-popover-arrow {
    position: absolute;
    width: 22px;
    height: 22px; }
    .pt-tooltip .pt-popover-arrow::before {
      margin: 4px;
      width: 14px;
      height: 14px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip {
    margin-top: -11px;
    margin-bottom: 11px; }
    .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow {
      bottom: -8px; }
      .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(-90deg); }
  .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip {
    margin-left: 11px; }
    .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow {
      left: -8px; }
      .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(0); }
  .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip {
    margin-top: 11px; }
    .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow {
      top: -8px; }
      .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(90deg); }
  .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip {
    margin-right: 11px;
    margin-left: -11px; }
    .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow {
      right: -8px; }
      .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(180deg); }
  .pt-tether-element-attached-middle > .pt-tooltip > .pt-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .pt-tether-element-attached-center > .pt-tooltip > .pt-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .pt-tether-element-attached-top.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow {
    top: -0.22183px; }
  .pt-tether-element-attached-right.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow {
    right: -0.22183px; }
  .pt-tether-element-attached-left.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow {
    left: -0.22183px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow {
    bottom: -0.22183px; }
  .pt-tether-element-attached-top.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: top left; }
  .pt-tether-element-attached-top.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: top center; }
  .pt-tether-element-attached-top.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: top right; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: center left; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: center center; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: center right; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: bottom left; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: bottom center; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: bottom right; }
  .pt-tooltip .pt-popover-content {
    background: #394b59;
    color: #f5f8fa; }
  .pt-tooltip .pt-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .pt-tooltip .pt-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .pt-tooltip .pt-popover-arrow-fill {
    fill: #394b59; }
  .pt-popover-enter > .pt-tooltip, .pt-popover-appear > .pt-tooltip {
    transform: scale(0.8); }
  .pt-popover-enter-active > .pt-tooltip, .pt-popover-appear-active > .pt-tooltip {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-popover-leave > .pt-tooltip {
    transform: scale(1); }
  .pt-popover-leave-active > .pt-tooltip {
    transform: scale(0.8);
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-tooltip .pt-popover-content {
    padding: 10px 12px; }
  .pt-tooltip.pt-dark,
  .pt-dark .pt-tooltip {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .pt-tooltip.pt-dark .pt-popover-content,
    .pt-dark .pt-tooltip .pt-popover-content {
      background: #e1e8ed;
      color: #394b59; }
    .pt-tooltip.pt-dark .pt-popover-arrow::before,
    .pt-dark .pt-tooltip .pt-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .pt-tooltip.pt-dark .pt-popover-arrow-border,
    .pt-dark .pt-tooltip .pt-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .pt-tooltip.pt-dark .pt-popover-arrow-fill,
    .pt-dark .pt-tooltip .pt-popover-arrow-fill {
      fill: #e1e8ed; }
  .pt-tooltip.pt-intent-primary .pt-popover-content {
    background: #137cbd;
    color: #ffffff; }
  .pt-tooltip.pt-intent-primary .pt-popover-arrow-fill {
    fill: #137cbd; }
  .pt-tooltip.pt-intent-success .pt-popover-content {
    background: #0f9960;
    color: #ffffff; }
  .pt-tooltip.pt-intent-success .pt-popover-arrow-fill {
    fill: #0f9960; }
  .pt-tooltip.pt-intent-warning .pt-popover-content {
    background: #d9822b;
    color: #ffffff; }
  .pt-tooltip.pt-intent-warning .pt-popover-arrow-fill {
    fill: #d9822b; }
  .pt-tooltip.pt-intent-danger .pt-popover-content {
    background: #db3737;
    color: #ffffff; }
  .pt-tooltip.pt-intent-danger .pt-popover-arrow-fill {
    fill: #db3737; }

.pt-popover-target .pt-tooltip {
  white-space: nowrap; }

.pt-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Colors

Hex values for these colors can be accessed in JavaScript. The global version of the module exposes
the `Blueprint.Colors` object. In CommonJS, you may `import { Colors } from "@blueprintjs/core"`.

Styleguide colors
*/
/*
Gray scale

Black, white and everything in between. The gray scale should be used for
the main UI frame: containers, headers, sections, boxes, etc.
If you need to call attention to a particular element (buttons, icons, tooltips, etc.),
use one of the [core colors](#colors.core).

@react-docs GrayscalePalette

Weight: -1

Styleguide colors.grays
*/
/*
Core colors

Core colors are reserved for user interface design. Use these to help call
attention to specific UI elements, such as buttons, callouts, icons, etc.
Each core color is mapped to what we call a __visual intent__. We use intents
to convey the status of UI elements:

- _Blue_ (intent: primary) elevates elements from the typical gray scale UI frame.
- _Green_ (intent: success) indicates successful operations.
- _Orange_ (intent: warning) indicates warnings and intermediate states.
- _Red_ (intent: danger) indicates errors and potentially destructive operations.

Core colors are also designed to:

- go well together and be used alongside each other in any application.
- adhere to [WCAG 2.0](https://www.w3.org/TR/WCAG20/) standards, and therefore are
  highly accessible to visually impaired and color blind users.

@react-docs CoreColorsPalette

Styleguide colors.core
*/
/*
Extended colors

Extended colors should typically be reserved for data visualizations: any time
you need to represent data of some sort, you can use these.
These colors are less strict on [WCAG 2.0](https://www.w3.org/TR/WCAG20/)
accessibility standards and should therefore not be used for typical user
interface design — take a look at [core colors](#colors.core) instead.

@react-docs ExtendedColorsPalette

Styleguide colors.extended
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color schemes

Use the following color scheme generators to produce color schemes for your data visualizations.
First, choose the kind of scheme based on the type of your data, then customize the number of values
using the forms below. Finally, copy the colors array into your application and make it live!

The following schemes have been carefully crafted to be visually striking and easily understandable
while remaining accessible to visually impaired and color blind users.

Styleguide colors.schemes
*/
/*
Sequential color schemes

Sequential color schemes imply order and are best suited for representing data that
ranges from low-to-high values either on an ordinal or on a numerical scale.

Weight: -1

@react-docs SequentialSchemePalette

Styleguide colors.schemes.sequential
*/
/*
Diverging color schemes

Diverging color schemes put equal emphasis on mid-range values and extremes
at both ends of the data range.

@react-docs DivergingSchemePalette

Styleguide colors.schemes.diverging
*/
/*
Qualitative color schemes

Qualitative color schemes use a series of unrelated colors to create a
scheme that does not imply order, merely difference in kind.

@react-docs QualitativeSchemePalette

Styleguide colors.schemes.qualitative
*/
/*
Variables

Available for use with Sass and Less.

```css.scss
@import "path/to/@blueprintjs/core/dist/variables";
```

The Sass `$` convention is used in this documentation for consistency with the original source code.
Every variable mentioned below is also available in `variables.less` with an `@` prefix instead of `$`.

Weight: 4

Styleguide variables
*/
/*
Font variables

Typically, correct typography styles should be achieved by using the proper HTML tag (`<p>` for
text, `<h*>` for headings, `<code>` for code, etc.). The following variables are provided for the
rare cases where custom styling is necessary and should be used sparingly:

- `$pt-font-family`
- `$pt-font-family-monospace`
- `$pt-font-size`
- `$pt-font-size-small`
- `$pt-font-size-large`
- `$pt-line-height`

See the [Fonts section](#typography.fonts) for more information and usage guidelines.

Styleguide variables.fonts
*/
/*
Icon variables

Most icons should be displayed using the `span.pt-icon-*` classes or via modifier classes on
components like `.pt-button`. In rare cases, you may need direct access to the content
string that generates each icon in the icon font. Blueprint provides these variables with
straightforward names (see the [Icons section](#icons) for the full list of identifiers):

- `$pt-icon-style`
- `$pt-icon-align-left`
- `$pt-icon-align-center`
- ...

Variables are also provided for the two icon font families and their pixel sizes:

- `@icons16-family`
- `@icons20-family`
- `$pt-icon-size-standard`
- `$pt-icon-size-large`

Styleguide variables.icons
*/
/*
Grids & dimensions

Sizes of common components. Most sizing variables are based on `$pt-grid-size`, which has
a value of `10px`. Custom components should adhere to the relevant `height` variable.

- `$pt-grid-size`
- `$pt-border-radius`
- `$pt-button-height`
- `$pt-button-height-large`
- `$pt-input-height`
- `$pt-input-height-large`
- `$pt-navbar-height`

Weight: 1

Styleguide variables.dimensions
*/
/*
Grid system

Blueprint doesn't provide a grid system. In general, you should try to use the `$pt-grid-size`
variable to generate layout & sizing style rules in your CSS codebase.

In lieu of a full grid system, you should try to use the __CSS3 Flexible Box layout model__ (a.k.a.
"flexbox"). It's quite powerful on its own and allows you to build robust, responsive layouts
without writing much CSS. Here are some resources for learning flexbox:
   - [MDN guide](https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Flexible_boxes)
   - [CSS Tricks guide](https://css-tricks.com/snippets/css/a-guide-to-flexbox/)

Styleguide variables.dimensions.grid
*/
/*
Layering

Blueprint provides variables for three z-index layers. This should be enough for most use cases,
especially if you make correct use of [stacking context][MDN]. [Overlay](#components.overlay)
components such as dialogs and popovers use these z-index values to configure their stacking
contexts.

- `$pt-z-index-base`
- `$pt-z-index-content`
- `$pt-z-index-overlay`

[MDN]: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

Weight: 1

Styleguide variables.layering
*/
/*
Light theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
light theme components.

- `$pt-dialog-box-shadow`
- `$pt-input-box-shadow`
- `$pt-popover-box-shadow`
- `$pt-tooltip-box-shadow`

Weight: 2

Styleguide variables.light
*/
/*
Dark theme styles

Use these when you need to build custom UI components that look similar to Blueprint's
dark theme components.

- `$pt-dark-dialog-box-shadow`
- `$pt-dark-input-box-shadow`
- `$pt-dark-popover-box-shadow`
- `$pt-dark-tooltip-box-shadow`

Weight: 3

Styleguide variables.dark
*/
/*
Trees

Trees display hierarchical data.

Styleguide components.tree
*/
/*
JavaScript API

The `Tree` component is available in the __@blueprintjs/core__ package.
Make sure to review the [general usage docs for JS components](#components.usage).

`Tree` is a stateless component. Its contents are dictated by the `contents` prop, which is an array
of `ITreeNode`s (see [below](#components.tree.js.treenode)). The tree is multi-rooted if `contents`
contains more than one top-level object.

A variety of interaction callbacks are also exposed as props. All interaction callbacks supply a
parameter `nodePath`, which is an array of numbers representing a node's position in the tree. For
example, `[2, 0]` represents the first child (`0`) of the third top-level node (`2`).

@interface ITreeProps

@react-example TreeExample

Styleguide components.tree.js
*/
/*
Tree node interface

`ITreeNode` objects determine the contents, appearance, and state of each node in the tree.

For example, `iconName` controls the icon displayed for the node, and `isExpanded` determines
whether the node's children are shown.

@interface ITreeNodeProps

Styleguide components.tree.js.treenode
*/
/*
CSS API

See below for the [JavaScript API](#components.tree.js) for the `Tree` React component. However, you
may also use the provided styles by themselves, without using the component.

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  Note that the following examples set a maximum width and background color for the tree;
you may want to do this as well in your own usage.
</div>

Markup:
<div class="pt-tree pt-elevation-0">
  <ul class="pt-tree-node-list pt-tree-root">
    <li class="pt-tree-node pt-tree-node-expanded">
      <div class="pt-tree-node-content">
        <span class="pt-tree-node-caret pt-tree-node-caret-open pt-icon-standard"></span>
        <span class="pt-tree-node-icon pt-icon-standard pt-icon-folder-close"></span>
        <span class="pt-tree-node-label">Label</span>
        <span class="pt-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="pt-tree-node-list">
        <li class="pt-tree-node">
          <div class="pt-tree-node-content">
            <span class="pt-tree-node-caret-none pt-icon-standard"></span>
            <span class="pt-tree-node-icon pt-icon-standard pt-icon-document"></span>
          <span class="pt-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="pt-tree-node">
          <div class="pt-tree-node-content">
            <span class="pt-tree-node-caret-none pt-icon-standard"></span>
            <span class="pt-tree-node-icon pt-icon-standard pt-icon-document"></span>
            <span class="pt-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide components.tree.css
*/
.pt-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.pt-tree-root {
  position: relative;
  background-color: transparent;
  cursor: default;
  padding-left: 0; }

.pt-tree-node-content-0 {
  padding-left: 0px; }

.pt-tree-node-content-1 {
  padding-left: 23px; }

.pt-tree-node-content-2 {
  padding-left: 46px; }

.pt-tree-node-content-3 {
  padding-left: 69px; }

.pt-tree-node-content-4 {
  padding-left: 92px; }

.pt-tree-node-content-5 {
  padding-left: 115px; }

.pt-tree-node-content-6 {
  padding-left: 138px; }

.pt-tree-node-content-7 {
  padding-left: 161px; }

.pt-tree-node-content-8 {
  padding-left: 184px; }

.pt-tree-node-content-9 {
  padding-left: 207px; }

.pt-tree-node-content-10 {
  padding-left: 230px; }

.pt-tree-node-content-11 {
  padding-left: 253px; }

.pt-tree-node-content-12 {
  padding-left: 276px; }

.pt-tree-node-content-13 {
  padding-left: 299px; }

.pt-tree-node-content-14 {
  padding-left: 322px; }

.pt-tree-node-content-15 {
  padding-left: 345px; }

.pt-tree-node-content-16 {
  padding-left: 368px; }

.pt-tree-node-content-17 {
  padding-left: 391px; }

.pt-tree-node-content-18 {
  padding-left: 414px; }

.pt-tree-node-content-19 {
  padding-left: 437px; }

.pt-tree-node-content-20 {
  padding-left: 460px; }

.pt-tree-node-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-right: 5px; }
  .pt-tree-node-content:hover {
    background-color: rgba(191, 204, 214, 0.4); }

.pt-tree-node-caret,
.pt-tree-node-caret-none {
  position: relative;
  min-width: 30px;
  line-height: 30px !important; }

.pt-tree-node-caret {
  color: #5c7080;
  cursor: pointer;
  text-align: center; }
  .pt-tree-node-caret:hover {
    color: #182026; }
  .pt-dark .pt-tree-node-caret {
    color: #bfccd6; }
    .pt-dark .pt-tree-node-caret:hover {
      color: #f5f8fa; }
  .pt-tree-node-caret::before {
    display: inline-block;
    content: "";
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-tree-node-caret.pt-tree-node-caret-open::before {
    transform: rotate(90deg); }

.pt-tree-node-icon {
  position: relative;
  margin-right: 7px;
  color: #5c7080; }

.pt-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  user-select: none; }
  .pt-tree-node-label span {
    display: inline; }

.pt-tree-node-secondary-label {
  padding: 0 5px;
  line-height: 30px;
  user-select: none; }

.pt-tree-node.pt-tree-node-selected > .pt-tree-node-content {
  background-color: #137cbd; }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content,
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon, .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon-standard, .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon-large {
    color: #ffffff; }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-tree-node-caret::before {
    color: rgba(255, 255, 255, 0.7); }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-tree-node-caret:hover::before {
    color: #ffffff; }

.pt-dark .pt-tree-node-content:hover {
  background-color: rgba(92, 112, 128, 0.3); }

.pt-dark .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content {
  background-color: #137cbd; }

/*
Dark theme

Blueprint provides two UI color themes: light and dark. The light theme is active by default. The
dark theme can be applied by adding the class `pt-dark` to a container element to theme all nested
elements.

Once applied, the dark theme will cascade to nested `.pt-*` elements inside a `.pt-dark` container.
There is no way to nest light-themed elements inside a dark container.

Most elements only support the dark theme when nested inside a `.pt-dark` container because it does
not make sense to mark individual elements as dark. The dark container is therefore responsible for
setting a dark background color.

The following elements and components support the `.pt-dark` class directly (i.e, `.pt-app.pt-dark`)
and can be used as a container for nested dark children:

- `.pt-app`
- `.pt-card`
- Overlays: `Dialog`, `Popover`, `Tooltip`, `Toast`
  - `Popover` and `Tooltip` will automatically detect when their trigger is inside a `.pt-dark`
    container and add the same class to themselves.

Rather than illustrating dark components inline, this documentation site provides a site-wide switch
in the top right corner of the page to enable the dark theme. Try it out as you read the docs.

Weight: 10

Styleguide components.usage.dark
*/
